import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  AppBar,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { BankAccountStatistics } from '../../../workers/BankAccountStatistics';
import { StatsModalContent } from './StatsModalContent';

export interface StatsModalProps {
  open: boolean;
  statistics: BankAccountStatistics | null;
  onClose: () => void;
}

const useStyle = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    margin: theme.spacing(0, 2),
  },
  content: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const StatsModal = (props: StatsModalProps) => {
  const { open, onClose, statistics } = props;
  const classes = useStyle();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={true}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge={'start'}
            aria-label={'close'}
            color={'inherit'}
            onClick={onClose}
          >
            <Close />
          </IconButton>
          <Typography className={classes.title} variant="h6">
            Statistiques
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        {statistics && statistics.months.length > 0 ? (
          <StatsModalContent statistics={statistics} />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
