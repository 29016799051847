import { Controller, useForm } from 'react-hook-form';
import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
} from '@material-ui/core';
import { GoogleDriveFolderField } from '../../forms/GoogleDriveFolderField';

export interface BankAccountNewModalFileStepData {
  fileName: string;
  folderFileId: string;
}

export interface BankAccountNewModalFileStepProps {
  initialValues: BankAccountNewModalFileStepData;
  onSubmit: (data: BankAccountNewModalFileStepData) => void;

  onCancelClicked: () => void;
}

export const BankAccountNewModalFileStep = (
  props: BankAccountNewModalFileStepProps
) => {
  const { register, handleSubmit, control, formState } =
    useForm<BankAccountNewModalFileStepData>({
      defaultValues: props.initialValues,
    });
  const errors = formState.errors;

  return (
    <form
      onSubmit={handleSubmit((data) => {
        props.onSubmit(data);
      })}
    >
      <DialogContent>
        <DialogContentText>
          Choisissez l&apos;emplacement où sera enregistré le fichier contenant
          les informations de votre compte.
        </DialogContentText>
        <Controller
          name={'fileName'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Nom du fichier"
              variant="filled"
              error={errors.fileName != null}
              helperText={
                errors.fileName != null
                  ? 'Veuillez saisir un nom de fichier'
                  : ' '
              }
              margin={'normal'}
            />
          )}
        />
        <Controller
          name={'folderFileId'}
          rules={{
            required: true,
            validate: {
              validFolder: (value) => value !== '-1',
            },
          }}
          control={control}
          render={({ field }) => (
            <GoogleDriveFolderField
              {...field}
              label={'Dossier'}
              error={errors.folderFileId != null}
              helperText={
                errors.folderFileId != null
                  ? 'Veuillez choisir un dossier'
                  : ' '
              }
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color={'primary'}
          type={'button'}
          onClick={props.onCancelClicked}
        >
          Annuler
        </Button>
        <Button color={'primary'} type={'submit'}>
          Suivant
        </Button>
      </DialogActions>
    </form>
  );
};
