import { RootState } from '../rootReducer';

export class AccountsListPersistTools {
  public static getPersistedAccountsList(userId: string): string[] {
    const itemName = 'al' + userId;
    const item = window.localStorage.getItem(itemName);
    if (item) {
      try {
        return JSON.parse(item);
      } catch (e) {
        console.warn(
          'Unable to parse the persisted accounts list for user ' + userId
        );
      }
    }
    return [];
  }

  public static persistAccountsList(
    userId: string,
    fileIdsList: string[]
  ): void {
    window.localStorage.setItem('al' + userId, JSON.stringify(fileIdsList));
  }

  public static persistAccountsListFromRedux(state: RootState): void {
    if (state.auth.authenticatedUser) {
      AccountsListPersistTools.persistAccountsList(
        state.auth.authenticatedUser.id,
        state.bankAccounts.bankAccountsList
      );
    }
  }
}
