import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BankAccount,
  BankAccountStatus,
} from '../../types/bank-account/BankAccount';
import { authLogoutAction } from '../auth/AuthExtraActions';
import { illegalAction } from '../tools/CommonThrows';
import { BankAccountData } from '../../types/data/BankAccountData';
import {
  createBankAccountFromEmpty,
  createBankAccountFromOpenedFile,
} from './BankAccountsReduxTools';
import { bankAccountReducer } from './BankAccountReducerWrappers';
import { OperationActionPayload } from './operations/OperationActionPayload';
import { OPERATION_ACTION_REDUCERS } from './operations/OperationActionReducer';
import { CollectionsTools } from '../../tools/CollectionsTools';

export interface BankAccountsState {
  /**
   * The currently selected bank account id.
   */
  currentId: string | null;

  /**
   * The ordered list of bank accounts ids.
   */
  bankAccountsList: string[];

  /**
   * A map of the bank accounts per id.
   */
  bankAccounts: {
    [id: string]: BankAccount;
  };

  /**
   * Id of the bank accounts being currently opened.
   */
  openingBankAccountId: string[];

  /**
   * Errors that happened during the opening a the bank accounts.
   */
  openingBankAccountError: {
    [fileId: string]: { name?: string; error: string };
  };

  isCreatingAccount: boolean;

  creatingBankAccountError: string | null;

  printing: boolean;
}

const initialState: BankAccountsState = {
  currentId: null,
  bankAccountsList: [],
  bankAccounts: {},

  openingBankAccountId: [],
  openingBankAccountError: {},
  isCreatingAccount: false,
  creatingBankAccountError: null,

  printing: false,
};

export const BankAccountsSlice = createSlice({
  name: 'BankAccounts',
  initialState,
  reducers: {
    /*
     * Reducers for file management.
     */
    baOpen: (state, action: PayloadAction<{ fileId: string }>) => {
      const { fileId } = action.payload;
      state.openingBankAccountId = CollectionsTools.editAsSet(
        state.openingBankAccountId,
        (s) => s.add(fileId)
      );
      delete state.openingBankAccountError[fileId];
    },
    baOpenSuccessful: (
      state,
      action: PayloadAction<{
        fileId: string;
        name: string;
        bankAccountData: BankAccountData;
      }>
    ) => {
      const { fileId, name, bankAccountData } = action.payload;
      state.openingBankAccountId = CollectionsTools.editAsSet(
        state.openingBankAccountId,
        (s) => s.delete(fileId)
      );
      delete state.openingBankAccountError[fileId];
      state.currentId = fileId;
      state.bankAccounts[fileId] = createBankAccountFromOpenedFile(
        fileId,
        name,
        bankAccountData
      );
      state.bankAccountsList.push(fileId);
    },
    baOpenFailure: (
      state,
      action: PayloadAction<{ fileId: string; name?: string; error: string }>
    ) => {
      const { error, fileId, name } = action.payload;
      state.openingBankAccountError[fileId] = { error, name };
      state.openingBankAccountId = CollectionsTools.editAsSet(
        state.openingBankAccountId,
        (s) => s.delete(fileId)
      );
    },
    baOpenFailureDismiss: (state) => {
      state.openingBankAccountError = {};
    },
    baNew: (state, action: PayloadAction) => {
      if (state.isCreatingAccount) {
        illegalAction('BA', action, state);
      }

      state.isCreatingAccount = true;
      state.creatingBankAccountError = null;
    },
    baNewSuccessful: (
      state,
      action: PayloadAction<{ fileId: string; name: string; epoch: number }>
    ) => {
      if (!state.isCreatingAccount) {
        illegalAction('BA', action, state);
      }

      const { fileId, name, epoch } = action.payload;
      state.isCreatingAccount = false;
      state.creatingBankAccountError = null;
      state.currentId = fileId;
      state.bankAccounts[fileId] = createBankAccountFromEmpty(
        fileId,
        name,
        epoch
      );
      state.bankAccountsList.push(fileId);
    },
    baNewFailure: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      state.isCreatingAccount = false;
      state.creatingBankAccountError = error;
    },
    baNewFailureDismiss: (state) => {
      state.creatingBankAccountError = null;
    },
    baClose: (state, action: PayloadAction<{ fileId: string }>) => {
      const fileId = action.payload.fileId;
      state.bankAccounts[fileId].status = BankAccountStatus.CLOSING;
    },
    baCloseSuccessful: (state, action: PayloadAction<{ fileId: string }>) => {
      const fileId = action.payload.fileId;
      delete state.bankAccounts[fileId];
      state.bankAccountsList = state.bankAccountsList.filter(
        (b) => b !== fileId
      );
      if (state.currentId === fileId) {
        state.currentId = null;
      }
    },
    baSwitchCurrent: (state, action: PayloadAction<{ fileId: string }>) => {
      state.currentId = action.payload.fileId;
    },
    baReorder: (
      state,
      action: PayloadAction<{ startIndex: number; endIndex: number }>
    ) => {
      const { startIndex, endIndex } = action.payload;
      const [itemToMove] = state.bankAccountsList.splice(startIndex, 1);
      state.bankAccountsList.splice(endIndex, 0, itemToMove);
    },
    baSavingAccount: (state, action: PayloadAction<{ fileId: string }>) => {
      const fileId = action.payload.fileId;
      state.bankAccounts[fileId].status = BankAccountStatus.SAVING;
      state.bankAccounts[fileId].savingChangeEpoch =
        state.bankAccounts[fileId].lastChangeEpoch;
    },
    baSavingAccountSuccessful: (
      state,
      action: PayloadAction<{ fileId: string }>
    ) => {
      const { fileId } = action.payload;

      const lastChangeEpoch = state.bankAccounts[fileId].savingChangeEpoch;
      if (lastChangeEpoch == null) {
        illegalAction('BA', action, state);
        return;
      }

      state.bankAccounts[fileId].status = BankAccountStatus.READY;
      state.bankAccounts[fileId].lastSavedChangeEpoch = lastChangeEpoch;
      state.bankAccounts[fileId].savingChangeEpoch = null;
      state.bankAccounts[fileId].saveError = null;
    },
    baSavingAccountFailure: (
      state,
      action: PayloadAction<{ fileId: string; error: string }>
    ) => {
      const { fileId, error } = action.payload;
      state.bankAccounts[fileId].status = BankAccountStatus.READY;
      state.bankAccounts[fileId].saveError = error;
    },

    baAct: bankAccountReducer(
      (state, action: PayloadAction<OperationActionPayload>, account) => {
        const undoActionPayload = OPERATION_ACTION_REDUCERS[
          action.payload.type
        ](action.payload as any)(account, state);
        if (undoActionPayload) {
          if (Array.isArray(undoActionPayload)) {
            account.undo.push(...undoActionPayload);
          } else {
            account.undo.push(undoActionPayload);
          }
        }

        // Delete all the redo history.
        account.redo = [];
      }
    ),

    baUndo: bankAccountReducer(
      (state, action: PayloadAction<Record<string, never>>, account) => {
        if (account.undo.length > 0) {
          const actionToUndo = account.undo[account.undo.length - 1];
          const redoActionPayload = OPERATION_ACTION_REDUCERS[
            actionToUndo.type
          ](actionToUndo as any)(account, state);
          if (redoActionPayload) {
            if (Array.isArray(redoActionPayload)) {
              account.redo.push(...redoActionPayload);
            } else {
              account.redo.push(redoActionPayload);
            }
          }

          account.undo.pop();
        }
      }
    ),

    baRedo: bankAccountReducer(
      (state, action: PayloadAction<Record<string, never>>, account) => {
        if (account.redo.length > 0) {
          const actionToRedo = account.redo[account.redo.length - 1];
          const undoActionPayload = OPERATION_ACTION_REDUCERS[
            actionToRedo.type
          ](actionToRedo as any)(account, state);
          if (undoActionPayload) {
            if (Array.isArray(undoActionPayload)) {
              account.undo.push(...undoActionPayload);
            } else {
              account.undo.push(undoActionPayload);
            }
          }

          account.redo.pop();
        }
      }
    ),

    baPrint: (state) => {
      state.printing = true;
    },
    baPrintFinish: (state) => {
      state.printing = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogoutAction, (state, action) => {
      state.openingBankAccountId = CollectionsTools.editAsSet(
        state.openingBankAccountId,
        (s) => s.clear()
      );
      state.bankAccounts = {};
      state.bankAccountsList = [];
      state.currentId = null;
    });
  },
});
