import { useAppSelector } from './ReduxHooks';
import { useIsOpeningAccounts } from '../bank-accounts/BankAccountHooks';

export const useLoadingBlockerMessage = (): string | null => {
  const isCreatingNewBankAccount = useAppSelector<boolean>(
    (state) => state.bankAccounts.isCreatingAccount
  );
  const isOpeningBankAccount = useIsOpeningAccounts();
  const isPrinting = useAppSelector<boolean>(
    (state) => state.bankAccounts.printing
  );

  if (isCreatingNewBankAccount) {
    return 'Création du compte...';
  } else if (isOpeningBankAccount) {
    return 'Ouverture...';
  } else if (isPrinting) {
    return 'Construction du PDF en cours...';
  } else {
    return null;
  }
};
