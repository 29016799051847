import { ThunkResult } from '../Store';
import { BankAccountTools } from '../../tools/BankAccountTools';
import { BankAccountsSlice } from '../bank-accounts/BankAccountsSlice';
import { AccountSerializationTools } from '../../tools/AccountSerializationTools';
import { GoogleApiTools } from '../../tools/GoogleApiTools';

export const saveAccountThunk =
  (fileId: string, allowOnClosing = false): ThunkResult<Promise<boolean>> =>
  async (dispatch, getState) => {
    const bankAccount = getState().bankAccounts.bankAccounts[fileId];
    if (BankAccountTools.canSave(bankAccount, allowOnClosing)) {
      dispatch(BankAccountsSlice.actions.baSavingAccount({ fileId }));

      try {
        // Serialize the file.
        const content = JSON.stringify(
          AccountSerializationTools.serialize(bankAccount)
        );
        await GoogleApiTools.saveToFile(
          fileId,
          content,
          'application/com.victorlevasseur.account-manager.file'
        );

        dispatch(
          BankAccountsSlice.actions.baSavingAccountSuccessful({ fileId })
        );
        return true;
      } catch (e: any) {
        dispatch(
          BankAccountsSlice.actions.baSavingAccountFailure({
            fileId,
            error: e.message,
          })
        );
        return false;
      }
    } else {
      return true;
    }
  };
