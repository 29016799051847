import { useRef } from 'react';
import * as Comlink from 'comlink';
import {
  BankAccountStatistics,
  BankAccountStatisticsWorkerInterface,
} from './BankAccountStatistics';
import { BankAccount } from '../types/bank-account/BankAccount';

export const useBankAccountStatistics = () => {
  const workerRef = useRef(
    Comlink.wrap<BankAccountStatisticsWorkerInterface>(
      new Worker(new URL('./BankAccountStatistics.worker', import.meta.url))
    )
  );
  return (bankAccount: BankAccount): Promise<BankAccountStatistics> => {
    return workerRef.current.compute(bankAccount);
  };
};
