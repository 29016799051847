import { OperationActionPayload } from '../../redux/bank-accounts/operations/OperationActionPayload';
import { BankAccountLine } from './BankAccountLine';
import { BankAccountChequeBook } from './BankAccountChequeBook';

export enum BankAccountStatus {
  READY,
  CLOSING,
  SAVING,
}

export interface BankAccount {
  /**
   * Id of the account, matches the Google Drive API file id.
   */
  id: string;

  /**
   * Name of the account, as used in Google Drive.
   */
  name: string;

  /**
   * The bank account status.
   */
  status: BankAccountStatus;

  /**
   * Epoch of the instant the last change occurred.
   */
  lastChangeEpoch: number;

  savingChangeEpoch: number | null;

  /**
   * Epoch of the instant that was saved. It may be sooner that the save instant as it represents
   * the last change instant that was saved.
   *
   * If lastChangeEpoch > lastSavedChangeEpoch it means the bank account is dirty and needs to be saved.
   *
   * @see lastChangeEpoch
   */
  lastSavedChangeEpoch: number;

  saveError: string | null;

  operations: BankAccountLine[];

  chequeBook?: BankAccountChequeBook;

  undo: OperationActionPayload[];
  redo: OperationActionPayload[];
}
