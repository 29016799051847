import { BankAccountLine } from '../../types/bank-account/BankAccountLine';
import React, { useCallback, useMemo } from 'react';
import Dinero from 'dinero.js';
import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Clear, Search } from '@material-ui/icons';
import { SearchableValue } from './SearchTools';
import { SearchResultItemData } from './SearchResultData';
import { SearchResultList } from './SearchResultList';
import { StringTools } from '../../tools/StringTools';

export interface SearchPanelProps {
  inputValue: string;
  setInputValue: (value: string) => void;

  searchValue: string | null;
  onInputSubmit?: () => void;
  onClearSearch?: () => void;

  onLineMatchClicked?: (uuid: string) => void;

  lines: BankAccountLine[];
}

const getSearchableValues = (line: BankAccountLine): SearchableValue[] => {
  if (line._type === 'operation') {
    return [
      { value: StringTools.removeDiatrics(line.date), mode: 'start' },
      {
        value: StringTools.removeDiatrics(line.description.toLowerCase()),
        mode: 'contain',
      },
      { value: '' + Dinero(line.amount).toUnit(), mode: 'numeric_order' },
    ];
  } else if (line._type === 'month') {
    return [
      {
        value: StringTools.removeDiatrics(line.name.toLowerCase()),
        mode: 'start',
      },
    ];
  } else {
    return [];
  }
};

const getOrderOfMagnitude = (n: number) => {
  const order = Math.floor(Math.log(n) / Math.LN10 + 0.000000001); // because float math sucks like that
  return Math.pow(10, order);
};

const isMatching = (
  searchableValue: SearchableValue,
  search: string,
  orderOfMagnitureOfSearch?: number
): boolean => {
  switch (searchableValue.mode) {
    case 'whole':
      return searchableValue.value === search;
    case 'contain':
      return searchableValue.value.includes(search);
    case 'start':
      return searchableValue.value.startsWith(search);
    case 'numeric_order': {
      const searchNum = +search;
      const valueNum = +searchableValue.value;
      if (orderOfMagnitureOfSearch == null || isNaN(valueNum)) {
        return false;
      }
      return (
        valueNum > searchNum - orderOfMagnitureOfSearch &&
        valueNum < searchNum + orderOfMagnitureOfSearch
      );
    }
  }
};

const useStyle = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  searchField: {
    margin: theme.spacing(0, 2),
  },
  searchListContainer: {
    flex: 1,
  },
}));

export const SearchPanel = (props: SearchPanelProps) => {
  const {
    lines,
    searchValue,
    inputValue,
    setInputValue,
    onInputSubmit,
    onClearSearch,
    onLineMatchClicked,
  } = props;
  const classes = useStyle();

  const filteredLines: SearchResultItemData[] | null = useMemo(() => {
    if (searchValue != null && searchValue !== '') {
      const lowercasedSearch = StringTools.removeDiatrics(
        searchValue.toLowerCase()
      );

      let orderOfMagnitudeIfNumeric: number | undefined = undefined;
      if (!isNaN(+lowercasedSearch)) {
        orderOfMagnitudeIfNumeric = getOrderOfMagnitude(+lowercasedSearch);
      }

      return lines
        .map((line, index) => ({
          line,
          searchables: getSearchableValues(line),
          index,
        }))
        .filter((lineInfo) =>
          lineInfo.searchables
            .map((searchable) =>
              isMatching(
                searchable,
                lowercasedSearch,
                orderOfMagnitudeIfNumeric
              )
            )
            .some((b) => b)
        );
    } else {
      return null;
    }
  }, [lines, searchValue]);

  const handleItemClicked = useCallback(
    (itemIndex: number) => {
      if (!filteredLines) {
        return;
      }
      const accountLineUuid = filteredLines[itemIndex].line.uuid;
      onLineMatchClicked?.(accountLineUuid);
    },
    [filteredLines, onLineMatchClicked]
  );

  return (
    <Box className={classes.container}>
      <TextField
        className={classes.searchField}
        variant={'filled'}
        label={'Recherche'}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            // "Return" pressed.
            onInputSubmit?.();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position={'end'}>
              {inputValue.length > 0 ? (
                <IconButton onClick={onClearSearch}>
                  <Clear />
                </IconButton>
              ) : null}

              <IconButton onClick={onInputSubmit}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
      />
      {filteredLines != null ? (
        <Box className={classes.searchListContainer}>
          <SearchResultList
            items={filteredLines}
            onItemClicked={handleItemClicked}
          />
        </Box>
      ) : null}
    </Box>
  );
};
