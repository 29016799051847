import { BankAccountStatistics } from '../../../workers/BankAccountStatistics';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import { CATEGORIES_PER_KEYS } from '../../../types/Categories';
import Dinero from 'dinero.js';

export interface StatsModalContentProps {
  statistics: BankAccountStatistics;
}

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  graphsContainer: {
    flex: 1,
    padding: theme.spacing(2),

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  pieTitles: {
    fontWeight: 'bold',
    fontSize: 24,
  },
}));

const prepareStats = (stats: {
  [categoryKey: string]: { amount: Dinero.DineroObject };
}) => {
  return Object.keys(stats).map((key) => ({
    category: key,
    amountValue: Dinero(stats[key].amount).toUnit(),
    amount: stats[key].amount,
    fill: CATEGORIES_PER_KEYS[key].color,
  }));
};

export const StatsModalContent = (props: StatsModalContentProps) => {
  const { statistics } = props;

  const theme = useTheme();
  const classes = useStyle();
  const textColor = theme.palette.getContrastText(
    theme.palette.background.paper
  );

  const [month, setMonth] = useState<{ month: number; year: number }>(
    statistics.months[statistics.months.length - 1]
  );

  const selectedMonthStats = statistics.months.find(
    (monthStats) =>
      monthStats.month === month.month && monthStats.year === month.year
  );

  const flattenExpenses = selectedMonthStats
    ? prepareStats(selectedMonthStats.perCategoriesExpenses)
    : [];

  const flattenRevenues = selectedMonthStats
    ? prepareStats(selectedMonthStats.perCategoriesRevenues)
    : [];

  const renderCustomizedLabel = useCallback(
    (pieData: any) => {
      const amount: Dinero.DineroObject = pieData.amount;

      return (
        <text
          x={pieData.x}
          y={pieData.y - 2}
          fill={textColor}
          dominantBaseline={pieData.y > pieData.cy ? 'hanging' : 'baseline'}
          textAnchor={pieData.x > pieData.cx ? 'start' : 'end'}
        >
          <tspan>({Dinero(amount).toFormat()}) </tspan>
          <tspan style={{ fontWeight: 'bold' }}>
            {CATEGORIES_PER_KEYS[pieData.category].name}
          </tspan>
        </text>
      );
    },
    [textColor]
  );

  return (
    <Box className={classes.mainContainer}>
      <FormControl variant="filled">
        <InputLabel>Mois</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={month.month + '/' + month.year}
          onChange={(event) => {
            const newValue = event.target.value as string;
            const splitted = newValue.split('/');
            setMonth({ month: +splitted[0], year: +splitted[1] });
          }}
        >
          {statistics.months.map((statMonth) => (
            <MenuItem
              key={statMonth.month + '/' + statMonth.year}
              value={statMonth.month + '/' + statMonth.year}
            >
              {DateTime.fromFormat(
                statMonth.month + '/' + statMonth.year,
                'M/yyyy'
              ).toFormat('LLLL yyyy')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box className={classes.graphsContainer}>
        <ResponsiveContainer width={'50%'} height="100%">
          <PieChart>
            <Pie
              data={flattenExpenses}
              dataKey="amountValue"
              nameKey="category"
              label={renderCustomizedLabel}
              cx="50%"
              cy="50%"
              innerRadius={'30%'}
              outerRadius={'45%'}
              stroke={theme.palette.background.paper}
            />
            <text
              className={classes.pieTitles}
              x={'50%'}
              y={'50%'}
              fill={textColor}
              textAnchor={'middle'}
              dominantBaseline={'middle'}
            >
              Dépenses
            </text>
          </PieChart>
        </ResponsiveContainer>
        <ResponsiveContainer width={'50%'} height="100%">
          <PieChart>
            <Pie
              data={flattenRevenues}
              dataKey="amountValue"
              nameKey="category"
              label={renderCustomizedLabel}
              cx="50%"
              cy="50%"
              innerRadius={'30%'}
              outerRadius={'45%'}
              stroke={theme.palette.background.paper}
            />
            <text
              className={classes.pieTitles}
              x={'50%'}
              y={'50%'}
              fill={textColor}
              textAnchor={'middle'}
              dominantBaseline={'middle'}
            >
              Revenus
            </text>
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
