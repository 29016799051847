import React, { useCallback, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Ajv from 'ajv';
import { Check } from '@material-ui/icons';

export interface DropFileModalProps {
  open: boolean;
  onClose?: () => void;

  title: string;
  message: string;
  submitButtonLabel: string;

  jsonSchema: any;
  onSubmitClicked: (content: string) => void;
  onCancelClicked: () => void;
}

const useStyle = makeStyles((theme) => ({
  dropZone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.grey[700],
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
  },
}));

type DropFileModalState =
  | null
  | { checking: true }
  | {
      checking: false;
      fileName: string | null;
      content: string | null;
      error: string | null;
    };

const readUploadedFileAsText = (inputFile: File) => {
  const temporaryFileReader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(Error('Cannot parse file!'));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result as any);
    };
    temporaryFileReader.readAsText(inputFile);
  });
};

export const DropFileModal = (props: DropFileModalProps) => {
  const {
    open,
    onClose,
    message,
    title,
    submitButtonLabel,
    jsonSchema,
    onSubmitClicked,
    onCancelClicked,
  } = props;
  const classes = useStyle();

  const [state, setState] = useState<DropFileModalState>(null);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      (async () => {
        if (acceptedFiles.length < 1) {
          return;
        }

        setState({ checking: true });
        const file = acceptedFiles[0];
        try {
          const text = await readUploadedFileAsText(file);

          let valid = true;
          let error: string | null = null;
          if (jsonSchema) {
            const validator = new Ajv();
            valid = validator.validate(jsonSchema, JSON.parse(text)) as any;
            if (!valid) {
              error = validator.errorsText();
            }
          }
          setState({
            checking: false,
            error,
            fileName: file.name,
            content: text,
          });
        } catch (e) {
          setState({
            checking: false,
            error: 'Fichier JSON invalide (ou impossible à lire) !',
            fileName: file.name,
            content: null,
          });
        }
      })();
    },
    [jsonSchema]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const handleSubmit = useCallback(() => {
    if (
      state != null &&
      !state.checking &&
      state.content != null &&
      state.error == null
    ) {
      onSubmitClicked(state.content);
    }
  }, [onSubmitClicked, state]);

  return (
    <Dialog
      TransitionProps={{ onExited: () => setState(null) }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <div className={classes.dropZone} {...getRootProps()}>
          <input {...getInputProps()} />
          {state == null ? (
            <p>Cliquer ou glissez-déposez un fichier ici.</p>
          ) : state.checking ? (
            <CircularProgress color={'secondary'} />
          ) : state.error != null ? (
            <>
              <p>Fichier invalide : </p>
              <p>{state.error}</p>
            </>
          ) : (
            <p>
              <Check color={'secondary'} />{' '}
              {state.fileName || 'Fichier importé.json'}
            </p>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} autoFocus onClick={onCancelClicked}>
          Annuler
        </Button>
        <Button
          disabled={state == null || state.checking || state.error != null}
          color={'primary'}
          onClick={handleSubmit}
        >
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
