import { SerializedLineV1 } from './SerializedLineV1';

export interface SerializedMonthV1 {
  _type: 'month';
  name: string;
}

export const isSerializedMonthV1 = (
  o: SerializedLineV1
): o is SerializedMonthV1 => {
  return o._type === 'month';
};
