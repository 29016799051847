import { SearchResultItemData } from './SearchResultData';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { AutoSizer as _AutoSizer, AutoSizerProps } from 'react-virtualized';
import React, { FC, useMemo } from 'react';
import { SearchResultItem } from './SearchResultItem';

// FIXME: https://github.com/bvaughn/react-virtualized/issues/1739
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

export interface SearchResultListProps {
  items: SearchResultItemData[];
  onItemClicked?: (itemIndex: number) => void;
}

interface ItemData {
  items: SearchResultItemData[];
  onItemClicked: (itemIndex: number) => void;
}

const renderRow = (props: ListChildComponentProps) => {
  const { index, style, data: _data } = props;
  const data = _data as ItemData;
  const item = data.items[index];

  return (
    <SearchResultItem
      item={item}
      onItemClicked={() => data.onItemClicked(index)}
      style={style}
    />
  );
};

export const SearchResultList = (props: SearchResultListProps) => {
  const { items, onItemClicked } = props;
  const data: ItemData = useMemo(
    () => ({
      items,
      onItemClicked:
        onItemClicked ??
        (() => {
          /* Do nothing if not specified */
        }),
    }),
    [items, onItemClicked]
  );
  return (
    <AutoSizer>
      {({ width, height }) => (
        <FixedSizeList
          height={height}
          width={width}
          itemSize={36}
          itemCount={items.length}
          itemData={data}
        >
          {renderRow}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};
