import {
  useCurrentAccountDispatch,
  useCurrentBankAccountOrThrow,
} from '../../../redux/bank-accounts/CurrentAccountHook';
import {
  AccountTable,
  AccountTableInterface,
} from '../../../components/account-table/AccountTable';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BankAccountsSlice } from '../../../redux/bank-accounts/BankAccountsSlice';
import {
  AddOperationPayload,
  ChangeColorPayload,
  OperationActionType,
  RemoveOperationPayload,
  SetCategoryPayload,
  SetValuePayload,
} from '../../../redux/bank-accounts/operations/OperationActionPayload';
import {
  useIsRedoable,
  useIsUndoable,
} from '../../../redux/bank-accounts/BankAccountHooks';
import { BankAccountOperationColumn } from '../../../types/bank-account/BankAccountOperationColumn';
import { useDispatch, useStore } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { EditorSlice } from '../../../redux/editor/EditorSlice';
import { debounce, round } from 'lodash';
import { AccountToolbar } from '../../../components/toolbar/account-toolbar/AccountToolbar';
import {
  CellEditor,
  CellEditorData,
} from '../../../components/cell-editors/CellEditor';
import { AccountTableContextMenu } from '../../../components/menu/AccountTableContextMenu';
import { DineroObject } from 'dinero.js';
import { useSelectedItemHook } from '../../../hooks/SelectedItemHook';
import { useBankAccountChecker } from '../../../workers/BankAccountCheckerHook';
import { SelectCategoryModal } from '../../../components/modals/SelectCategoryModal';
import { BankAccountStatistics } from '../../../workers/BankAccountStatistics';
import { StatsModal } from '../../../components/modals/stats-modal/StatsModal';
import { useBankAccountStatistics } from '../../../workers/BankAccountStatisticsHook';
import { PrinterTools } from '../../../external/printer/PrinterTools';

export type AccountEditorProps = object;

export interface AccountEditorInterface {
  scrollToLine(uuid: string): void;
}

const useStyle = makeStyles((theme) => ({
  container: {
    flex: 1,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    position: 'relative',
  },
}));

export const AccountEditor = forwardRef<
  AccountEditorInterface,
  AccountEditorProps
>((props, ref) => {
  const classes = useStyle();

  const dispatch = useDispatch();
  const currentAccountDispatch = useCurrentAccountDispatch();

  const bankAccount = useCurrentBankAccountOrThrow();
  const store = useStore<RootState>();

  const checkerResult = useBankAccountChecker(bankAccount);

  // Force a scroll to the previous scroll offset if the current bank account changes.
  const tableRef = useRef<AccountTableInterface>(null);
  useEffect(() => {
    if (!tableRef.current) {
      return;
    }
    const newInitialScroll =
      store.getState().editor.scrollOffsets[bankAccount.id];
    if (newInitialScroll) {
      tableRef.current.scrollTo(newInitialScroll);
    }
  }, [bankAccount.id, store]);

  // Ref interface of AccountEditor.
  useImperativeHandle(
    ref,
    () => ({
      scrollToLine: (uuid: string) => {
        const index = bankAccount.operations.findIndex((l) => l.uuid === uuid);
        if (index >= 0) {
          if (tableRef.current) {
            tableRef.current.scrollToItem(index, 'center');
          }
        } else {
          console.warn(
            'Item with UUID ' +
              uuid +
              ' not found when calling scrollToLine in AccountEditor!'
          );
        }
      },
    }),
    [bankAccount.operations]
  );

  const selection = useSelectedItemHook(bankAccount.operations);
  const canUndo = useIsUndoable(bankAccount.id);
  const canRedo = useIsRedoable(bankAccount.id);

  const [editedItemData, setEditedItemData] = useState<CellEditorData | null>(
    null
  );
  const [editedItemPosition, setEditedItemPosition] = useState<DOMRect | null>(
    null
  );

  const handleItemColumnClicked = useCallback(
    (index: number, column: BankAccountOperationColumn, rect: DOMRect) => {
      selection.setSelectedIndex(index);

      if (column === BankAccountOperationColumn.CHECKED) {
        const operationAtIndex = bankAccount.operations[index];
        if (operationAtIndex._type !== 'operation') {
          return;
        }
        currentAccountDispatch<SetValuePayload>(
          BankAccountsSlice.actions.baAct as any,
          {
            type: OperationActionType.SET_VALUE,
            index,
            column,
            value: operationAtIndex.checked ? 'false' : 'true',
          }
        );
      }
    },
    [bankAccount.operations, currentAccountDispatch, selection]
  );

  const handleItemColumnDoubleClicked = useCallback(
    (index: number, column: BankAccountOperationColumn, rect: DOMRect) => {
      if (
        column === BankAccountOperationColumn.DATE ||
        column === BankAccountOperationColumn.TYPE ||
        column === BankAccountOperationColumn.DESCRIPTION ||
        column === BankAccountOperationColumn.AMOUNT_CREDIT ||
        column === BankAccountOperationColumn.AMOUNT_DEBIT ||
        column === BankAccountOperationColumn.MONTH_NAME
      ) {
        setEditedItemData({
          index,
          column,
          bankAccount,
          fileId: bankAccount.id,
        });
        setEditedItemPosition(rect);
      }
    },
    [bankAccount]
  );

  const [contextMenuInfo, setContextMenuInfo] = useState<{
    index: number;
    x: number;
    y: number;
  } | null>(null);
  const closeContextMenu = useCallback(() => {
    setContextMenuInfo(null);
  }, []);

  const handleAddItem = useCallback(
    (fromIndex?: number | null, type?: 'operation' | 'month') => {
      const newItemIndex =
        fromIndex != null ? fromIndex + 1 : bankAccount.operations.length;
      currentAccountDispatch<AddOperationPayload>(
        BankAccountsSlice.actions.baAct as any,
        {
          type: OperationActionType.ADD_OPERATION,
          lineType: type || 'operation',
          index: newItemIndex,
        }
      );
    },
    [bankAccount.operations.length, currentAccountDispatch]
  );

  const handleDeleteItem = useCallback(
    (index?: number | null) => {
      if (index == null) {
        return;
      }
      currentAccountDispatch<RemoveOperationPayload>(
        BankAccountsSlice.actions.baAct as any,
        {
          type: OperationActionType.REMOVE_OPERATION,
          index,
        }
      );
    },
    [currentAccountDispatch]
  );

  const selectedItem = selection.selectedIndex
    ? bankAccount.operations[selection.selectedIndex]
    : null;
  const selectedRowColor =
    selectedItem && selectedItem._type === 'operation'
      ? selectedItem.color || null
      : null;
  const handleChangeColor = useCallback(
    (color: string) => {
      if (selection.selectedIndex == null) {
        return;
      }
      currentAccountDispatch<ChangeColorPayload>(
        BankAccountsSlice.actions.baAct as any,
        {
          type: OperationActionType.CHANGE_COLOR,
          index: selection.selectedIndex,
          color,
        }
      );
    },
    [currentAccountDispatch, selection.selectedIndex]
  );

  const [genuineBankAmount, setGenuineBankAmount] = useState<
    DineroObject | undefined
  >(undefined);

  const [selectCategoryOperationIndex, setSelectCategoryOperationIndex] =
    useState<{ index: number; initialCategory?: string } | null>(null);

  const [statsModalState, setStatsModalState] = useState<{
    open: boolean;
    statistics: BankAccountStatistics | null;
  }>({
    open: false,
    statistics: null,
  });

  const computeBankStatistics = useBankAccountStatistics();

  const handleStatsModalOpen = useCallback(() => {
    setStatsModalState({
      open: true,
      statistics: null,
    });
    (async () => {
      const statistics = await computeBankStatistics(bankAccount);
      console.log('Stat computed.');
      setStatsModalState((prev) => ({ ...prev, statistics }));
    })();
  }, [bankAccount, computeBankStatistics]);

  const handleStatsModalClose = useCallback(() => {
    setStatsModalState((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <>
      <StatsModal
        open={statsModalState.open}
        statistics={statsModalState.statistics}
        onClose={handleStatsModalClose}
      />
      <SelectCategoryModal
        open={selectCategoryOperationIndex != null}
        category={selectCategoryOperationIndex?.initialCategory}
        onCategorySelected={(category) => {
          if (selectCategoryOperationIndex != null) {
            currentAccountDispatch<SetCategoryPayload>(
              BankAccountsSlice.actions.baAct as any,
              {
                type: OperationActionType.SET_CATEGORY,
                index: selectCategoryOperationIndex.index,
                category: category?.key,
              }
            );
            setSelectCategoryOperationIndex(null);
          }
        }}
        onCancel={() => {
          setSelectCategoryOperationIndex(null);
        }}
      />
      <Box className={classes.container}>
        <AccountToolbar
          undoDisabled={!canUndo}
          redoDisabled={!canRedo}
          onUndoClicked={() => {
            currentAccountDispatch(BankAccountsSlice.actions.baUndo, {});
          }}
          onRedoClicked={() => {
            currentAccountDispatch(BankAccountsSlice.actions.baRedo, {});
          }}
          onAddClicked={() => {
            handleAddItem(selection.selectedIndex);
          }}
          onAddMonthClicked={() => {
            handleAddItem(selection.selectedIndex, 'month');
          }}
          deleteDisabled={selection.selectedIndex == null}
          onDeleteClicked={() => {
            handleDeleteItem(selection.selectedIndex);
          }}
          rowColor={selectedRowColor}
          onRowColorChange={handleChangeColor}
          rowColorDisabled={
            selection.selectedIndex == null ||
            bankAccount.operations[selection.selectedIndex]._type !==
              'operation'
          }
          onStatsClicked={handleStatsModalOpen}
          onPrintClicked={() => {
            dispatch(BankAccountsSlice.actions.baPrint());
            PrinterTools.downloadPdf(bankAccount)
              .catch((e) => {
                alert("Impossible d'imprimer le compte :\n" + e.message);
              })
              .then(() => {
                dispatch(BankAccountsSlice.actions.baPrintFinish());
              });
          }}
          account={bankAccount}
          genuineBankAmount={genuineBankAmount}
          onGenuineBankAmountEdit={() => {
            const result = window.prompt(
              'Montant (€, décimales séparées par un point) :',
              genuineBankAmount ? '' + genuineBankAmount.amount / 100 : ''
            );
            if (result === '') {
              setGenuineBankAmount(undefined);
            } else if (result != null && !isNaN(+result)) {
              setGenuineBankAmount({
                currency: 'EUR',
                amount: round(+result * 100),
                precision: 2,
              });
            }
          }}
          chequeBook={bankAccount.chequeBook}
          checkerResult={checkerResult}
          onLogClicked={(log) => {
            if (tableRef.current && log.line < bankAccount.operations.length) {
              tableRef.current.scrollToItem(log.line, 'center');
            }
          }}
        />
        <Box className={classes.container}>
          <AccountTableContextMenu
            openAt={contextMenuInfo}
            onClose={closeContextMenu}
            onAddOperationClicked={() => {
              handleAddItem(contextMenuInfo?.index, 'operation');
              closeContextMenu();
            }}
            onAddMonthClicked={() => {
              handleAddItem(contextMenuInfo?.index, 'month');
              closeContextMenu();
            }}
            onDeleteClicked={() => {
              handleDeleteItem(contextMenuInfo?.index);
              closeContextMenu();
            }}
          />
          <CellEditor
            onSubmit={(result) => {
              currentAccountDispatch<SetValuePayload>(
                BankAccountsSlice.actions.baAct as any,
                {
                  type: OperationActionType.SET_VALUE,
                  ...result,
                } as any
              );
              setEditedItemData(null);
            }}
            onCancel={() => {
              setEditedItemData(null);
            }}
            data={editedItemData}
            position={editedItemPosition}
          />
          <AccountTable
            ref={tableRef}
            items={bankAccount.operations}
            selectedIndex={selection.selectedIndex}
            onItemColumnClicked={handleItemColumnClicked}
            onItemColumnDoubleClicked={handleItemColumnDoubleClicked}
            onItemContextMenu={(index, event) => {
              event.preventDefault();
              setContextMenuInfo({
                index,
                x: event.clientX - 4,
                y: event.clientY - 4,
              });
            }}
            onItemCategoryButtonClick={(index) => {
              const line = bankAccount.operations[index];
              if (line._type === 'operation') {
                setSelectCategoryOperationIndex({
                  index,
                  initialCategory: line.category,
                });
              }
            }}
            onScroll={debounce((scroll) => {
              setEditedItemData((prevEditedItemData) => {
                if (prevEditedItemData == null) {
                  return null;
                } else {
                  return {
                    ...prevEditedItemData,
                  };
                }
              });
              dispatch(
                EditorSlice.actions.editorUpdateScrollOffset({
                  fileId: bankAccount.id,
                  scrollOffset: scroll.scrollOffset,
                })
              );
            }, 200)}
          />
        </Box>
      </Box>
    </>
  );
});
AccountEditor.displayName = 'AccountEditor';
