import { Box, Theme } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Edit, FiberManualRecord } from '@material-ui/icons';

export interface AccountStatisticFieldProps {
  value: string;
  label: string;
  graphLegendColor?: string;

  editable?: boolean;
  onClick?: () => void;
}

const useStyle = makeStyles<Theme, { editable: boolean }, string>((theme) => ({
  container: ({ editable }) => ({
    backgroundColor: theme.palette.background.paper,
    transition: 'background-color 200ms ease',
    padding: theme.spacing(0, 1),
    cursor: editable ? 'pointer' : undefined,
    '&:hover': editable
      ? {
          backgroundColor:
            theme.palette.type === 'dark'
              ? theme.palette.grey[700]
              : theme.palette.grey[200],
        }
      : undefined,
  }),
  label: {
    color: theme.palette.grey[500],
    fontSize: '0.8em',
  },
  value: {
    fontWeight: 600,
    fontSize: '1.6em',
  },
}));

export const AccountStatisticField = (props: AccountStatisticFieldProps) => {
  const { label, value, editable, onClick, graphLegendColor } = props;
  const classes = useStyle({ editable: editable || false });

  return (
    <Box className={classes.container} onClick={onClick}>
      <div className={classes.label}>{label}</div>
      <div className={classes.value}>
        {graphLegendColor ? (
          <FiberManualRecord htmlColor={graphLegendColor} />
        ) : null}
        {value} {editable ? <Edit color={'secondary'} /> : null}
      </div>
    </Box>
  );
};
