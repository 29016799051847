import { CellInput } from './CellInput';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dinero from 'dinero.js';

const useStyle = makeStyles((theme) => ({
  textInput: {
    flex: 1,
    padding: theme.spacing(0, 2),
  },
}));

const parseStringifiedDineroAmount = (stringData: string | null): string => {
  try {
    if (stringData) {
      const dineroValue = Dinero(JSON.parse(stringData));
      if (dineroValue.isPositive()) {
        const numericResult = dineroValue.toUnit();
        if (numericResult !== 0) {
          return '' + dineroValue.toUnit();
        }
      }
    }
  } catch (e) {
    /* empty */
  }
  return '';
};

export const DineroCellInput: CellInput = forwardRef((props, ref) => {
  const { value, setValue, submit, cancel } = props;
  const classes = useStyle();

  const inputRef = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  const previousValue = useRef(value);
  const [text, setText] = useState(parseStringifiedDineroAmount(value));

  // Handle value prop changes.
  if (value !== previousValue.current) {
    setText(parseStringifiedDineroAmount(value));
    previousValue.current = value;
  }

  const handleChange = useCallback(
    (newText: string) => {
      setText(newText);

      // Try to call setValue with the draft text if possible.
      if (!isNaN(+newText)) {
        try {
          const stringifiedResult = JSON.stringify(
            Dinero({
              currency: 'EUR',
              amount: Math.round(+newText * 100),
            }).toObject()
          );
          setValue(stringifiedResult);
          previousValue.current = stringifiedResult; // We don't want to update the text from value in the next tick.
        } catch (e) {
          /* empty */
        }
      }
    },
    [setValue]
  );

  return (
    <InputBase
      value={text}
      onChange={(event) => {
        handleChange(event.target.value);
      }}
      autoFocus={true}
      inputRef={inputRef}
      className={classes.textInput}
      onFocus={(event) => {
        event.target.setSelectionRange(0, Number.MAX_SAFE_INTEGER);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          // "Return" pressed.
          submit();
        } else if (event.key === 'Escape') {
          cancel();
        }
      }}
    />
  );
});
DineroCellInput.displayName = 'DineroCellInput';
