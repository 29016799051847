import { CellInput } from './CellInput';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  textInput: {
    flex: 1,
    padding: theme.spacing(0, 2),
  },
}));

export const TextCellInput: CellInput = forwardRef((props, ref) => {
  const { value, setValue, submit, cancel } = props;
  const classes = useStyle();

  const inputRef = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  return (
    <InputBase
      value={value || ''}
      onChange={(event) => {
        setValue(event.target.value);
      }}
      autoFocus={true}
      inputRef={inputRef}
      className={classes.textInput}
      onFocus={(event) => {
        event.target.setSelectionRange(0, Number.MAX_SAFE_INTEGER);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          // "Return" pressed.
          submit();
        } else if (event.key === 'Escape') {
          cancel();
        }
      }}
    />
  );
});
TextCellInput.displayName = 'TextCellInput';
