import React, { ReactNode } from 'react';
import { Button, Grow, Paper, Popper } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

export interface PopperButtonProps {
  buttonContent: (expanded: boolean) => ReactNode;
  children: ReactNode;

  top?: boolean;
}

const useStyle = makeStyles((theme) => ({
  button: {},
  buttonExpanded: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[4],
    borderColor: 'transparent',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? theme.palette.grey[700]
          : theme.palette.grey[100],
    },
  },
  expansionPaper: {
    borderTopRightRadius: 0,
    padding: theme.spacing(2, 2, 2, 2),
  },
}));

export const PopperButton = (props: PopperButtonProps) => {
  const { buttonContent, children, top } = props;

  const classes = useStyle();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      <Button
        className={clsx(
          classes.button,
          anchorEl != null ? classes.buttonExpanded : undefined
        )}
        onClick={handleClick}
        variant={'outlined'}
      >
        {buttonContent(anchorEl != null)}
      </Button>
      <Popper
        open={anchorEl != null}
        anchorEl={anchorEl}
        placement={top ? 'top-end' : 'bottom-end'}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            timeout={350}
            style={{ transformOrigin: 'top right' }}
          >
            <Paper className={classes.expansionPaper} elevation={4}>
              {children}
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
