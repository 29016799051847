import { TreeItem, TreeItemProps } from '@material-ui/lab';
import { BankAccountLine } from '../../types/bank-account/BankAccountLine';
import React from 'react';
import { DateRange, FiberManualRecord } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';

export interface AccountLineTreeItemProps
  extends Omit<TreeItemProps, 'nodeId' | 'label'> {
  line: BankAccountLine;
}

const useStyle = makeStyles((theme) => ({
  itemLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  itemLabelText: {
    marginLeft: theme.spacing(1),
  },
}));

export const AccountLineTreeItemProps = (props: AccountLineTreeItemProps) => {
  const { line, children } = props;
  const classes = useStyle();

  return (
    <TreeItem
      {...props}
      nodeId={line.uuid}
      label={
        <div className={classes.itemLabel}>
          {line._type === 'month' ? (
            <DateRange />
          ) : (
            <FiberManualRecord htmlColor={line.color} />
          )}
          <span className={classes.itemLabelText}>
            {(line._type === 'month' ? line.name : line.description) ||
              '<vide>'}
          </span>
        </div>
      }
    >
      {children}
    </TreeItem>
  );
};
