import { BankAccount } from '../types/bank-account/BankAccount';
import {
  BankAccountCheckerResult,
  BankAccountCheckerWorkerInterface,
} from './BankAccountChecker';
import { useEffect, useRef, useState } from 'react';
import * as Comlink from 'comlink';

export const useBankAccountChecker = (
  bankAccount: BankAccount
): BankAccountCheckerResult | null => {
  const workerRef = useRef(
    Comlink.wrap<BankAccountCheckerWorkerInterface>(
      new Worker(new URL('./BankAccountChecker.worker', import.meta.url))
    )
  );
  const [workerResult, setWorkerResult] =
    useState<BankAccountCheckerResult | null>(null);

  useEffect(() => {
    (async () => {
      setWorkerResult(await workerRef.current.check(bankAccount));
    })();
  }, [bankAccount]);

  return workerResult != null && workerResult.accountId === bankAccount.id
    ? workerResult
    : null;
};
