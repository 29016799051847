import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankAccountsSlice } from '../bank-accounts/BankAccountsSlice';

export enum AppThemeType {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export interface EditorState {
  scrollOffsets: { [fileId: string]: number };
  themeType: AppThemeType;
}

const initialState: EditorState = {
  scrollOffsets: {},
  themeType: AppThemeType.LIGHT,
};

export const EditorSlice = createSlice({
  name: 'Editor',
  initialState,
  reducers: {
    editorUpdateScrollOffset: (
      state,
      action: PayloadAction<{ fileId: string; scrollOffset: number | null }>
    ) => {
      state.scrollOffsets[action.payload.fileId] =
        action.payload.scrollOffset || 0;
    },
    setThemeType: (
      state,
      action: PayloadAction<{ themeType: AppThemeType }>
    ) => {
      state.themeType = action.payload.themeType;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(BankAccountsSlice.actions.baOpenSuccessful, (state, action) => {
        state.scrollOffsets[action.payload.fileId] = 0;
      })
      .addCase(BankAccountsSlice.actions.baCloseSuccessful, (state, action) => {
        delete state.scrollOffsets[action.payload.fileId];
      });
  },
});
