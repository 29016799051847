import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  AccountFileListItem,
  AccountFileListItemRightButtonType,
} from '../../../components/items/AccountFileListItem';
import {
  BankAccount,
  BankAccountStatus,
} from '../../../types/bank-account/BankAccount';
import {
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { closeFileThunk } from '../../../redux/thunks/CloseFileThunk';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentBankAccountId } from '../../../redux/bank-accounts/CurrentAccountHook';
import { RootState } from '../../../redux/rootReducer';
import { BankAccountsSlice } from '../../../redux/bank-accounts/BankAccountsSlice';
import { useIsOpeningAccounts } from '../../../redux/bank-accounts/BankAccountHooks';

const accountFileItemHeight = 48;

export const AccountsList = () => {
  const dispatch = useDispatch();
  const list = useSelector<RootState, BankAccount[]>((state) =>
    state.bankAccounts.bankAccountsList.map(
      (id) => state.bankAccounts.bankAccounts[id]
    )
  );
  const isOpeningAccount = useIsOpeningAccounts();
  const currentBankAccountId = useCurrentBankAccountId();
  const closeAccount = useCallback(
    (fileId: string) => dispatch(closeFileThunk(fileId)),
    [dispatch]
  );
  const switchCurrent = useCallback(
    (fileId: string) =>
      dispatch(BankAccountsSlice.actions.baSwitchCurrent({ fileId })),
    [dispatch]
  );

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }
          dispatch(
            BankAccountsSlice.actions.baReorder({
              startIndex: result.source.index,
              endIndex: result.destination.index,
            })
          );
        }}
      >
        <Droppable
          droppableId={'accountFileListDroppable'}
          direction={'vertical'}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ minHeight: list.length * accountFileItemHeight }}
            >
              {list.map((item, index) => {
                const isLoading =
                  item.status === BankAccountStatus.CLOSING ||
                  item.status === BankAccountStatus.SAVING;
                const isError = item.saveError != null;
                return (
                  <AccountFileListItem
                    key={item.id}
                    accountFileId={item.id}
                    accountName={item.name}
                    selected={currentBankAccountId === item.id}
                    index={index}
                    onClick={() => switchCurrent(item.id)}
                    closeButtonType={
                      isLoading
                        ? AccountFileListItemRightButtonType.LOADING
                        : isError
                        ? AccountFileListItemRightButtonType.SAVE_ERROR
                        : AccountFileListItemRightButtonType.CLOSE
                    }
                    onCloseButtonClicked={() => closeAccount(item.id)}
                  />
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isOpeningAccount ? (
        <ListItem button>
          <ListItemIcon>
            <CircularProgress size={22} color={'secondary'} />
          </ListItemIcon>
          <ListItemText>
            <Skeleton variant="text" />
          </ListItemText>
        </ListItem>
      ) : null}
    </>
  );
};
