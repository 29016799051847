import { useCallback, useMemo, useState } from 'react';

interface ItemWithUuid {
  uuid: string;
}

export const useSelectedItemHook = <ItemType extends ItemWithUuid>(
  items: ItemType[]
) => {
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const selectedIndex = useMemo(() => {
    if (selectedUuid != null) {
      const foundIndex = items.findIndex((o) => o.uuid === selectedUuid);
      if (foundIndex >= 0) {
        return foundIndex;
      }
    }
    return null;
  }, [items, selectedUuid]);

  // Check if the selected uuid has been deleted. If it's set but it can't be found (selectedIndex == null),
  // it means the item was deleted.
  let reallySelectedUuid = selectedUuid;
  if (selectedUuid != null && selectedIndex == null) {
    setSelectedUuid(null);
    reallySelectedUuid = null;
  }

  const setSelectedIndex = useCallback(
    (index: number | null) => {
      if (index != null && index >= 0 && index < items.length) {
        setSelectedUuid(items[index].uuid);
      } else {
        setSelectedUuid(null);
      }
    },
    [items]
  );

  return {
    selectedUuid: reallySelectedUuid,
    setSelectedUuid,
    selectedIndex,
    setSelectedIndex,
  };
};
