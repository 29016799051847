import React from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { Add, Delete, Edit, GetApp, Publish } from '@material-ui/icons';

export interface PrefilledLinesToolbarProps {
  containerClassName?: string;

  onAddClicked?: () => void;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;

  onImportClicked?: () => void;
  onExportClicked?: () => void;

  editDisabled?: boolean;
  deleteDisabled?: boolean;

  saving?: boolean;
}

export const PrefilledLinesToolbar = (props: PrefilledLinesToolbarProps) => {
  return (
    <Box className={props.containerClassName}>
      <ButtonGroup variant={'outlined'} color={'secondary'} size={'small'}>
        <Tooltip title={'Créer une ligne pré-remplie...'}>
          <Button onClick={props.onAddClicked}>
            <Add />
          </Button>
        </Tooltip>
        <Tooltip title={'Editer la ligne pré-remplie...'}>
          <Button disabled={props.editDisabled} onClick={props.onEditClicked}>
            <Edit />
          </Button>
        </Tooltip>
        <Tooltip title={'Supprimer la ligne pré-remplie'}>
          <Button
            disabled={props.deleteDisabled}
            onClick={props.onDeleteClicked}
          >
            <Delete />
          </Button>
        </Tooltip>

        <Tooltip title={'Importer...'}>
          <Button onClick={props.onImportClicked}>
            <Publish />
          </Button>
        </Tooltip>
        <Tooltip title={'Exporter'}>
          <Button onClick={props.onExportClicked}>
            <GetApp />
          </Button>
        </Tooltip>
      </ButtonGroup>
      {props.saving ? (
        <CircularProgress
          style={{ marginLeft: 8 }}
          size={24}
          color={'secondary'}
        />
      ) : null}
    </Box>
  );
};
