import {
  Box,
  CircularProgress,
  Container,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { CONFIG } from '../config';

const useStyle = makeStyles({
  container: {
    marginTop: '20%',
    padding: 32,
  },
  title: {
    fontSize: 28,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: 16,
  },
  centered: {
    textAlign: 'center',
  },
  environment: {
    textAlign: 'center',
    marginBottom: 32,
  },
  error: {
    color: 'red',
  },
});

export interface SplashScreenProps {
  failure: string | null;
}

export const SplashScreen = (props: SplashScreenProps) => {
  const { failure } = props;
  const classes = useStyle();
  return (
    <Container className={classes.container} maxWidth={'xs'}>
      <Box className={classes.title}>Account Manager</Box>
      <Box className={classes.environment}>{CONFIG.envName}</Box>
      <Box className={classes.centered}>
        {failure != null ? (
          <Box className={classes.error}>Erreur : {failure}</Box>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Container>
  );
};
