import { CONFIG } from '../config';
import { User } from '../types/User';
import { GoogleApiFile } from './GoogleApiFile';
import utf8 from 'utf8';

let tokenClient: any;
let currentUser: User | null = null;

export class GoogleApiTools {
  public static async init(): Promise<void> {
    await Promise.all([
      new Promise((resolve) => {
        gapi.load('picker', () => resolve(null));
      }),
      new Promise((resolve) => {
        gapi.load('client', () => resolve(null));
      }),
    ]);
    try {
      await gapi.client.load(
        'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
      );
      await gapi.client.load(
        'https://www.googleapis.com/discovery/v1/apis/oauth2/v2/rest'
      );

      // Init the client ready to ask for tokens.
      tokenClient = (google as any).accounts.oauth2.initTokenClient({
        client_id: CONFIG.gapi.clientId,
        scope:
          'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.appdata',
        prompt: 'select_account',
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public static async login(hint?: string): Promise<User | null> {
    const accessToken = await this.getNewToken(hint);
    const userInfo = await gapi.client.oauth2.userinfo.get();
    currentUser = {
      displayName: userInfo.result.name ?? 'Inconnu',
      displayIconUrl: userInfo.result.picture ?? '',
      email: userInfo.result.email ?? '',
      id: userInfo.result.id ?? '',
      accessToken,
    };
    return currentUser;
  }

  public static logout(): void {
    (google as any).accounts.oauth2.revoke(currentUser?.accessToken);
  }

  public static isLoggedIn(): boolean {
    return !!currentUser;
  }

  public static getLoggedInUser(): User {
    if (currentUser) {
      return currentUser;
    } else {
      throw Error(
        'GoogleApiTools: Cannot call getLoggedInUser when no user is logged in.'
      );
    }
  }

  /**
   * Open a Google Drive file and return a promise returning it some metadata and its content.
   * @param fileId
   */
  public static async openFile(fileId: string): Promise<GoogleApiFile> {
    return this.rewriteGApiErrors(async () => {
      const fileMetaData = await gapi.client.drive.files.get({
        fileId,
      });
      const file = await gapi.client.drive.files.get({
        fileId,
        alt: 'media',
      });

      let decodedContent: string;
      try {
        decodedContent = utf8.decode(file.body);
      } catch (e: any) {
        console.warn(
          'Unable to decode UTF8, some text errors may be visible! ',
          e.message
        );
        decodedContent = file.body;
      }

      return {
        fileId,
        name: fileMetaData.result.name || 'Sans titre',
        content: decodedContent,
      };
    });
  }

  public static async createFile(
    name: string,
    folderId: string,
    mimeType: string
  ): Promise<string | null> {
    return this.rewriteGApiErrors(async () => {
      const creationResult = await gapi.client.drive.files.create({
        fields: 'id',
        resource: {
          name,
          mimeType,
          parents: [folderId],
        },
      });
      return creationResult.result.id || null;
    });
  }

  public static async saveToFile(
    fileId: string,
    content: string,
    mimeType: string
  ): Promise<string> {
    return this.rewriteGApiErrors(async () => {
      await gapi.client.request({
        path: '/upload/drive/v3/files/' + fileId,
        method: 'PATCH',
        params: {
          uploadType: 'media',
        },
        headers: {
          'Content-Type': mimeType,
        },
        body: content,
      });
      return fileId;
    });
  }

  public static async deleteFile(fileId: string): Promise<void> {
    return this.rewriteGApiErrors(async () => {
      await gapi.client.drive.files.delete({
        fileId,
      });
    });
  }

  public static async searchFileByName(
    spaces: string,
    name: string
  ): Promise<{ id: string; name: string } | null> {
    const page = await this.rewriteGApiErrors(() =>
      gapi.client.drive.files.list({
        spaces,
        q: `name = '${name}'`,
        fields: 'nextPageToken, files(id, name)',
      })
    );
    if (!page.result.files) {
      return null;
    }
    const foundFile = page.result.files;
    if (foundFile && foundFile.length > 0) {
      const firstFile = foundFile[0];
      if (firstFile.id != null && firstFile.name != null) {
        return firstFile as any;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * Get the name of a file or folder from Google Drive API.
   * @param fileId
   */
  public static async getFilename(fileId: string): Promise<string> {
    const fileMetaData = await gapi.client.drive.files.get({
      fileId,
    });
    return fileMetaData.result.name || 'Inconnu';
  }

  private static async rewriteGApiErrors<T>(fn: () => Promise<T>): Promise<T> {
    try {
      return await fn();
    } catch (e: any) {
      // TODO: Manage auth expiration and act for a new token.
      if (e.result?.error?.code === 401 || e.result?.error?.code === 403) {
        // It means the token is expired, log in again and then retry the request.
        // We try to force the user to select the same account as previously
        alert(
          'Vous avez été déconnecté de Google, veuillez vous reconnectez avec le même compte Google...'
        );
        await this.login(currentUser?.email);
        return await this.rewriteGApiErrors(fn);
      } else if (e.result && e.result.error && e.result.error.message) {
        throw Error(e.result.error.message);
      } else if (e.message) {
        throw Error(e.message);
      } else {
        throw Error('Erreur inconnue !');
      }
    }
  }

  private static async getNewToken(hint?: string): Promise<string> {
    const tokenResponse: any = await new Promise((resolve) => {
      tokenClient.callback = (tr: any) => {
        resolve(tr);
      };
      tokenClient.requestAccessToken({
        hint,
      });
    });
    return tokenResponse.access_token;
  }
}
