import React, { useCallback, useMemo, useState } from 'react';
import { TreeView } from '@material-ui/lab';
import { BankAccountLine } from '../../types/bank-account/BankAccountLine';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { CollectionsTools } from '../../tools/CollectionsTools';
import { AccountLineTreeItemProps } from './AccountLineTreeItem';

export interface AccountNavigatorTreeProps {
  lines: BankAccountLine[];
  className?: string;

  onTreeItemClicked?: (uuid: string) => void;
}

type BankAccountLineWithChildren = BankAccountLine & {
  children: BankAccountLine[];
};

export const AccountNavigatorTree = (props: AccountNavigatorTreeProps) => {
  const { className, lines, onTreeItemClicked } = props;

  const filteredLines = useMemo(() => {
    return lines
      .filter(
        (line) =>
          line._type === 'month' ||
          (line._type === 'operation' &&
            line.color != null &&
            line.color.toLowerCase() !== '#ffffff')
      )
      .reduce((groupedByMonths, line) => {
        if (line._type === 'month' || groupedByMonths.length <= 0) {
          return [...groupedByMonths, { ...line, children: [] }];
        } else {
          // Add the line to the month
          const lastMonth = groupedByMonths[groupedByMonths.length - 1];
          if (lastMonth._type === 'month') {
            return [
              ...groupedByMonths.filter(
                (l, index) => index < groupedByMonths.length - 1
              ),
              {
                ...lastMonth,
                children: [...lastMonth.children, line],
              },
            ];
          } else {
            // The previous line is not a month (the list doesn't contains a month yet).
            return [...groupedByMonths, { ...line, children: [] }];
          }
        }
      }, [] as BankAccountLineWithChildren[]);
  }, [lines]);

  const handleClick = useCallback(
    (uuid: string) => {
      if (onTreeItemClicked) {
        onTreeItemClicked(uuid);
      }
    },
    [onTreeItemClicked]
  );

  const [expanded, setExpanded] = useState<string[]>([]);

  return (
    <TreeView
      className={className}
      disableSelection={true}
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      expanded={expanded}
      onNodeToggle={(e, nodes) => setExpanded(nodes)}
    >
      {filteredLines.map((line) => (
        <AccountLineTreeItemProps
          line={line}
          key={line.uuid}
          onLabelClick={(e) => {
            handleClick(line.uuid);
            setExpanded((prev) =>
              CollectionsTools.editAsSet(prev, (s) => s.add(line.uuid))
            );
            e.preventDefault();
          }}
        >
          {line.children.map((child) => (
            <AccountLineTreeItemProps
              line={child}
              key={child.uuid}
              onLabelClick={() => handleClick(child.uuid)}
            />
          ))}
        </AccountLineTreeItemProps>
      ))}
    </TreeView>
  );
};
