import { useAppSelector } from '../redux/hooks/ReduxHooks';
import { BankAccountTools } from '../tools/BankAccountTools';
import { usePreventClose } from './PreventCloseHook';

export const usePreventCloseWhenNotSaved = (enabled: boolean) => {
  const allAccounts = useAppSelector((state) =>
    state.bankAccounts.bankAccountsList.map(
      (uuid) => state.bankAccounts.bankAccounts[uuid]
    )
  );
  const someAccountsNeedSave = allAccounts.some((account) =>
    BankAccountTools.isSaveNeeded(account)
  );
  const prefilledLinesNeedSave = useAppSelector(
    (state) =>
      state.prefilledLines.lastChangeEpoch != null &&
      (state.prefilledLines.lastSaveEpoch == null ||
        state.prefilledLines.lastChangeEpoch >
          state.prefilledLines.lastSaveEpoch)
  );

  const needSave = someAccountsNeedSave || prefilledLinesNeedSave;

  // If somethings need save and it's enabled, prevent close by displaying the
  // navigator's standard popup.
  usePreventClose(needSave && enabled);
};
