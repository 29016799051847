import { Box, Button, ButtonGroup, Tooltip } from '@material-ui/core';
import {
  Add,
  AddAlert,
  BarChart,
  Delete,
  Print,
  Redo,
  Undo,
} from '@material-ui/icons';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ColorPickerButton } from '../../buttons/ColorPickerButton';
import { AccountAmountsPopper } from './AccountAmountsPopper';
import { BankAccount } from '../../../types/bank-account/BankAccount';
import { DineroObject } from 'dinero.js';
import { AccountChequeBookPopper } from './AccountChequeBookPopper';
import { BankAccountChequeBook } from '../../../types/bank-account/BankAccountChequeBook';
import {
  BankAccountCheckerLog,
  BankAccountCheckerResult,
} from '../../../workers/BankAccountChecker';
import { AccountCheckerPopper } from './AccountCheckerPopper';

export interface AccountToolbarProps {
  undoDisabled?: boolean;
  redoDisabled?: boolean;
  onUndoClicked?: () => void;
  onRedoClicked?: () => void;

  deleteDisabled?: boolean;
  onAddClicked?: () => void;
  onAddMonthClicked?: () => void;
  onDeleteClicked?: () => void;

  rowColor: string | null;
  rowColorDisabled?: boolean;
  onRowColorChange: (color: string) => void;

  onStatsClicked?: () => void;

  onPrintClicked?: () => void;

  account?: BankAccount;

  genuineBankAmount?: DineroObject;
  onGenuineBankAmountEdit?: () => void;

  chequeBook?: BankAccountChequeBook;

  checkerResult?: BankAccountCheckerResult | null;
  onLogClicked?: (log: BankAccountCheckerLog) => void;
}

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  spacer: {
    flex: 1,
  },
}));

export const AccountToolbar = (props: AccountToolbarProps) => {
  const {
    onUndoClicked,
    onRedoClicked,
    redoDisabled,
    undoDisabled,
    deleteDisabled,
    onAddClicked,
    onAddMonthClicked,
    onDeleteClicked,
    rowColor,
    rowColorDisabled,
    onRowColorChange,
    onStatsClicked,
    account,
    genuineBankAmount,
    onGenuineBankAmountEdit,
    chequeBook,
    checkerResult,
    onLogClicked,
    onPrintClicked,
  } = props;

  const classes = useStyle();

  return (
    <Box className={classes.container}>
      <ButtonGroup size={'large'} variant={'contained'} color={'secondary'}>
        <Tooltip title={'Annuler'}>
          <Button disabled={undoDisabled} onClick={onUndoClicked}>
            <Undo />
          </Button>
        </Tooltip>

        <Tooltip title={'Refaire'} hidden={redoDisabled}>
          <Button disabled={redoDisabled} onClick={onRedoClicked}>
            <Redo />
          </Button>
        </Tooltip>
      </ButtonGroup>

      <ButtonGroup size={'large'} variant={'contained'} color={'secondary'}>
        <Tooltip title={'Ajouter'}>
          <Button onClick={onAddClicked}>
            <Add />
          </Button>
        </Tooltip>

        <Tooltip title={'Ajouter une séparation de mois'}>
          <Button onClick={onAddMonthClicked}>
            <AddAlert />
          </Button>
        </Tooltip>

        <Tooltip
          title={'Supprimer la ligne sélectionnée'}
          hidden={deleteDisabled}
        >
          <Button disabled={deleteDisabled} onClick={onDeleteClicked}>
            <Delete />
          </Button>
        </Tooltip>
      </ButtonGroup>

      <ButtonGroup size={'large'} variant={'contained'} color={'secondary'}>
        <ColorPickerButton
          colorValue={rowColor || '#FFFFFF'}
          buttonTooltip={'Changer la couleur de fond de la ligne'}
          disabled={rowColorDisabled}
          onColorValueSubmit={onRowColorChange}
        />
      </ButtonGroup>

      <ButtonGroup size={'large'} variant={'contained'} color={'secondary'}>
        <Tooltip title={'Statistiques'}>
          <Button onClick={onStatsClicked}>
            <BarChart />
          </Button>
        </Tooltip>
      </ButtonGroup>

      <ButtonGroup size={'large'} variant={'contained'} color={'secondary'}>
        <Tooltip title={'Imprimer'}>
          <Button onClick={onPrintClicked}>
            <Print />
          </Button>
        </Tooltip>
      </ButtonGroup>

      <Box className={classes.spacer} />

      {account && checkerResult !== undefined ? (
        <AccountCheckerPopper
          checkerResult={checkerResult}
          onLogClicked={onLogClicked}
        />
      ) : null}

      {account ? <AccountChequeBookPopper chequeBook={chequeBook} /> : null}

      {account ? (
        <AccountAmountsPopper
          account={account}
          genuineBankAmount={genuineBankAmount}
          onGenuineBankAmountEdit={onGenuineBankAmountEdit}
        />
      ) : null}
    </Box>
  );
};
