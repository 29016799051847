import React, { useCallback, useMemo, useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';

interface Values {
  chequeBookId: string;
  maxChequeNumber: string;
}

const validationSchema = yup.object().shape({
  chequeBookId: yup.string().required(),
  maxChequeNumber: yup.number().required().integer().min(1),
});

const useChequeBookForm = (
  defaultValues: Values,
  onSubmit: (values: Values) => void
) => {
  const [initialValues, setInitialValues] = useState(defaultValues);

  const form = useFormik<Values>({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });
  const resetValues = useCallback(
    (values: Values) => {
      setInitialValues(values);
      form.resetForm();
    },
    [form]
  );
  return useMemo(
    () => ({
      ...form,
      resetValues,
    }),
    [form, resetValues]
  );
};

export interface Props {
  form: ReturnType<typeof useChequeBookForm>;
  cancellable?: boolean;
  onCancelClicked?: () => void;
}

const useStyle = makeStyles((theme) => ({
  form: {
    textAlign: 'right',
    '& > *': {
      margin: theme.spacing(1, 1),
    },
  },
}));

const Component = (props: Props) => {
  const { form, cancellable, onCancelClicked } = props;
  const classes = useStyle();
  return (
    <form className={classes.form} onSubmit={form.handleSubmit}>
      <TextField
        name={'chequeBookId'}
        autoFocus
        variant={'filled'}
        label={'Numéro du chéquier'}
        value={form.values.chequeBookId}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.errors.chequeBookId != null}
        helperText={
          form.errors.chequeBookId != null ? 'Numéro de chéquier invalide' : ' '
        }
      />
      <TextField
        name={'maxChequeNumber'}
        variant={'filled'}
        label={'Nombre de chèques'}
        value={form.values.maxChequeNumber}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.errors.maxChequeNumber != null}
        helperText={
          form.errors.maxChequeNumber != null ? 'Nombre invalide' : ' '
        }
      />
      <br />
      {cancellable ? (
        <Button
          type={'button'}
          color={'primary'}
          variant={'outlined'}
          onClick={onCancelClicked}
        >
          Annuler
        </Button>
      ) : null}
      <Button type={'submit'} color={'primary'} variant={'contained'}>
        Valider
      </Button>
    </form>
  );
};

export const AccountChequeBookResetForm = {
  Component,
  useForm: useChequeBookForm,
};

// eslint-disable-next-line no-redeclare,@typescript-eslint/no-namespace
export declare namespace AccountChequeBookResetForm {
  export type FormValues = Values;
  export type ComponentProps = Props;
  export type FormState = ReturnType<typeof useChequeBookForm>;
}
