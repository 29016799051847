import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../rootReducer';
import { useCallback } from 'react';

export const useAppSelector = <TSelected>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
) => useSelector<RootState, TSelected>(selector, equalityFn);

export const useEpochDispatch = () => {
  const dispatch = useDispatch();
  return useCallback(
    <PayloadType extends object>(
      actionCreator: (payload: PayloadType & { epoch: number }) => {
        type: string;
        payload: PayloadType & { epoch: number };
      },
      payload: PayloadType
    ) => {
      const payloadWithEpoch = Object.assign({}, payload, {
        epoch: Date.now(),
      });
      return dispatch(actionCreator(payloadWithEpoch));
    },
    [dispatch]
  );
};
