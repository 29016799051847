import { Button, makeStyles } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginThunk } from '../redux/thunks/LoginThunk';
import { AccountCircle } from '@material-ui/icons';
import { CenteredPaper } from '../components/common/CenteredPaper';
import { RootState } from '../redux/rootReducer';

const useStyle = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 0),
    padding: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
  },
  titleIcon: {
    textAlign: 'center',
    fontSize: 64,
  },
  loginButton: {
    marginTop: theme.spacing(4),
    width: '100%',
  },
  loginButtonIcon: {
    marginRight: theme.spacing(1),
    maxHeight: 24,
  },
}));

export const LoginPage = () => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const isPending = useSelector<RootState, boolean>(
    (store) => store.auth.pending
  );

  const onLoginButtonClicked = useCallback(
    () => dispatch(loginThunk()),
    [dispatch]
  );

  return (
    <CenteredPaper icon={AccountCircle} title={'Connexion'}>
      Accédez à vos comptes en vous connectant avec Google.{' '}
      <em>
        L&apos;intégralité de vos données est stockée sur votre espace Google
        Drive personnel.
      </em>
      <br />
      <Button
        className={classes.loginButton}
        color={'primary'}
        disabled={isPending}
        onClick={onLoginButtonClicked}
        variant={'contained'}
        size={'large'}
      >
        <img
          alt={'Google logo'}
          className={classes.loginButtonIcon}
          src={'/assets/g-logo.png'}
        />
        Se connecter avec Google
      </Button>
    </CenteredPaper>
  );
};
