import { PrefilledLineData } from '../../types/prefilled-line/PrefilledLineData';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Box, ListItem, ListItemText, useTheme } from '@material-ui/core';
import React, { CSSProperties } from 'react';

export interface PrefilledLineItemProps {
  item: PrefilledLineData;
  onItemClicked?: () => void;
  selected?: boolean;
  isDragging?: boolean;

  style?: CSSProperties;
  provided?: DraggableProvided;
}

export const PrefilledLineItem = (props: PrefilledLineItemProps) => {
  const { item, onItemClicked, provided, style, selected, isDragging } = props;
  const refProps = { ref: provided?.innerRef };
  const theme = useTheme();
  return (
    <Box
      boxShadow={isDragging ? 4 : 0}
      {...refProps}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      style={{
        backgroundColor: theme.palette.background.paper,
        ...style,
        ...provided?.draggableProps.style,
      }}
    >
      <ListItem dense={true} button selected={selected} onClick={onItemClicked}>
        <ListItemText primary={item.name} />
      </ListItem>
    </Box>
  );
};
