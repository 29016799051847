import { BankAccountStatus } from '../../types/bank-account/BankAccount';
import { useAppSelector } from '../hooks/ReduxHooks';

export const useAccountStatus = (fileId: string): BankAccountStatus =>
  useAppSelector((state) => state.bankAccounts.bankAccounts[fileId].status);

export const useIsSavingAccounts = (): boolean =>
  useAppSelector((state) =>
    Object.keys(state.bankAccounts.bankAccounts)
      .map((fileId) => state.bankAccounts.bankAccounts[fileId])
      .map((bankAccount) => bankAccount.status === BankAccountStatus.SAVING)
      .some((isSaving) => isSaving)
  );

export const useIsOpeningAccounts = () =>
  useAppSelector<boolean>(
    (state) => state.bankAccounts.openingBankAccountId.length > 0
  );

export const useNewAccountError = (): string | null =>
  useAppSelector((state) => state.bankAccounts.creatingBankAccountError);
export const useOpenAccountErrorsMessage = (): string | null => {
  const errors = useAppSelector(
    (state) => state.bankAccounts.openingBankAccountError
  );
  if (Object.keys(errors).length === 0) {
    return null;
  }

  let message = '';
  Object.keys(errors).forEach((fileId) => {
    const error = errors[fileId];
    message +=
      'Fichier ' + (error.name ?? fileId) + ' :\n' + error.error + '\n\n';
  });
  return message.trim();
};

export const useIsUndoable = (fileId: string): boolean =>
  useAppSelector(
    (state) => state.bankAccounts.bankAccounts[fileId].undo.length > 0
  );
export const useIsRedoable = (fileId: string): boolean =>
  useAppSelector(
    (state) => state.bankAccounts.bankAccounts[fileId].redo.length > 0
  );
