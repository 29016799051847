import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import React, { ReactNode } from 'react';

export interface SimpleModalProps extends Omit<DialogProps, 'children'> {
  title: string;
  children: ReactNode;
  buttons: string[];
  onButtonClicked: (i: number) => void;
}

export const SimpleModal = (props: SimpleModalProps) => {
  const { title, children, buttons, onButtonClicked } = props;

  return (
    <Dialog {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {buttons.map((buttonLabel, index) => (
          <Button
            key={buttonLabel}
            color={'primary'}
            type={'button'}
            onClick={() => onButtonClicked(index)}
          >
            {buttonLabel}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};
