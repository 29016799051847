import { PayloadAction } from '@reduxjs/toolkit';

export const illegalAction = <S>(
  reducerName: string,
  action: PayloadAction<any>,
  prevState: S
): never => {
  throw Error(
    `Reducer ${reducerName}: Cannot apply action ${
      action.type
    } on state ${JSON.stringify(prevState)}.`
  );
};
