import { useCallback, useState } from 'react';
import { BankAccountCreationData } from '../../../types/data/BankAccountCreationData';
import { BankAccountNewModalProps, StepId } from './BankAccountNewModal';
import { BankAccountNewModalFileStepData } from './BankAccountNewModalFileStep';

export interface BankAccountNewModalHookResult
  extends BankAccountNewModalProps {
  open: () => void;
}

/**
 * Hook to manage the bank account new modal.
 * @param onSubmit
 */
export const useBankAccountNewModal = (
  onSubmit: (creationData: BankAccountCreationData) => void
): BankAccountNewModalHookResult => {
  const [currentStep, setCurrentStep] = useState<StepId | null>(null);
  const [creationData, setCreationData] = useState<BankAccountCreationData>({
    fileName: '',
    folderFileId: '-1',
  });

  const onCloseRequested = useCallback(() => {
    setCurrentStep(null);
    setCreationData({
      fileName: '',
      folderFileId: '-1',
    });
  }, []);

  return {
    currentStep,
    creationData,
    setCreationData,
    open: () => setCurrentStep(StepId.FILE),
    onFileStepCompleted: (data: BankAccountNewModalFileStepData) => {
      setCreationData((prevData) => ({
        ...prevData,
        fileName: data.fileName,
        folderFileId: data.folderFileId,
      }));
      setCurrentStep(StepId.SETTINGS);
    },
    onSettingsStepCompleted: () => {
      // TODO: Settings set.
      setCurrentStep(StepId.RECAP);
    },
    onSubmit: () => {
      onSubmit(creationData);
      onCloseRequested();
    },
    onCloseRequested,
    onFileStepCancelClicked: onCloseRequested,
    onSettingsStepCancelClicked: () => {
      setCurrentStep(StepId.FILE);
    },
    onRecapStepCancelClicked: () => {
      setCurrentStep(StepId.SETTINGS);
    },
  };
};
