import {
  Box,
  Divider,
  Drawer,
  List,
  ListSubheader,
  makeStyles,
  Tab,
  Tabs,
} from '@material-ui/core';
import React, { useState } from 'react';
import { AccountsList } from './AccountsList';
import { CurrentAccountNavigator } from './CurrentAccountNavigator';
import { useCurrentBankAccount } from '../../../redux/bank-accounts/CurrentAccountHook';
import { Navigation, PlaylistAdd, Search } from '@material-ui/icons';
import { PrefilledLinesNavigator } from './PrefilledLinesNavigator';
import { SearchPanel } from '../../../components/search-panel/SearchPanel';

export interface LeftDrawerProps {
  onWantToNavigateToLineUuid?: (uuid: string) => void;
}

const drawerWidth = 300;

const useStyle = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbarPaddingDummy: theme.mixins.toolbar,
  tabs: {
    '& .MuiTab-root': {
      minWidth: 0,
    },
    '& .MuiTab-wrapper': {
      fontSize: 10,
    },
  },
}));

export const LeftDrawer = (props: LeftDrawerProps) => {
  const { onWantToNavigateToLineUuid } = props;
  const classes = useStyle();

  const currentBankAccount = useCurrentBankAccount();

  const [tab, setTab] = useState(0);

  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchValue, setSearchValue] = useState<string | null>(null);

  return (
    <>
      <Drawer
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        variant={'permanent'}
      >
        <Box className={classes.toolbarPaddingDummy} />
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Fichiers ouverts
            </ListSubheader>
          }
        >
          <AccountsList />
        </List>
        <Divider />
        {currentBankAccount != null ? (
          <>
            <ListSubheader component="div" id="nested-list-subheader">
              {tab === 0
                ? 'Lignes pré-remplies'
                : tab === 1
                ? 'Navigateur'
                : 'Recherche'}
            </ListSubheader>
            {tab === 0 ? (
              <PrefilledLinesNavigator />
            ) : tab === 1 ? (
              <CurrentAccountNavigator
                onTreeItemClicked={onWantToNavigateToLineUuid}
              />
            ) : (
              <SearchPanel
                inputValue={searchInputValue}
                setInputValue={setSearchInputValue}
                searchValue={searchValue}
                onInputSubmit={() => {
                  setSearchValue(searchInputValue);
                }}
                onClearSearch={() => {
                  setSearchValue(null);
                  setSearchInputValue('');
                }}
                lines={currentBankAccount.operations}
                onLineMatchClicked={onWantToNavigateToLineUuid}
              />
            )}

            <Tabs
              className={classes.tabs}
              value={tab}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
              onChange={(event, newIndex) => setTab(newIndex)}
            >
              <Tab label="Pré-remplies" icon={<PlaylistAdd />} />
              <Tab label="Navigateur" icon={<Navigation />} />
              <Tab label="Recherche" icon={<Search />} />
            </Tabs>
          </>
        ) : null}
      </Drawer>
    </>
  );
};
