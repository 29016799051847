import React, {
  FocusEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { OpenInBrowser } from '@material-ui/icons';
import { GoogleApiTools } from '../../tools/GoogleApiTools';
import { GooglePickerApiTools } from '../../tools/GooglePickerApiTools';

export interface GoogleDriveFolderFieldProps {
  label?: string;

  value?: string;
  onChange: (e: string) => void;
  onBlur?: FocusEventHandler;

  error?: boolean;
  helperText?: string;
}

export const GoogleDriveFolderField = (props: GoogleDriveFolderFieldProps) => {
  const { error, label, helperText, onBlur, onChange, value } = props;
  const [folderName, setFolderName] = useState<{
    id: string;
    name: string | null;
  }>({ id: value || '-1', name: null });

  const fetchFolderName = useCallback((folderFileId: string) => {
    (async () => {
      setFolderName({ id: folderFileId, name: null });
      const folderName = await GoogleApiTools.getFilename(folderFileId);
      setFolderName((prevState) => {
        // Only apply if id is still the same as set before getFilename promise call.
        // If it changed, it means the value changed (see the useEffect).
        if (prevState.id === folderFileId) {
          return {
            id: folderFileId,
            name: folderName,
          };
        } else {
          return prevState;
        }
      });
    })();
  }, []);

  useEffect(() => {
    // Only update if the folder fileId changed.
    // The previous value's corresponding folder name, if still being processed by GoogleApiTools.getFilename, will
    // not be set to avoid weird side-effects.
    if (value !== undefined && folderName.id !== value) {
      fetchFolderName(value);
    }
  }, [fetchFolderName, folderName.id, value]);

  return (
    <TextField
      fullWidth
      label={label}
      onBlur={onBlur}
      variant="filled"
      error={error}
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position={'end'}>
            <IconButton
              onClick={() => {
                (async () => {
                  const folderId =
                    await GooglePickerApiTools.openFolderPicker();
                  if (folderId) {
                    onChange(folderId);
                  }
                })();
              }}
              edge={'end'}
            >
              <OpenInBrowser />
            </IconButton>
          </InputAdornment>
        ),
      }}
      helperText={helperText}
      margin={'normal'}
      value={folderName.id !== '-1' ? folderName.name || 'Chargement...' : ''}
    />
  );
};
