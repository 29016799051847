import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { Close, ErrorOutline, FormatAlignJustify } from '@material-ui/icons';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';

const useStyle = makeStyles((theme) => ({
  itemContainer: {
    borderRight: '6px solid transparent',
  },
  itemContainerSelected: {
    borderRight: '6px solid ' + theme.palette.secondary.main,
  },
  accountText: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    whiteSpace: 'nowrap',
  },
}));

export enum AccountFileListItemRightButtonType {
  LOADING,
  CLOSE,
  SAVE_ERROR,
}

export interface AccountFileListItemProps {
  accountFileId: string;
  accountName: string;
  selected: boolean;
  index: number;
  onClick: () => void;

  closeButtonType: AccountFileListItemRightButtonType;
  onCloseButtonClicked: () => void;
}

export const AccountFileListItem = (props: AccountFileListItemProps) => {
  const {
    accountFileId,
    accountName,
    closeButtonType,
    index,
    onClick,
    onCloseButtonClicked,
    selected,
  } = props;

  const classes = useStyle();
  const theme = useTheme();

  const [isHovered, setHovered] = useState(false);

  const showLoading =
    closeButtonType === AccountFileListItemRightButtonType.LOADING;
  const showCloseButton =
    selected &&
    (closeButtonType === AccountFileListItemRightButtonType.CLOSE ||
      (isHovered &&
        closeButtonType === AccountFileListItemRightButtonType.SAVE_ERROR));
  const showSaveError =
    closeButtonType === AccountFileListItemRightButtonType.SAVE_ERROR &&
    (!selected || !isHovered);

  const rightButtonDisplayed =
    showLoading ||
    closeButtonType === AccountFileListItemRightButtonType.SAVE_ERROR ||
    (selected && closeButtonType === AccountFileListItemRightButtonType.CLOSE);

  return (
    <Draggable
      draggableId={'accountFileListItem$' + accountFileId}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          style={{
            ...provided.draggableProps.style,
            backgroundColor: snapshot.isDragging
              ? theme.palette.background.paper
              : undefined,
          }}
        >
          <ListItem
            className={clsx(
              classes.itemContainer,
              selected ? classes.itemContainerSelected : undefined
            )}
            button
            selected={selected}
            onClick={onClick}
          >
            <ListItemIcon {...provided.dragHandleProps}>
              <FormatAlignJustify color={'inherit'} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ className: classes.accountText }}
            >
              {accountName}
            </ListItemText>
            {rightButtonDisplayed ? (
              <ListItemSecondaryAction>
                {showLoading ? <CircularProgress size={22} /> : null}
                {showCloseButton ? (
                  <IconButton
                    aria-label={'close'}
                    edge={'end'}
                    onClick={onCloseButtonClicked}
                  >
                    <Close />
                  </IconButton>
                ) : null}
                {showSaveError ? (
                  <IconButton aria-label={'error'} edge={'end'}>
                    <ErrorOutline color={'error'} />
                  </IconButton>
                ) : null}
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        </div>
      )}
    </Draggable>
  );
};
