import { BankAccountLine } from '../../../types/bank-account/BankAccountLine';
import React, { useMemo } from 'react';
import { BankAccountOperation } from '../../../types/bank-account/BankAccountOperation';
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts';
import { DateTime } from 'luxon';
import Dinero from 'dinero.js';
import { Box, useTheme } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { BankAccountLineTools } from '../../../tools/BankAccountLineTools';

export interface AccountTotalGraphProps {
  lines: BankAccountLine[];
}

const useStyle = makeStyles((theme) => ({
  warningContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
}));

const parseDateAsUTC = (dateStr: string): DateTime => {
  return DateTime.fromFormat(dateStr, 'd/L/yy', { zone: 'UTC' });
};

const MILLIS_PER_DAY = 1000 * 3600 * 24;

const START_DAYS_BEFORE = 31;

export const AccountTotalGraph = (props: AccountTotalGraphProps) => {
  const { lines } = props;

  const classes = useStyle();
  const theme = useTheme();

  // Only get the lines
  const dataIntermediate = useMemo(
    () =>
      lines
        .filter(
          (line): line is BankAccountOperation => line._type === 'operation'
        )
        .map((line) => ({
          date: parseDateAsUTC(line.date),
          total: Dinero(line.total),
          amountChecked: Dinero(
            BankAccountLineTools.getLineCheckedAmount(line)
          ),
        }))
        .filter((line) => line.date.isValid)
        .reduce((groupedByDate, line) => {
          if (groupedByDate != null) {
            const previousLine = groupedByDate[groupedByDate.length - 1];
            if (previousLine == null) {
              return [
                ...groupedByDate,
                { ...line, totalChecked: line.amountChecked },
              ];
            } else if (+line.date > +previousLine.date) {
              return [
                ...groupedByDate,
                {
                  ...line,
                  totalChecked: previousLine.totalChecked.add(
                    line.amountChecked
                  ),
                },
              ];
            } else if (+previousLine.date === +line.date) {
              return [
                ...groupedByDate.slice(0, groupedByDate.length - 1),
                {
                  ...line,
                  totalChecked: previousLine.totalChecked.add(
                    line.amountChecked
                  ),
                },
              ];
            } else {
              console.warn(
                'AccountTotalGraph: not ordered date at ' + line.date.toISO()
              );
              return null;
            }
          } else {
            return null;
          }
        }, [] as { date: DateTime; total: Dinero.Dinero; totalChecked: Dinero.Dinero }[] | null),
    [lines]
  );
  const data = useMemo(() => {
    if (dataIntermediate) {
      const startOfXAxisDateTime = dataIntermediate[
        dataIntermediate.length - 1
      ].date.minus({ days: START_DAYS_BEFORE });
      return dataIntermediate
        .map((line) => ({
          timestamp: +line.date / MILLIS_PER_DAY,
          total: line.total.toRoundedUnit(2),
          totalChecked: line.totalChecked.toRoundedUnit(2),
        }))
        .filter(
          (line) =>
            DateTime.fromMillis(line.timestamp * MILLIS_PER_DAY) >=
            startOfXAxisDateTime
        );
    } else {
      return null;
    }
  }, [dataIntermediate]);

  const xAxisDomain: [any, any] = useMemo(() => {
    if (data && data.length > 0) {
      const lastPoint = DateTime.fromMillis(
        data[data.length - 1].timestamp * MILLIS_PER_DAY,
        {
          zone: 'UTC',
        }
      );
      return [
        +lastPoint.minus({ days: START_DAYS_BEFORE }) / MILLIS_PER_DAY,
        +lastPoint / MILLIS_PER_DAY,
      ];
    } else {
      return ['dataMin', 'dataMax'];
    }
  }, [data]);

  const yAxisDomain: [number, number] | null = useMemo(() => {
    if (data && data.length > 0) {
      return data.reduce(
        (minMax, line) => [
          Math.min(
            minMax[0],
            line.total,
            line.totalChecked,
            Math.floor(line.total * 1.1),
            Math.floor(line.totalChecked * 1.1)
          ),
          Math.max(
            minMax[1],
            line.total,
            line.totalChecked,
            Math.ceil(line.total * 1.1),
            Math.ceil(line.totalChecked * 1.1)
          ),
        ],
        [-250, 250]
      );
    } else {
      return null;
    }
  }, [data]);

  const yAxisTicks = useMemo(() => {
    if (yAxisDomain == null) {
      return null;
    }
    const ticks = [0];
    for (let i = 0; i <= yAxisDomain[1]; i += 500) {
      if (i !== 0) {
        ticks.push(i);
      }
    }
    for (let i = 0; i >= yAxisDomain[0]; i -= 500) {
      if (i !== 0) {
        ticks.push(i);
      }
    }
    return ticks;
  }, [yAxisDomain]);

  if (
    data != null &&
    data.length > 0 &&
    xAxisDomain != null &&
    yAxisDomain != null &&
    yAxisTicks != null
  ) {
    return (
      <AreaChart width={600} height={300} data={data}>
        <XAxis
          type={'number'}
          dataKey="timestamp"
          domain={xAxisDomain}
          allowDataOverflow={true}
          allowDecimals={false}
          tickFormatter={(d) =>
            DateTime.fromMillis(d * MILLIS_PER_DAY)
              .toUTC()
              .toFormat('dd/LL/yyyy')
          }
        />
        <YAxis
          width={80}
          domain={yAxisDomain}
          ticks={yAxisTicks}
          tickCount={yAxisTicks.length}
          tickFormatter={(v) =>
            Dinero({
              amount: Math.round(v * 100),
              currency: 'EUR',
              precision: 2,
            }).toFormat()
          }
        />
        <CartesianGrid stroke="#f5f5f5" />
        <Area
          type="stepAfter"
          dot={false}
          dataKey="totalChecked"
          stroke={theme.palette.success.main}
          fill={theme.palette.success.main}
          strokeWidth={2}
          fillOpacity={0.1}
          yAxisId={0}
        />
        <Area
          type="stepAfter"
          dot={false}
          dataKey="total"
          stroke={theme.palette.warning.main}
          fill={theme.palette.warning.main}
          strokeWidth={2}
          fillOpacity={0.1}
          yAxisId={0}
        />
      </AreaChart>
    );
  } else {
    return (
      <Box className={classes.warningContainer}>
        <Warning className={classes.warningIcon} /> Impossible d&apos;afficher
        le graphique, certaines dates ne sont pas ordonnées !
      </Box>
    );
  }
};
