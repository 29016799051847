import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { PrefilledLineEditorForm } from './PrefilledLineEditorForm';
import { makeStyles } from '@material-ui/core/styles';

export interface PrefilledLineEditorModalProps {
  open: boolean;
  onClose?: () => void;

  form: PrefilledLineEditorForm.FormState;
}

const useStyle = makeStyles((theme) => ({
  formContainer: {
    margin: theme.spacing(2, 0),
  },
}));

export const PrefilledLineEditorModal = (
  props: PrefilledLineEditorModalProps
) => {
  const { form, open, onClose } = props;
  const classes = useStyle();
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'md'}>
      <DialogTitle>Editeur de ligne pré-remplie</DialogTitle>
      <DialogContent className={classes.formContainer}>
        <DialogContentText>
          Définissez les valeurs qui seront pré-remplies.
        </DialogContentText>
        <PrefilledLineEditorForm.Component form={form} />
      </DialogContent>
      <DialogActions>
        <Button color={'primary'} onClick={onClose}>
          Annuler
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          onClick={() => form.handleSubmit()}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
