import { UnknownSerializedAccount } from '../UnknownSerializedAccount';
import { SerializedLineV1 } from './SerializedLineV1';

export interface SerializedAccountV1 extends UnknownSerializedAccount {
  version: 1;
  operations: SerializedLineV1[];
  chequeBook?: {
    chequeBookId: string;
    currentChequeNumber: number;
    maxChequeNumber: number;
  };
}

export const isSerializedAccountV1 = (
  account: UnknownSerializedAccount
): account is SerializedAccountV1 => account.version === 1;
