import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authLogoutAction } from '../auth/AuthExtraActions';
import { PrefilledLineData } from '../../types/prefilled-line/PrefilledLineData';
import { PrefilledLineConfigFile } from '../../types/prefilled-line/PrefilledLineConfigFile';
import { PrefilledLineImportFile } from '../../types/prefilled-line/PrefilledLineImportFile';
import { uuid } from 'uuidv4';
import Dinero from 'dinero.js';
import { PrefilledLineEditorForm } from '../../components/prefilled-lines/editor-form/PrefilledLineEditorForm';

export interface PrefilledLinesState {
  loading: boolean;
  loaded: boolean;
  error: string | null;

  saveError: string | null;

  configFileId: string | null;

  linesList: string[];
  linesByUuid: { [uuid: string]: PrefilledLineData };

  lastChangeEpoch: number | null;
  lastSaveEpoch: number | null;
  savingChangeEpoch: number | null;
}

const initialState: PrefilledLinesState = {
  loading: false,
  loaded: false,
  error: null,
  saveError: null,
  configFileId: null,
  linesList: [],
  linesByUuid: {},
  lastChangeEpoch: null,
  lastSaveEpoch: null,
  savingChangeEpoch: null,
};

export const PrefilledLinesSlice = createSlice({
  name: 'PrefilledLinesSlice',
  initialState,
  reducers: {
    loadLines: (state) => {
      state.loading = true;
      state.loaded = false;
      state.error = null;
      state.lastChangeEpoch = null;
      state.lastSaveEpoch = null;
    },
    loadLinesSuccessful: (
      state,
      action: PayloadAction<{
        fileId: string | null;
        prefilledLinesConfig: PrefilledLineConfigFile | null;
      }>
    ) => {
      state.loading = false;
      state.loaded = true;
      state.error = null;
      state.configFileId = action.payload.fileId;
      state.lastChangeEpoch = null;
      state.lastSaveEpoch = null;
      if (action.payload.prefilledLinesConfig) {
        state.linesList = action.payload.prefilledLinesConfig.lines.map(
          (l) => l.uuid
        );
        state.linesByUuid = action.payload.prefilledLinesConfig.lines.reduce(
          (obj, line) => ({ ...obj, [line.uuid]: line }),
          {}
        );
      } else {
        state.linesByUuid = {};
        state.linesList = [];
      }
    },
    loadLinesFailure: (
      state,
      action: PayloadAction<{ error: string; fileId: string | null }>
    ) => {
      state.loading = false;
      state.loaded = false;
      state.error = action.payload.error;
      state.configFileId = action.payload.fileId;
      state.linesByUuid = {};
      state.linesList = [];
      state.lastChangeEpoch = null;
      state.lastSaveEpoch = null;
    },
    saveLines: (state) => {
      state.savingChangeEpoch = state.lastChangeEpoch; // We start to save the latest changes (so lastChangeEpoch).
      state.saveError = null;
    },
    saveLinesSuccessful: (state, action: PayloadAction<{ fileId: string }>) => {
      state.lastSaveEpoch = state.savingChangeEpoch; // Save is successful, the new save epoch is the change epoch when the save operation started.
      state.savingChangeEpoch = null;
      state.saveError = null;
      state.configFileId = action.payload.fileId;
    },
    saveLinesFailure: (state, action: PayloadAction<{ error: string }>) => {
      state.savingChangeEpoch = null;
      state.saveError = action.payload.error;
    },
    resetFile: (state, action: PayloadAction<{ epoch: number }>) => {
      // When resetting the prefilled lines, we erase the data but we keep the save fileId to overwrite it.
      state.loaded = true;
      state.error = null;
      state.linesList = [];
      state.linesByUuid = {};
      state.lastSaveEpoch = null;
      state.lastChangeEpoch = action.payload.epoch;
    },
    addLine: (
      state,
      action: PayloadAction<{
        formValues: PrefilledLineEditorForm.FormValues;
        index: number;
        epoch: number;
      }>
    ) => {
      const { index, epoch, formValues } = action.payload;

      state.lastChangeEpoch = epoch;
      const newUuid = uuid();
      state.linesList.splice(index, 0, newUuid);
      state.linesByUuid[newUuid] = {
        name: formValues.name,
        checked: formValues.checked,
        type: formValues.type,
        description: formValues.description,
        amount: Dinero({
          amount: Math.round(+formValues.amount * 100),
          currency: 'EUR',
        }).toObject(),
        color: formValues.color,
        category: formValues.category,
        uuid: newUuid,
      };
    },
    updateLine: (
      state,
      action: PayloadAction<{
        uuid: string;
        epoch: number;
        formValues: PrefilledLineEditorForm.FormValues;
      }>
    ) => {
      const { uuid, epoch, formValues } = action.payload;

      state.lastChangeEpoch = epoch;

      state.linesByUuid[uuid].name = formValues.name;
      state.linesByUuid[uuid].checked = formValues.checked;
      state.linesByUuid[uuid].type = formValues.type;
      state.linesByUuid[uuid].description = formValues.description;
      state.linesByUuid[uuid].amount = Dinero({
        amount: Math.round(+formValues.amount * 100),
        currency: 'EUR',
      }).toObject();
      state.linesByUuid[uuid].color = formValues.color;
      state.linesByUuid[uuid].category = formValues.category;
    },
    moveLine: (
      state,
      action: PayloadAction<{ from: number; to: number; epoch: number }>
    ) => {
      const [uuidToMove] = state.linesList.splice(action.payload.from, 1);
      state.linesList.splice(action.payload.to, 0, uuidToMove);
      state.lastChangeEpoch = action.payload.epoch;
    },
    removeLine: (
      state,
      action: PayloadAction<{ index: number; epoch: number }>
    ) => {
      state.lastChangeEpoch = action.payload.epoch;
      const lineUuid = state.linesList[action.payload.index];
      state.linesList.splice(action.payload.index, 1);
      delete state.linesByUuid[lineUuid];
    },
    importLinesConfig: (
      state,
      action: PayloadAction<{
        importContent: PrefilledLineImportFile;
        epoch: number;
      }>
    ) => {
      state.lastChangeEpoch = action.payload.epoch;
      action.payload.importContent.lines.forEach((line) => {
        const newUuid = uuid();
        state.linesByUuid[newUuid] = {
          uuid: newUuid,
          ...line,
        };
        state.linesList.push(newUuid);
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogoutAction, (state) => {
      // On logout, we clear the state.
      state.loading = false;
      state.error = null;
      state.configFileId = null;
      state.linesList = [];
      state.linesByUuid = {};
    });
  },
});
