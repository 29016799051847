import { PrefilledLineData } from '../../types/prefilled-line/PrefilledLineData';
import { useAppSelector } from '../hooks/ReduxHooks';

export const usePrefilledLines = () =>
  useAppSelector<PrefilledLineData[]>((state) =>
    state.prefilledLines.linesList
      .map((uuid) => state.prefilledLines.linesByUuid[uuid])
      .filter((line) => line != null)
  );

export const usePrefilledLinesLoading = () =>
  useAppSelector<boolean>((state) => state.prefilledLines.loading);
export const usePrefilledLinesSaving = () =>
  useAppSelector<boolean>(
    (state) => state.prefilledLines.savingChangeEpoch != null
  );

export enum PrefilledLinesErrorType {
  NO_ERRORS,
  UNABLE_TO_LOAD,
  INVALID_EXISTING_FILE,
}
export const usePrefilledLinesErrorType = () =>
  useAppSelector<PrefilledLinesErrorType>((state) =>
    state.prefilledLines.error != null
      ? state.prefilledLines.configFileId != null
        ? PrefilledLinesErrorType.INVALID_EXISTING_FILE
        : PrefilledLinesErrorType.UNABLE_TO_LOAD
      : PrefilledLinesErrorType.NO_ERRORS
  );
export const usePrefilledLinesErrorMessage = () =>
  useAppSelector<string | null>((state) => state.prefilledLines.error);
