import {
  AccountBalance,
  Commute,
  DriveEta,
  Healing,
  Home,
  Kitchen,
  MonetizationOn,
  MoneyOff,
  PhoneAndroid,
  ShoppingCart,
  SportsEsports,
  SupervisorAccount,
  SvgIconComponent,
  SwapHoriz,
} from '@material-ui/icons';

export interface Category {
  key: string;
  name: string;
  icon: SvgIconComponent;
  color: string;
}

export const CATEGORIES_PER_KEYS: { [key: string]: Category } = {
  income: {
    key: 'income',
    name: 'Revenus',
    icon: MonetizationOn,
    color: '#9ccc65',
  },
  dailyLife: {
    key: 'dailyLife',
    name: 'Vie quotidienne',
    icon: ShoppingCart,
    color: '#64b5f6',
  },
  subscriptions: {
    key: 'subscriptions',
    name: 'Internet & téléphonie',
    icon: PhoneAndroid,
    color: '#64b5f6',
  },
  car: {
    key: 'car',
    name: 'Auto & Moto',
    icon: DriveEta,
    color: '#ffeb3b',
  },
  furnitures: {
    key: 'furnitures',
    name: 'Ammeublement',
    icon: Kitchen,
    color: '#fb8c00',
  },
  educationFamily: {
    key: 'educationFamily',
    name: 'Education & Famille',
    icon: SupervisorAccount,
    color: '#f06292',
  },
  taxes: {
    key: 'taxes',
    name: 'Impôts & Taxes',
    icon: AccountBalance,
    color: '#e53935',
  },
  housing: {
    key: 'housing',
    name: 'Logement (EDF, eau, assurance)',
    icon: Home,
    color: '#ff7043',
  },
  hobbies: {
    key: 'hobbies',
    name: 'Loisirs & Restaurants',
    icon: SportsEsports,
    color: '#ab47bc',
  },
  internal: {
    key: 'internal',
    name: 'Mouvements internes (retraits, placements)',
    icon: SwapHoriz,
    color: '#90a4ae',
  },
  refunds: {
    key: 'refunds',
    name: 'Remboursements',
    icon: MoneyOff,
    color: '#f48fb1',
  },
  health: {
    key: 'health',
    name: 'Santé',
    icon: Healing,
    color: '#cddc39',
  },
  transportation: {
    key: 'transportation',
    name: 'Voyages & Transports',
    icon: Commute,
    color: '#ffca28',
  },
};

export const CATEGORIES_SORTED = Object.values(CATEGORIES_PER_KEYS).sort(
  (a, b) => a.name.localeCompare(b.name)
);
