import { useAuthenticatedUser } from '../redux/auth/AuthenticatedUserHook';
import { LoginPage } from './LoginPage';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ConnectedPage } from './connected/ConnectedPage';

export const RoutesRoot = () => {
  const user = useAuthenticatedUser();

  return (
    <Routes>
      <Route
        path={'/'}
        element={
          user ? (
            <Navigate to={'/connected'} replace />
          ) : (
            <Navigate to={'/login'} replace />
          )
        }
      />
      <Route
        path={'/login'}
        element={user ? <Navigate to={'/connected'} replace /> : <LoginPage />}
      />
      <Route
        path={'/connected'}
        element={!user ? <Navigate to={'/login'} replace /> : <ConnectedPage />}
      />
    </Routes>
  );
};
