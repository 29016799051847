import { ThunkResult } from '../Store';
import { GoogleApiTools } from '../../tools/GoogleApiTools';
import { AppInitSlice } from '../app-init/AppInitSlice';
import { AuthSlice } from '../auth/AuthSlice';
import { openFilesByIdsThunk } from './OpenFileThunk';
import { AccountsListPersistTools } from '../tools/AccountsListPersistTools';
import * as Sentry from '@sentry/browser';
import { loadPrefilledLinesThunk } from './prefilled-lines/LoadPrefilledLinesThunk';
import { AppThemeType, EditorSlice } from '../editor/EditorSlice';

/**
 * Init the app (gapi, ...) and apply the current authentication status.
 */
export const initAppThunk =
  (): ThunkResult<void> => async (dispatch, getState) => {
    try {
      // Set the theme to the saved settings in local storage.
      dispatch(
        EditorSlice.actions.setThemeType({
          themeType:
            (localStorage.getItem('theme_type') as any) ?? AppThemeType.LIGHT, // TODO: Improve setting security
        })
      );

      // Load Google APIs.
      await GoogleApiTools.init();
      dispatch(AppInitSlice.actions.gApiReady());

      // If the user was previously logged in, reload the current user in the store.
      if (GoogleApiTools.isLoggedIn()) {
        const user = GoogleApiTools.getLoggedInUser();
        Sentry.setUser({
          id: user.id,
          username: user.displayName,
        });
        dispatch(
          AuthSlice.actions.authReloadLoggedInUser({
            user,
          })
        );
        dispatch(
          openFilesByIdsThunk(
            AccountsListPersistTools.getPersistedAccountsList(user.id)
          )
        );
        // Load the prefilled lines if the user was already logged in.
        dispatch(loadPrefilledLinesThunk());
      }

      dispatch(AppInitSlice.actions.apiReady());
    } catch (e: any) {
      dispatch(
        AppInitSlice.actions.apiFailure({
          message: e && e.message ? e.message : JSON.stringify(e),
        })
      );
    }
  };
