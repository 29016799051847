import {
  BankAccount,
  BankAccountStatus,
} from '../types/bank-account/BankAccount';

export class BankAccountTools {
  /**
   * Returns true if a bank account has changed since the last save.
   * @param bankAccount
   */
  public static isSaveNeeded(bankAccount: BankAccount): boolean {
    return bankAccount.lastChangeEpoch > bankAccount.lastSavedChangeEpoch;
  }

  /**
   * Returns true if the a bank account can be saved (has changed since
   * last save and is in ready state).
   * @param bankAccount
   * @param evenIfClosing if true, canSave can return true even if the file is in a closing state.
   */
  public static canSave(
    bankAccount: BankAccount,
    evenIfClosing = false
  ): boolean {
    return (
      this.isSaveNeeded(bankAccount) &&
      (bankAccount.status === BankAccountStatus.READY ||
        (evenIfClosing && bankAccount.status === BankAccountStatus.CLOSING))
    );
  }
}
