import { useEffect } from 'react';

export const usePreventClose = (prevent: boolean) => {
  useEffect(() => {
    if (prevent) {
      window.onbeforeunload = (event: BeforeUnloadEvent) => {
        event.returnValue = 'something';
      };
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [prevent]);
};
