import { CONFIG } from '../config';
import { GoogleApiTools } from './GoogleApiTools';

export class GooglePickerApiTools {
  /**
   * Opens a Google Drive file picker and returns a promise that gives the picked
   * fileId, null if none / picker cancelled.
   * @param mimeTypes allowed mime types
   */
  public static openPicker(mimeTypes?: string): Promise<string | null> {
    return new Promise((resolve) => {
      const view = new google.picker.DocsView();
      view.setIncludeFolders(true);
      view.setOwnedByMe(true);
      view.setMode(google.picker.DocsViewMode.LIST);
      if (mimeTypes) {
        view.setMimeTypes(mimeTypes);
      }
      const picker = new google.picker.PickerBuilder()
        .setAppId(CONFIG.gapi.appId)
        .setLocale('fr_FR')
        .setOAuthToken(GoogleApiTools.getLoggedInUser().accessToken)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setDeveloperKey(CONFIG.gapi.apiKey)
        .setCallback((data: any) => {
          if (data.action === google.picker.Action.PICKED) {
            resolve(data.docs[0].id);
          } else if (data.action === google.picker.Action.CANCEL) {
            resolve(null);
          }
        })
        .build();
      picker.setVisible(true);
    });
  }

  public static openFolderPicker(): Promise<string | null> {
    return new Promise((resolve) => {
      const view = new google.picker.DocsView();
      view.setIncludeFolders(true);
      view.setOwnedByMe(true);
      view.setMode(google.picker.DocsViewMode.LIST);
      view.setMimeTypes('application/vnd.google-apps.folder');
      view.setSelectFolderEnabled(true);
      const picker = new google.picker.PickerBuilder()
        .setAppId(CONFIG.gapi.appId)
        .setLocale('fr_FR')
        .setOAuthToken(GoogleApiTools.getLoggedInUser().accessToken)
        .addView(view)
        .setDeveloperKey(CONFIG.gapi.apiKey)
        .setCallback((data: any) => {
          if (data.action === google.picker.Action.PICKED) {
            resolve(data.docs[0].id);
          } else if (data.action === google.picker.Action.CANCEL) {
            resolve(null);
          }
        })
        .build();
      picker.setVisible(true);
    });
  }
}
