import { useDispatch, useSelector } from 'react-redux';
import { AppBar, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import { ConnectedToolbar } from './ConnectedToolbar';
import { PublicToolbar } from './PublicToolbar';
import { logoutThunk } from '../../redux/thunks/LogoutThunk';
import { loginThunk } from '../../redux/thunks/LoginThunk';
import { useAuthenticatedUser } from '../../redux/auth/AuthenticatedUserHook';
import { RootState } from '../../redux/rootReducer';
import { EuroSymbol } from '@material-ui/icons';
import { openFileThunk } from '../../redux/thunks/OpenFileThunk';
import { useBankAccountNewModal } from '../modals/bank-account-new-modal/BankAccountNewModalHook';
import { createFileThunk } from '../../redux/thunks/CreateFileThunk';
import { useCachedNullableValue } from '../../hooks/CachedNullableValueHook';
import {
  useIsOpeningAccounts,
  useNewAccountError,
  useOpenAccountErrorsMessage,
} from '../../redux/bank-accounts/BankAccountHooks';
import { BankAccountNewModal } from '../modals/bank-account-new-modal/BankAccountNewModal';
import { SimpleModal } from '../modals/SimpleModal';
import { BankAccountsSlice } from '../../redux/bank-accounts/BankAccountsSlice';
import { useCurrentBankAccountId } from '../../redux/bank-accounts/CurrentAccountHook';
import { closeFileThunk } from '../../redux/thunks/CloseFileThunk';
import { AppThemeType, EditorSlice } from '../../redux/editor/EditorSlice';
import { useEditorThemeType } from '../../redux/editor/EditorHooks';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    margin: theme.spacing(0, 2),
  },
  version: {
    color: theme.palette.primary.dark,
    fontSize: 11,
    marginRight: theme.spacing(2),
  },
  leftRightSeparator: {
    flexGrow: 1,
  },
}));

export const MainToolbar = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const currentThemeType = useEditorThemeType();

  // User auth management.
  const user = useAuthenticatedUser();
  const authPending = useSelector<RootState, boolean>(
    (state) => state.auth.pending
  );

  const onLogin = useCallback(() => {
    dispatch(loginThunk());
  }, [dispatch]);
  const onLogout = useCallback(() => {
    dispatch(logoutThunk());
  }, [dispatch]);

  // File management.
  const openAccount = useCallback(() => dispatch(openFileThunk()), [dispatch]);
  const openAccountNoMimeTypes = useCallback(
    () => dispatch(openFileThunk(true)),
    [dispatch]
  );

  const bankAccountNewModal = useBankAccountNewModal((creationData) => {
    dispatch(createFileThunk(creationData));
  });

  const [newAccountError, newAccountErrorCached] = useCachedNullableValue(
    useNewAccountError()
  );
  const openAccountErrors = useOpenAccountErrorsMessage();
  const isOpeningAccounts = useIsOpeningAccounts();
  const [openAccountError, openAccountErrorCached] = useCachedNullableValue(
    isOpeningAccounts ? null : openAccountErrors
  );

  const currentAccountId = useCurrentBankAccountId();
  const closeCurrentAccount = useCallback(() => {
    if (currentAccountId != null) {
      dispatch(closeFileThunk(currentAccountId));
    }
  }, [currentAccountId, dispatch]);

  return (
    <>
      <BankAccountNewModal {...bankAccountNewModal} />
      <SimpleModal
        title={'Erreur'}
        buttons={['Ok']}
        onButtonClicked={() => {
          dispatch(BankAccountsSlice.actions.baNewFailureDismiss());
        }}
        open={newAccountError != null}
      >
        {'Impossible de créer un compte : ' + (newAccountErrorCached || '')}
      </SimpleModal>
      <SimpleModal
        title={'Erreur'}
        buttons={['Ok']}
        onButtonClicked={() => {
          dispatch(BankAccountsSlice.actions.baOpenFailureDismiss());
        }}
        open={openAccountError != null}
      >
        {"Des erreurs se sont produites lors de l'ouverture des fichiers." +
          (openAccountErrorCached || '')}
      </SimpleModal>
      <AppBar className={classes.appBar} position={'static'}>
        <Toolbar>
          <EuroSymbol color={'inherit'} />
          <Typography className={classes.title} variant={'h6'}>
            Account Manager
          </Typography>
          <Typography className={classes.version}>
            {process.env.REACT_APP_VERSION}
          </Typography>
          {user ? (
            <ConnectedToolbar
              user={user}
              onLogoutClicked={onLogout}
              onNewClicked={() => {
                bankAccountNewModal.open();
              }}
              onOpenClicked={openAccount}
              onOpenImportedFileClicked={openAccountNoMimeTypes}
              onCloseClicked={closeCurrentAccount}
              onSwitchThemeTypeClicked={() => {
                const newThemeType =
                  currentThemeType === AppThemeType.DARK
                    ? AppThemeType.LIGHT
                    : AppThemeType.DARK;
                dispatch(
                  EditorSlice.actions.setThemeType({
                    themeType: newThemeType,
                  })
                );
                localStorage.setItem('theme_type', newThemeType);
              }}
              onBugReportClicked={() => {
                Sentry.captureMessage(
                  'Manual bug report',
                  Sentry.Severity.Info
                );
                Sentry.showReportDialog({
                  title: 'Quelque chose ne se passe pas comme prévu ?',
                  subtitle:
                    'Remplissez ce formulaire pour nous expliquer pourquoi.',
                  labelComments: "Qu'est-ce qui ne fonctionne pas ?",
                });
              }}
            />
          ) : (
            <PublicToolbar
              logInDisabled={authPending}
              onLogInClicked={onLogin}
            />
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};
