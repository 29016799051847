import { ThunkResult } from '../../Store';
import { PrefilledLinesTools } from '../../../tools/PrefilledLinesTools';
import { PrefilledLinesSlice } from '../../prefilled-lines/PrefilledLinesSlice';

/**
 * Save the prefilled lines to the app data config file.
 */
export const savePrefilledLinesIfNeededThunk =
  (): ThunkResult<void> => async (dispatch, getState) => {
    const state = getState();

    // Do not save if the prefilled lines config file has not been loaded or the last save epoch is the same as
    // the last change epoch.
    if (
      !state.prefilledLines.loaded ||
      state.prefilledLines.lastChangeEpoch == null ||
      (state.prefilledLines.lastSaveEpoch != null &&
        state.prefilledLines.lastSaveEpoch >=
          state.prefilledLines.lastChangeEpoch)
    ) {
      return;
    }

    dispatch(PrefilledLinesSlice.actions.saveLines());
    try {
      const configFileId = await PrefilledLinesTools.save(
        {
          lines: state.prefilledLines.linesList
            .map((uuid) => state.prefilledLines.linesByUuid[uuid])
            .filter((line) => line != null),
        },
        state.prefilledLines.configFileId
      );
      dispatch(
        PrefilledLinesSlice.actions.saveLinesSuccessful({
          fileId: configFileId,
        })
      );
    } catch (e: any) {
      dispatch(
        PrefilledLinesSlice.actions.saveLinesFailure({ error: e.message })
      );
    }
  };
