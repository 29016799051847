import {
  FilteredOperationActionPayload,
  OperationActionPayload,
  OperationActionType,
} from './OperationActionPayload';
import { BankAccount } from '../../../types/bank-account/BankAccount';
import { Draft } from '@reduxjs/toolkit';
import { BankAccountsState } from '../BankAccountsSlice';
import Dinero from 'dinero.js';
import { BankAccountLineTools } from '../../../tools/BankAccountLineTools';
import { BankAccountOperationColumn } from '../../../types/bank-account/BankAccountOperationColumn';
import { addLineInBankAccount, manageChequeBook } from './OperationActionTools';
import { BankAccountLineData } from '../../../types/data/BankAccountLineData';

/**
 * Using the data from the payload
 */
type OperationActionReducer<PayloadType> = (
  payload: PayloadType
) => (
  account: Draft<BankAccount>,
  state: Draft<BankAccountsState>
) => OperationActionPayload | OperationActionPayload[] | null;

export const OPERATION_ACTION_REDUCERS: {
  [Type in OperationActionType]: OperationActionReducer<
    FilteredOperationActionPayload<Type>
  >;
} = {
  [OperationActionType.ADD_OPERATION]:
    ({ index, data, lineType }) =>
    (account) =>
      addLineInBankAccount(account, index, lineType, data),
  [OperationActionType.REMOVE_OPERATION]:
    ({ index }) =>
    (account) => {
      const [deletedOperation] = account.operations.splice(index, 1);

      // Subtract the new operation amount if data is provided to the operations following the deleted operation.
      account.operations.forEach((o, oIndex) => {
        if (oIndex >= index) {
          o.total = Dinero(o.total)
            .subtract(
              Dinero(BankAccountLineTools.getLineAmount(deletedOperation))
            )
            .toObject();
        }
      });

      return {
        type: OperationActionType.ADD_OPERATION,
        index,
        lineType: deletedOperation._type,
        data: {
          ...deletedOperation,
        },
      };
    },
  [OperationActionType.MOVE_OPERATION]:
    ({ from, to }) =>
    (account) => {
      const [itemToMove] = account.operations.splice(from, 1);
      account.operations.splice(to, 0, itemToMove);

      // Recompute only the needed totals.
      if (from < to) {
        // The item was moved down in the list.
        // So all operations between the old and the new have a total subtracted by the moved operation amount.
        for (let i = from; i < to; i++) {
          account.operations[i].total = Dinero(account.operations[i].total)
            .subtract(Dinero(BankAccountLineTools.getLineAmount(itemToMove)))
            .toObject();
        }

        // The moved item has a total being equal to the operation's total at index "to" - 1 added to the amount of
        // the moved item.
        // Note: "to" can't be zero so no out of bound issue.
        itemToMove.total = Dinero(account.operations[to - 1].total)
          .add(Dinero(BankAccountLineTools.getLineAmount(itemToMove)))
          .toObject();
      } else if (from > to) {
        // The item was moved up in the list.
        // So all operations between the old and the new have a total added by the moved operation amount.
        for (let i = to; i <= from; i++) {
          account.operations[i].total = Dinero(account.operations[i].total)
            .add(Dinero(BankAccountLineTools.getLineAmount(itemToMove)))
            .toObject();
        }
        // The moved item has a total being equal to the operation's total at index "from" + 1 subtracted to the
        // amount of the item at "from" + 1.
        // Note: "from" can't be zero so no out of bound issue.
        itemToMove.total = Dinero(account.operations[to + 1].total)
          .subtract(
            Dinero(
              BankAccountLineTools.getLineAmount(account.operations[to + 1])
            )
          )
          .toObject();
      }

      return {
        type: OperationActionType.MOVE_OPERATION,
        from: to,
        to: from,
      };
    },
  [OperationActionType.SET_VALUE]:
    ({ index, column, value }) =>
    (account, state) => {
      return manageChequeBook(
        account,
        state,
        (hasChequeBook, getNewChequeNumber) => {
          const previousValue = BankAccountLineTools.getStringValueFromColumn(
            account.operations[index],
            column
          );
          try {
            const previousAmount = Dinero(
              BankAccountLineTools.getLineAmount(account.operations[index])
            );

            let newValue = value;
            if (
              hasChequeBook &&
              column === BankAccountOperationColumn.TYPE &&
              value.trim() === 'C#'
            ) {
              newValue = getNewChequeNumber();
            }

            BankAccountLineTools.setColumnFromStringValue(
              account.operations[index],
              column,
              newValue
            );

            // Updates the total for the current operation and the next operations.
            const newAmount = Dinero(
              BankAccountLineTools.getLineAmount(account.operations[index])
            );
            if (!newAmount.equalsTo(previousAmount)) {
              const delta = newAmount.subtract(previousAmount);
              for (let i = index; i < account.operations.length; i++) {
                account.operations[i].total = Dinero(
                  account.operations[i].total
                )
                  .add(delta)
                  .toObject();
              }
            }

            if (previousValue != null) {
              return {
                type: OperationActionType.SET_VALUE,
                index,
                column,
                value: previousValue,
              };
            } else {
              console.warn('Unable to make an undo action!');
              return null;
            }
          } catch (e: any) {
            console.error('Unable to set the value because: ' + e.message);
            return null;
          }
        }
      );
    },
  [OperationActionType.CHANGE_COLOR]:
    ({ index, color }) =>
    (account) => {
      const line = account.operations[index];
      if (line._type === 'operation') {
        const previousColor = line.color;
        line.color = color;
        return {
          type: OperationActionType.CHANGE_COLOR,
          index,
          color: previousColor,
        };
      } else {
        throw Error('Cannot change color of a line of type: ' + line._type);
      }
    },
  [OperationActionType.RESET_CHEQUE_BOOK]:
    ({ chequeBookId, currentChequeNumber, maxChequeNumber }) =>
    (account) => {
      const previousChequeBook = account.chequeBook;
      account.chequeBook = {
        chequeBookId,
        currentChequeNumber,
        maxChequeNumber,
      };
      if (previousChequeBook) {
        // The undo action is to reset back to the previous params.
        return {
          type: OperationActionType.RESET_CHEQUE_BOOK,
          chequeBookId: previousChequeBook.chequeBookId,
          currentChequeNumber: previousChequeBook.currentChequeNumber,
          maxChequeNumber: previousChequeBook.maxChequeNumber,
        };
      } else {
        // If the previous cheque book was empty, the undo action is to delete the cheque book.
        return {
          type: OperationActionType.DELETE_CHEQUE_BOOK,
        };
      }
    },
  [OperationActionType.DELETE_CHEQUE_BOOK]: () => (account) => {
    const previousChequeBook = account.chequeBook;
    account.chequeBook = undefined;
    if (previousChequeBook) {
      return {
        type: OperationActionType.RESET_CHEQUE_BOOK,
        chequeBookId: previousChequeBook.chequeBookId,
        currentChequeNumber: previousChequeBook.currentChequeNumber,
        maxChequeNumber: previousChequeBook.maxChequeNumber,
      };
    } else {
      return [];
    }
  },
  [OperationActionType.INCREMENT_CHEQUE_BOOK]:
    ({ toAdd }) =>
    (account) => {
      if (account.chequeBook) {
        const previousChequeNumber = account.chequeBook.currentChequeNumber;
        account.chequeBook.currentChequeNumber = Math.max(
          0,
          account.chequeBook.currentChequeNumber + toAdd
        );

        return {
          type: OperationActionType.INCREMENT_CHEQUE_BOOK,
          toAdd: previousChequeNumber - account.chequeBook.currentChequeNumber,
        };
      } else {
        return [];
      }
    },
  [OperationActionType.ADD_FROM_PREFILLED_LINE]:
    ({ index, prefilledLine }) =>
    (account, state) => {
      return manageChequeBook(
        account,
        state,
        (hasChequeBook, getNewChequeNumber) => {
          const type =
            hasChequeBook && prefilledLine.type === 'C#'
              ? getNewChequeNumber()
              : prefilledLine.type;
          const lineData: BankAccountLineData = {
            _type: 'operation',
            checked: prefilledLine.checked,
            date: '',
            type,
            description: prefilledLine.description,
            amount:
              prefilledLine.amount ??
              Dinero({ currency: 'EUR', amount: 0 }).toObject(),
            color: prefilledLine.color,
            category: prefilledLine.category,
          };
          return addLineInBankAccount(account, index, lineData._type, lineData);
        }
      );
    },
  [OperationActionType.SET_CATEGORY]:
    ({ index, category }) =>
    (account) => {
      const line = account.operations[index];
      if (line._type !== 'operation') {
        throw Error('Cannot SET_CATEGORY on a ' + line._type + ' line!');
      }
      const previousCategory = line.category;
      line.category = category;
      return {
        type: OperationActionType.SET_CATEGORY,
        index,
        category: previousCategory,
      };
    },
};
