import { CellInput } from './CellInput';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';

const useStyle = makeStyles((theme) => ({
  autocomplete: {
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
  },
  textInput: {
    flex: 1,
    padding: theme.spacing(0, 2),
  },
}));

const typeOptions = [
  'C#',
  'Virement',
  'Prélèvement',
  'Chèque encaissé',
  'Carte bleue',
  'Retrait',
];

export const TypeCellInput: CellInput = forwardRef((props, ref) => {
  const { value, setValue, submit, cancel } = props;
  const classes = useStyle();

  const [autocompleteOption, setAutocompleteOption] = useState<string | null>(
    null
  );

  const inputRef = useRef<any>(null);
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
  }));

  return (
    <Autocomplete
      className={classes.autocomplete}
      multiple={false}
      value={autocompleteOption}
      onChange={(event: any, newValue: string | null) => {
        if (newValue == null) {
          return;
        }
        setAutocompleteOption(newValue);
      }}
      noOptionsText={'Pas de proposition'}
      inputValue={value ?? ''}
      onInputChange={(event, newInputValue) => {
        if (event == null || event.nativeEvent.type === 'blur') {
          return;
        }
        setValue(newInputValue);
      }}
      options={typeOptions}
      renderInput={(params) => (
        <InputBase
          ref={params.InputProps.ref}
          inputProps={params.inputProps}
          autoFocus={true}
          inputRef={inputRef}
          className={classes.textInput}
          onFocus={(event) => {
            event.target.setSelectionRange(0, Number.MAX_SAFE_INTEGER);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // "Return" pressed.
              submit();
            } else if (event.key === 'Escape') {
              cancel();
            }
          }}
        />
      )}
    />
  );
});
TypeCellInput.displayName = 'TypeCellInput';
