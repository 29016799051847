export class StringTools {
  /**
   * Remove the diatrics from a string. To do so, it first normalizes the string to NFD.
   * So be careful with comparisons.
   * @param input
   */
  public static removeDiatrics(input: string): string {
    return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
