import { SerializedLineV1 } from './SerializedLineV1';

export interface SerializedOperationV1 {
  _type?: 'operation';
  checked: boolean;
  date: string;
  type: string;
  description: string;
  amount: number;

  color?: string;
  category?: string;
}

export const isSerializedOperationV1 = (
  o: SerializedLineV1
): o is SerializedOperationV1 => {
  return o._type === 'operation' || o._type === undefined;
};
