import { ThunkResult } from '../Store';
import { saveAccountThunk } from './SaveAccountThunk';

export const saveAccountsThunk =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    return Promise.all(
      Object.keys(getState().bankAccounts.bankAccounts).map((fileId) =>
        dispatch(saveAccountThunk(fileId))
      )
    ).then();
  };
