import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SplashScreen } from './pages/SplashScreen';
import { initAppThunk } from './redux/thunks/InitAppThunk';
import { MainToolbar } from './components/toolbar/MainToolbar';
import { RoutesRoot } from './pages/RoutesRoot';
import { RootState } from './redux/rootReducer';
import { LoadingBlockerModal } from './components/containers/LoadingBlockerModal';
import Dinero from 'dinero.js';

const App: React.FC = () => {
  const ready = useSelector<RootState, boolean>((state) => state.appInit.ready);
  const failure = useSelector<RootState, string | null>(
    (state) => state.appInit.failure
  );

  const dispatch = useDispatch();

  useEffect(() => {
    Dinero.globalLocale = 'fr';
    dispatch(initAppThunk() as any);
  }, [dispatch]);

  if (ready) {
    return (
      <>
        <LoadingBlockerModal />
        <MainToolbar />
        <RoutesRoot />
      </>
    );
  } else {
    return <SplashScreen failure={failure} />;
  }
};

export default App;
