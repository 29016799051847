import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Chip, IconButton, TableCell, TableRow } from '@material-ui/core';
import React, {
  CSSProperties,
  memo,
  MouseEventHandler,
  useCallback,
  useMemo,
} from 'react';
import { BankAccountOperation } from '../../types/bank-account/BankAccountOperation';
import clsx from 'clsx';
import { CheckBox, CheckBoxOutlineBlank, Help } from '@material-ui/icons';
import { BankAccountOperationColumn } from '../../types/bank-account/BankAccountOperationColumn';
import { DraggableProvided } from 'react-beautiful-dnd';
import Dinero from 'dinero.js';
import Color from 'color';
import { GripIcon, SelectedGripIcon } from '../../assets/GripIcon';
import { AccountOperationCellWithTooltip } from './AccountOperationCellWithTooltip';
import { CATEGORIES_PER_KEYS } from '../../types/Categories';

export interface AccountOperationRowProps {
  item: BankAccountOperation;
  onColumnClick?: (column: BankAccountOperationColumn, rect: DOMRect) => void;
  onColumnDoubleClick?: (
    column: BankAccountOperationColumn,
    rect: DOMRect
  ) => void;
  selected?: boolean;
  isDragging?: boolean;

  style?: CSSProperties;

  draggableProvided?: DraggableProvided;

  onMouseEnter?: (el: HTMLElement) => void;
  onMouseLeave?: () => void;

  onContextMenu?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  onCategoryButtonClick?: () => void;
}

const useStyle = makeStyles<
  Theme,
  { color: string; isDragging: boolean },
  string
>((theme) => ({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    backgroundColor: theme.palette.background.paper,
    boxShadow: (props) => theme.shadows[props.isDragging ? 4 : 0],
  },
  tableRowSelected: {
    backgroundColor: () => {
      const color = Color(theme.palette.background.paper);
      if (color.isDark()) {
        return color.lighten(0.05).hex();
      } else {
        return color.darken(0.05).hex();
      }
    },
    transition: 'background-color 200ms ease-in-out',
  },
  tableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexBasis: 0,
    transition: 'background-color 200ms ease-in-out',
    '&:hover': {
      backgroundColor: () => {
        const color = Color(theme.palette.background.paper);
        if (color.isDark()) {
          return color.lighten(0.05).hex();
        } else {
          return color.darken(0.05).hex();
        }
      },
      cursor: 'pointer',
    },
  },
  gripCell: {
    padding: theme.spacing(0),
    flex: 0,
    flexBasis: 40,
    textAlign: 'center',
  },
  amountCellCredit: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
  },
  amountCellDebit: {
    color: theme.palette.error.main,
  },
  descriptionCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cellWithChip: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  coloredChip: {
    cursor: 'pointer',
    fontSize: '0.875rem',
    backgroundColor: (props) => props.color,
    color: (props) => theme.palette.getContrastText(props.color),
  },
}));

export const AccountOperationRow = memo((props: AccountOperationRowProps) => {
  const {
    item,
    style,
    onColumnClick,
    onColumnDoubleClick,
    selected,
    isDragging,
    draggableProvided,
    onContextMenu,
    onCategoryButtonClick,
  } = props;
  const classes = useStyle({
    color: item.color || '#ffffff',
    isDragging: isDragging || false,
  });
  const theme = useTheme();

  const columnClickHandler = useCallback(
    (column: BankAccountOperationColumn): MouseEventHandler<HTMLDivElement> =>
      (e) => {
        if (onColumnClick) {
          onColumnClick(column, e.currentTarget.getBoundingClientRect());
        }
      },
    [onColumnClick]
  );

  const columnDblClickHandler = useCallback(
    (column: BankAccountOperationColumn): MouseEventHandler<HTMLDivElement> =>
      (e) => {
        if (onColumnDoubleClick) {
          onColumnDoubleClick(column, e.currentTarget.getBoundingClientRect());
        }
      },
    [onColumnDoubleClick]
  );

  const itemAmount = useMemo(() => Dinero(item.amount), [item.amount]);
  const itemTotal = useMemo(() => Dinero(item.total), [item.total]);

  const isColored =
    item.color != null && item.color.toLowerCase() !== '#ffffff';
  const category =
    item.category != null ? CATEGORIES_PER_KEYS[item.category] : null;

  return (
    <TableRow
      ref={draggableProvided?.innerRef}
      component={'div'}
      className={clsx(
        classes.tableRow,
        selected ? classes.tableRowSelected : undefined
      )}
      {...draggableProvided?.draggableProps}
      style={style}
      onMouseEnter={(event: any) => {
        if (props.onMouseEnter) {
          props.onMouseEnter(event.currentTarget);
        }
      }}
      onMouseLeave={props.onMouseLeave}
      onContextMenu={onContextMenu}
    >
      <TableCell
        component={'div'}
        className={clsx(classes.tableCell, classes.gripCell)}
        {...draggableProvided?.dragHandleProps}
        onClick={columnClickHandler(BankAccountOperationColumn.DND)}
        onDoubleClick={columnDblClickHandler(BankAccountOperationColumn.DND)}
      >
        {selected ? <SelectedGripIcon /> : <GripIcon />}
      </TableCell>
      <TableCell
        component={'div'}
        className={classes.tableCell}
        style={{ flex: 0, flexBasis: 24, textAlign: 'center' }}
        onClick={columnClickHandler(BankAccountOperationColumn.CHECKED)}
        onDoubleClick={columnDblClickHandler(
          BankAccountOperationColumn.CHECKED
        )}
      >
        {item.checked ? <CheckBox /> : <CheckBoxOutlineBlank />}
      </TableCell>
      <TableCell
        component={'div'}
        className={classes.tableCell}
        style={{ flex: 1 }}
        onClick={columnClickHandler(BankAccountOperationColumn.DATE)}
        onDoubleClick={columnDblClickHandler(BankAccountOperationColumn.DATE)}
      >
        {item.date}
      </TableCell>
      <AccountOperationCellWithTooltip
        value={item.type}
        className={classes.tableCell}
        style={{ flex: 1 }}
        onClick={columnClickHandler(BankAccountOperationColumn.TYPE)}
        onDoubleClick={columnDblClickHandler(BankAccountOperationColumn.TYPE)}
      />
      <TableCell
        component={'div'}
        className={clsx(
          classes.tableCell,
          classes.descriptionCell,
          isColored ? classes.cellWithChip : null
        )}
        style={{ flex: 5 }}
        onClick={columnClickHandler(BankAccountOperationColumn.DESCRIPTION)}
        onDoubleClick={columnDblClickHandler(
          BankAccountOperationColumn.DESCRIPTION
        )}
      >
        {isColored ? (
          <Chip className={classes.coloredChip} label={item.description} />
        ) : (
          item.description
        )}
        <div style={{ flex: 1 }} />
        <IconButton
          disabled={onCategoryButtonClick == null}
          onClick={onCategoryButtonClick}
          size={'small'}
        >
          {category ? (
            <category.icon htmlColor={category.color} />
          ) : (
            <Help htmlColor={theme.palette.grey[500]} />
          )}
        </IconButton>
      </TableCell>
      <TableCell
        component={'div'}
        className={clsx(classes.amountCellCredit, classes.tableCell)}
        style={{ flex: 1 }}
        onClick={columnClickHandler(BankAccountOperationColumn.AMOUNT_CREDIT)}
        onDoubleClick={columnDblClickHandler(
          BankAccountOperationColumn.AMOUNT_CREDIT
        )}
      >
        {itemAmount.isPositive() && !itemAmount.isZero()
          ? itemAmount.toFormat()
          : ''}
      </TableCell>
      <TableCell
        component={'div'}
        className={clsx(classes.amountCellDebit, classes.tableCell)}
        style={{ flex: 1 }}
        onClick={columnClickHandler(BankAccountOperationColumn.AMOUNT_DEBIT)}
        onDoubleClick={columnDblClickHandler(
          BankAccountOperationColumn.AMOUNT_DEBIT
        )}
      >
        {itemAmount.isNegative() ? itemAmount.multiply(-1).toFormat() : ''}
      </TableCell>
      <TableCell
        component={'div'}
        className={classes.tableCell}
        style={{ flex: 1 }}
        onClick={columnClickHandler(BankAccountOperationColumn.TOTAL)}
        onDoubleClick={columnDblClickHandler(BankAccountOperationColumn.TOTAL)}
      >
        {item.total ? itemTotal.toFormat() : ''}
      </TableCell>
    </TableRow>
  );
});
AccountOperationRow.displayName = 'AccountOperationRow';
