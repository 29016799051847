import { GoogleApiTools } from './GoogleApiTools';
import Ajv from 'ajv';
import PrefilledLineConfigFileSchema from '../generated/json-schema/PrefilledLineConfigFile.json';
import { PrefilledLineConfigFile } from '../types/prefilled-line/PrefilledLineConfigFile';

export class PrefilledLinesTools {
  private static FILE_NAME = 'prefilled-lines.json';
  private static MIME_TYPE =
    'application/com.victorlevasseur.account-manager.prefilled-lines-config';

  public static async load(): Promise<{
    fileId: string | null;
    result: PrefilledLineConfigFile | null;
    error?: string;
  }> {
    const prefilledLinesFile = await GoogleApiTools.searchFileByName(
      'appDataFolder',
      this.FILE_NAME
    );
    if (prefilledLinesFile) {
      try {
        const file = await GoogleApiTools.openFile(prefilledLinesFile.id);
        const parsedContent = JSON.parse(file.content);

        const validator = new Ajv();
        const isValid = validator.validate(
          PrefilledLineConfigFileSchema,
          parsedContent
        );
        if (!isValid) {
          console.warn(
            'Unable to open prefilled lines config file! Not validating the schema!'
          );
          return {
            result: null,
            fileId: file.fileId,
            error: validator.errorsText(),
          };
        }

        return {
          fileId: file.fileId,
          result: parsedContent,
        };
      } catch (e: any) {
        return {
          fileId: prefilledLinesFile.id,
          result: null,
          error: e.message,
        };
      }
    } else {
      console.log(
        `No "${this.FILE_NAME}" file found in app data, create an empty list of prefilled lines.`
      );
      return {
        fileId: null,
        result: null,
      };
    }
  }

  public static async save(
    prefilledLinesConfig: PrefilledLineConfigFile,
    fileId: string | null
  ): Promise<string> {
    let realFileId = fileId;
    if (fileId == null) {
      realFileId = await GoogleApiTools.createFile(
        this.FILE_NAME,
        'appDataFolder',
        this.MIME_TYPE
      );
    }

    if (realFileId == null) {
      throw Error(
        'Impossible de créer le fichier pour enregistrer les lignes pré-remplies.'
      );
    }

    await GoogleApiTools.saveToFile(
      realFileId,
      JSON.stringify(prefilledLinesConfig),
      this.MIME_TYPE
    );
    return realFileId;
  }
}
