import { Button, Popover, Tooltip } from '@material-ui/core';
import React, { FocusEventHandler, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CompactPicker } from 'react-color';

export interface ColorPickerButtonProps {
  colorValue: string;
  onColorValueSubmit?: (color: string) => void;

  onBlur?: FocusEventHandler<HTMLButtonElement>;

  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  buttonText?: string;

  buttonTooltip?: string;

  disabled?: boolean;
}

const useStyle = makeStyles((theme) => ({
  colorPreviewRect: {
    height: 22,
    width: 22,
    border: 'solid 1px black',
    borderRadius: theme.shape.borderRadius,
  },
  pickerPaper: {
    overflow: 'visible',
  },
  buttonLabel: {
    marginLeft: theme.spacing(1),
  },
}));

export const ColorPickerButton = (props: ColorPickerButtonProps) => {
  const {
    variant,
    size,
    color,
    colorValue,
    onColorValueSubmit,
    disabled,
    buttonText,
    buttonTooltip,
    onBlur,
  } = props;

  const classes = useStyle();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const buttonJsx = (
    <Button
      ref={buttonRef}
      disabled={disabled}
      color={color}
      variant={variant}
      size={size}
      onBlur={onBlur}
      onClick={() => setOpen(true)}
    >
      <div
        className={classes.colorPreviewRect}
        style={{ backgroundColor: colorValue }}
      />
      {buttonText ? (
        <span className={classes.buttonLabel}>{buttonText}</span>
      ) : null}
    </Button>
  );
  return (
    <>
      {buttonTooltip != null && !disabled ? (
        <Tooltip title={buttonTooltip}>{buttonJsx}</Tooltip>
      ) : (
        buttonJsx
      )}
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{ className: classes.pickerPaper, elevation: 0 }}
      >
        <CompactPicker
          color={colorValue}
          onChangeComplete={(color) => {
            if (onColorValueSubmit) {
              onColorValueSubmit(color.hex);
            }
            setOpen(false);
          }}
        />
      </Popover>
    </>
  );
};
