import { ThunkResult } from '../Store';
import { GoogleApiTools } from '../../tools/GoogleApiTools';
import { GooglePickerApiTools } from '../../tools/GooglePickerApiTools';
import { BankAccountsSlice } from '../bank-accounts/BankAccountsSlice';
import { AccountSerializationTools } from '../../tools/AccountSerializationTools';
import { AccountsListPersistTools } from '../tools/AccountsListPersistTools';

export const openFileByIdThunk =
  (fileId: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    // Check if the account was already opened. If it's the case, just switch to it.
    // Otherwise, proceed with opening the account.
    const alreadyOpenedFileIds = getState().bankAccounts.bankAccountsList;

    if (alreadyOpenedFileIds.includes(fileId)) {
      // The file is already opened, just switch to the file.
      dispatch(BankAccountsSlice.actions.baSwitchCurrent({ fileId }));
    } else {
      // Proceed to open the file, parse its content and send it through a baOpenSuccessful action.
      dispatch(BankAccountsSlice.actions.baOpen({ fileId }));
      let knownFileName: string | undefined = undefined;
      try {
        const file = await GoogleApiTools.openFile(fileId);
        knownFileName = file.name;
        const data = AccountSerializationTools.deserialize(
          JSON.parse(file.content)
        );
        dispatch(
          BankAccountsSlice.actions.baOpenSuccessful({
            fileId: file.fileId,
            name: file.name,
            bankAccountData: data,
          })
        );
        AccountsListPersistTools.persistAccountsListFromRedux(getState());
      } catch (e: any) {
        console.error(e);
        dispatch(
          BankAccountsSlice.actions.baOpenFailure({
            error: e.message,
            name: knownFileName,
            fileId,
          })
        );
      }
    }
  };

export const openFilesByIdsThunk =
  (fileIds: string[]): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    return Promise.all(
      fileIds.map((fileId) => dispatch(openFileByIdThunk(fileId)))
    ).then();
  };

export const openFileThunk =
  (ignoreMimeType?: boolean): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const fileId = await GooglePickerApiTools.openPicker(
      ignoreMimeType
        ? undefined
        : 'application/com.victorlevasseur.account-manager.file'
    );
    if (fileId) {
      return dispatch(openFileByIdThunk(fileId));
    }
  };
