import { Divider, Menu, MenuItem } from '@material-ui/core';
import React from 'react';

export interface AccountTableContextMenuProps {
  openAt: { x: number; y: number } | null;
  onClose: () => void;
  onAddOperationClicked: () => void;
  onAddMonthClicked: () => void;
  onDeleteClicked: () => void;
}

export const AccountTableContextMenu = (
  props: AccountTableContextMenuProps
) => {
  const {
    openAt,
    onClose,
    onAddOperationClicked,
    onAddMonthClicked,
    onDeleteClicked,
  } = props;
  return (
    <Menu
      keepMounted
      open={openAt != null}
      onClose={onClose}
      onContextMenu={(event) => {
        onClose();
        event.preventDefault();
      }}
      anchorReference="anchorPosition"
      anchorPosition={
        openAt != null ? { top: openAt.y, left: openAt.x } : undefined
      }
    >
      <MenuItem onClick={onAddOperationClicked}>Ajouter une ligne</MenuItem>
      <MenuItem onClick={onAddMonthClicked}>
        Ajouter une séparation de mois
      </MenuItem>
      <Divider />
      <MenuItem onClick={onDeleteClicked}>Supprimer la ligne</MenuItem>
    </Menu>
  );
};
