import { AppInitSlice } from './app-init/AppInitSlice';
import { combineReducers } from 'redux';
import { AuthSlice } from './auth/AuthSlice';
import { BankAccountsSlice } from './bank-accounts/BankAccountsSlice';
import { EditorSlice } from './editor/EditorSlice';
import { PrefilledLinesSlice } from './prefilled-lines/PrefilledLinesSlice';

export const rootReducer = combineReducers({
  appInit: AppInitSlice.reducer,
  auth: AuthSlice.reducer,
  bankAccounts: BankAccountsSlice.reducer,
  editor: EditorSlice.reducer,
  prefilledLines: PrefilledLinesSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
