import { PrefilledLineData } from '../../types/prefilled-line/PrefilledLineData';
import React, { FC, useMemo } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { AutoSizer as _AutoSizer, AutoSizerProps } from 'react-virtualized';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { PrefilledLineItem } from './PrefilledLineItem';
import { PrefilledLineInsertionDropClone } from './PrefilledLineInsertionDropClone';

// FIXME: https://github.com/bvaughn/react-virtualized/issues/1739
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

export interface PrefilledLinesListProps {
  lines: PrefilledLineData[];
  selectedIndex: number | null;
  setSelectedIndex?: (index: number) => void;
}

interface ItemData {
  lines: PrefilledLineData[];
  selectedIndex: number | null;
  onItemClicked: (index: number) => void;
}

const renderRow = (props: ListChildComponentProps) => {
  const { index, style, data: _data } = props;
  const data = _data as ItemData;
  const item = data.lines[index];

  return (
    <Draggable
      draggableId={'prefilled-lines-list-item-' + item.uuid}
      index={index}
      key={item.uuid}
    >
      {(provided) => (
        <PrefilledLineItem
          item={item}
          onItemClicked={() => data.onItemClicked(index)}
          selected={data.selectedIndex === index}
          style={style}
          provided={provided}
        />
      )}
    </Draggable>
  );
};

/**
 * Displays a list of prefilled lines.
 * Must be wrapped into a DragDropContext to make D&D work.
 * @param props
 * @constructor
 */
export const PrefilledLinesList = (props: PrefilledLinesListProps) => {
  const { lines, selectedIndex, setSelectedIndex } = props;
  const data: ItemData = useMemo(
    () => ({
      lines,
      selectedIndex,
      onItemClicked: (index) => {
        setSelectedIndex?.(index);
      },
    }),
    [lines, selectedIndex, setSelectedIndex]
  );
  return (
    <AutoSizer>
      {({ width, height }) => (
        <Droppable
          droppableId={'prefilled-lines'}
          mode={'virtual'}
          renderClone={(provided, snapshot, rubric) => {
            if (snapshot.draggingOver === 'account-table') {
              return (
                <PrefilledLineInsertionDropClone
                  item={lines[rubric.source.index]}
                  provided={provided}
                />
              );
            } else {
              return (
                <PrefilledLineItem
                  item={lines[rubric.source.index]}
                  provided={provided}
                  isDragging={true}
                />
              );
            }
          }}
        >
          {(provided) => (
            <FixedSizeList
              outerRef={provided.innerRef}
              height={height}
              width={width}
              itemSize={36}
              itemCount={lines.length}
              itemData={data}
            >
              {renderRow}
            </FixedSizeList>
          )}
        </Droppable>
      )}
    </AutoSizer>
  );
};
