import { ThunkResult } from '../Store';
import { GoogleApiTools } from '../../tools/GoogleApiTools';
import { authLogoutAction } from '../auth/AuthExtraActions';

export const logoutThunk = (): ThunkResult<void> => (dispatch, getState) => {
  if (getState().auth.authenticatedUser) {
    GoogleApiTools.logout();
    dispatch(authLogoutAction());
  }
};
