import { BankAccount } from '../../types/bank-account/BankAccount';
import { useAppSelector, useEpochDispatch } from '../hooks/ReduxHooks';
import { useCallback, useMemo } from 'react';
import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const useCurrentBankAccountId = (): string | null => {
  return useAppSelector<string | null>((state) => state.bankAccounts.currentId);
};

export const useCurrentBankAccount = (): BankAccount | null => {
  const selector = useMemo(
    () =>
      createSelector(
        (state: RootState) => state.bankAccounts.bankAccounts,
        (state: RootState) => state.bankAccounts.currentId,
        (bankAccountsByUuid, currentUuid) =>
          currentUuid != null ? bankAccountsByUuid[currentUuid] : null
      ),
    []
  );
  return useAppSelector<BankAccount | null>((state) => selector(state));
};

export const useCurrentBankAccountOrThrow = (): BankAccount => {
  const bankAccount = useCurrentBankAccount();
  if (!bankAccount) {
    throw Error('No current bank account!');
  }
  return bankAccount;
};

export const useCurrentAccountDispatch = () => {
  const epochDispatch = useEpochDispatch();
  const currentAccountId = useCurrentBankAccountId();
  return useCallback(
    <PayloadType extends object>(
      actionCreator: (
        payload: PayloadType & { epoch: number; fileId: string }
      ) => {
        type: string;
        payload: PayloadType & { epoch: number; fileId: string };
      },
      payload: PayloadType
    ) => {
      if (currentAccountId == null) {
        throw Error(
          'Cannot dispatch with current account dispatch fn if no current account are set!'
        );
      }

      const payloadWithFileId: PayloadType & { fileId: string } = Object.assign(
        {},
        payload,
        {
          fileId: currentAccountId as string,
        }
      );
      return epochDispatch(actionCreator, payloadWithFileId);
    },
    [currentAccountId, epochDispatch]
  );
};
