import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, DialogActions, DialogContent } from '@material-ui/core';

export type BankAccountNewModalSettingsStepData = {
  // FIXME
};

export interface BankAccountNewModalSettingsStepProps {
  initialValues: BankAccountNewModalSettingsStepData;
  onSubmit: (data: BankAccountNewModalSettingsStepData) => void;
  onCancelClicked: () => void;
}

export const BankAccountNewModalSettingStep = (
  props: BankAccountNewModalSettingsStepProps
) => {
  const { onSubmit, onCancelClicked, initialValues } = props;
  const { handleSubmit } = useForm<BankAccountNewModalSettingsStepData>({
    defaultValues: initialValues,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent>TODO</DialogContent>
      <DialogActions>
        <Button color={'primary'} type={'button'} onClick={onCancelClicked}>
          Précédent
        </Button>
        <Button color={'primary'} type={'submit'}>
          Suivant
        </Button>
      </DialogActions>
    </form>
  );
};
