import { ThunkResult } from '../Store';
import { BankAccountsSlice } from '../bank-accounts/BankAccountsSlice';
import { saveAccountThunk } from './SaveAccountThunk';
import { AccountsListPersistTools } from '../tools/AccountsListPersistTools';

export const closeFileThunk =
  (fileId: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch(BankAccountsSlice.actions.baClose({ fileId }));
    return dispatch(saveAccountThunk(fileId, true)).then(() => {
      dispatch(BankAccountsSlice.actions.baCloseSuccessful({ fileId }));
      AccountsListPersistTools.persistAccountsListFromRedux(getState());
    });
  };
