import React from 'react';
import { AccountNavigatorTree } from '../../../components/tree/AccountNavigatorTree';
import { useCurrentBankAccountOrThrow } from '../../../redux/bank-accounts/CurrentAccountHook';
import { makeStyles } from '@material-ui/core/styles';

export interface CurrentAccountNavigatorProps {
  onTreeItemClicked?: (uuid: string) => void;
}

const useStyle = makeStyles((theme) => ({
  tree: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(0, 1),
  },
}));

export const CurrentAccountNavigator = (
  props: CurrentAccountNavigatorProps
) => {
  const { onTreeItemClicked } = props;

  const account = useCurrentBankAccountOrThrow();
  const classes = useStyle();

  return (
    <AccountNavigatorTree
      className={classes.tree}
      lines={account.operations}
      onTreeItemClicked={onTreeItemClicked}
    />
  );
};
