import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppInitState {
  gapiReady: boolean;
  ready: boolean;
  failure: string | null;
}

const initialState: AppInitState = {
  gapiReady: false,
  ready: false,
  failure: null,
};

export const AppInitSlice = createSlice({
  name: 'AppInit',
  initialState: initialState,
  reducers: {
    gApiReady: (state) => {
      state.gapiReady = true;
    },
    apiReady: (state) => {
      state.ready = true;
    },
    apiFailure: (state, action: PayloadAction<{ message: string }>) => {
      state.failure = action.payload.message;
    },
  },
});
