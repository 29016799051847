import { SearchResultItemData } from './SearchResultData';
import React, { CSSProperties } from 'react';
import { Box, ListItem, ListItemText, useTheme } from '@material-ui/core';

export interface SearchResultItemProps {
  item: SearchResultItemData;
  onItemClicked?: () => void;

  style?: CSSProperties;
}

export const SearchResultItem = (props: SearchResultItemProps) => {
  const { item, onItemClicked, style } = props;
  const theme = useTheme();
  return (
    <Box
      style={{
        backgroundColor: theme.palette.background.paper,
        ...style,
      }}
    >
      <ListItem button dense={true} onClick={onItemClicked}>
        <ListItemText
          primary={
            item.line._type === 'operation'
              ? `${item.line.description} (${item.line.date})`
              : item.line.name
          }
        />
      </ListItem>
    </Box>
  );
};
