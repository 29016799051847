import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface WhatsNewVersion {
  version: string;
  messageMarkup: string;
}

export interface WhatsNewModalProps {
  open: boolean;
  onClose: () => void;

  version: string;
  versions: WhatsNewVersion[];
}

const useStyle = makeStyles((theme) => ({
  versionContainer: {
    marginTop: theme.spacing(2),
  },
  versionTitle: {
    color: theme.palette.getContrastText(theme.palette.background.paper),
    fontWeight: 'bold',
  },
}));

export const WhatsNewModal = (props: WhatsNewModalProps) => {
  const { open, onClose, version, versions } = props;
  const classes = useStyle();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>Nous avons du nouveau pour vous... (v{version})</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Voici les fonctionnalités ajoutées depuis votre dernière visite.
        </DialogContentText>
        {versions.map((v) => (
          <DialogContentText
            key={v.version}
            className={classes.versionContainer}
          >
            <span className={classes.versionTitle}>Version {v.version}</span>
            <br />
            <span dangerouslySetInnerHTML={{ __html: v.messageMarkup }} />
          </DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant={'contained'} color={'primary'} onClick={onClose}>
          D&apos;accord
        </Button>
      </DialogActions>
    </Dialog>
  );
};
