import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import { PrefilledLineData } from '../../../types/prefilled-line/PrefilledLineData';
import {
  PrefilledLinesErrorType,
  usePrefilledLinesErrorMessage,
  usePrefilledLinesErrorType,
  usePrefilledLinesLoading,
  usePrefilledLinesSaving,
} from '../../../redux/prefilled-lines/PrefilledLinesHooks';
import { PrefilledLinesList } from '../../../components/prefilled-lines/PrefilledLinesList';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress } from '@material-ui/core';
import {
  useAppSelector,
  useEpochDispatch,
} from '../../../redux/hooks/ReduxHooks';
import { GoogleApiTools } from '../../../tools/GoogleApiTools';
import { DropFileModal } from '../../../components/modals/drop-file-modal/DropFileModal';
import PrefilledLineImportFileSchema from '../../../generated/json-schema/PrefilledLineImportFile.json';
import { PrefilledLinesSlice } from '../../../redux/prefilled-lines/PrefilledLinesSlice';
import { usePrefilledLinesAutoSave } from '../../../hooks/PrefilledLinesAutoSaveHooks';
import { PrefilledLinesToolbar } from '../../../components/toolbar/PrefilledLinesToolbar';
import FileSaver from 'file-saver';
import { PrefilledLineImportFile } from '../../../types/prefilled-line/PrefilledLineImportFile';
import { useSelectedItemHook } from '../../../hooks/SelectedItemHook';
import { PrefilledLineEditorForm } from '../../../components/prefilled-lines/editor-form/PrefilledLineEditorForm';
import { PrefilledLineEditorModal } from '../../../components/prefilled-lines/editor-form/PrefilledLineEditorModal';
import Dinero from 'dinero.js';
import { SimpleModal } from '../../../components/modals/SimpleModal';

const useStyle = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  toolbarContainer: {
    margin: theme.spacing(1),
  },
  listContainer: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'stretch',
  },
  centeredText: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  inlineButtons: {
    margin: theme.spacing(0.5, 0),
  },
}));

export const PrefilledLinesNavigator = () => {
  const classes = useStyle();

  const epochDispatch = useEpochDispatch();

  // Enable autosave.
  usePrefilledLinesAutoSave(true);

  const [localLoading, setLocalLoading] = useState(false);
  const loading = usePrefilledLinesLoading() || localLoading;
  const saving = usePrefilledLinesSaving();

  const error = usePrefilledLinesErrorType();
  const errorMessage = usePrefilledLinesErrorMessage();
  const configFileId = useAppSelector(
    (state) => state.prefilledLines.configFileId
  );

  const [lineToDeleteIndex, setLineToDeleteIndex] = useState<number | null>(
    null
  );

  const prefilledLines = useSelector<RootState, PrefilledLineData[]>((state) =>
    state.prefilledLines.linesList
      .map((uuid) => state.prefilledLines.linesByUuid[uuid])
      .filter((line) => line != null)
  );
  const selection = useSelectedItemHook(prefilledLines);

  const handleRemoveSelected = useCallback(() => {
    if (selection.selectedIndex != null) {
      setLineToDeleteIndex(selection.selectedIndex);
    }
  }, [selection.selectedIndex]);

  const confirmRemoveSelected = useCallback(() => {
    if (lineToDeleteIndex != null) {
      epochDispatch(PrefilledLinesSlice.actions.removeLine, {
        index: lineToDeleteIndex,
      });
      setLineToDeleteIndex(null);
    }
  }, [epochDispatch, lineToDeleteIndex]);

  const handleReset = useCallback(() => {
    epochDispatch(PrefilledLinesSlice.actions.resetFile, {});
  }, [epochDispatch]);

  const handleExport = useCallback(async () => {
    setLocalLoading(true);
    if (configFileId != null) {
      try {
        const result = await GoogleApiTools.openFile(configFileId);
        const fileContentBlob = new Blob([result.content], {
          type: 'application/json;charset=utf-8',
        });
        FileSaver.saveAs(fileContentBlob, 'export-ligne-preremplies.json');
      } catch (e: any) {
        alert("Impossible d'exporter le fichier : " + e.message);
      }
    } else {
      alert(
        "Impossible d'exporter le fichier : aucun fichier de config existant"
      );
    }
    setLocalLoading(false);
  }, [configFileId]);

  const [importModalOpen, setImportModalOpen] = useState(false);
  const handleImport = useCallback(() => {
    setImportModalOpen(true);
  }, []);
  const handleCloseImport = useCallback(() => {
    setImportModalOpen(false);
  }, []);
  const handleSubmitImport = useCallback(
    (content: string) => {
      handleCloseImport();
      const importContent: PrefilledLineImportFile = JSON.parse(content); // Import has been validated by the modal, no need to try catch.
      epochDispatch(PrefilledLinesSlice.actions.importLinesConfig, {
        importContent,
      });
    },
    [epochDispatch, handleCloseImport]
  );

  // string if a prefilled line is edited, boolean (true) if a new line is edited or null if the modal is closed.
  const [editPrefilledLineId, setEditPrefilledLineId] = useState<
    string | true | null
  >(null);
  const handleFormSubmit = useCallback(
    (data: PrefilledLineEditorForm.FormValues) => {
      if (editPrefilledLineId === true) {
        // We create a new line at the selection's index.
        epochDispatch(PrefilledLinesSlice.actions.addLine, {
          index: (selection.selectedIndex || prefilledLines.length - 1) + 1,
          formValues: data,
        });
      } else if (editPrefilledLineId != null) {
        // We update the line.
        epochDispatch(PrefilledLinesSlice.actions.updateLine, {
          uuid: editPrefilledLineId,
          formValues: data,
        });
      }
      setEditPrefilledLineId(null);
    },
    [
      editPrefilledLineId,
      epochDispatch,
      prefilledLines.length,
      selection.selectedIndex,
    ]
  );
  const editorForm = PrefilledLineEditorForm.useForm(
    {
      name: '',
      checked: false,
      type: '',
      description: '',
      amount: '',
      color: '#ffffff',
      category: undefined,
    },
    handleFormSubmit
  );
  const handleEditPrefilledLine = useCallback(
    (lineId: string | null) => {
      if (lineId != null) {
        const prefilledLineToEdit = prefilledLines.find(
          (l) => l.uuid === lineId
        );
        if (!prefilledLineToEdit) {
          return;
        }
        setEditPrefilledLineId(lineId);
        editorForm.resetValues({
          name: prefilledLineToEdit.name,
          checked: prefilledLineToEdit.checked,
          type: prefilledLineToEdit.type,
          description: prefilledLineToEdit.description,
          amount:
            prefilledLineToEdit.amount &&
            prefilledLineToEdit.amount.amount !== 0
              ? '' + Dinero(prefilledLineToEdit.amount).toUnit()
              : '',
          color: prefilledLineToEdit.color ?? '#ffffff',
          category: prefilledLineToEdit.category,
        });
      } else {
        setEditPrefilledLineId(true);
        editorForm.resetValues({
          name: '',
          checked: false,
          type: '',
          description: '',
          amount: '',
          color: '#ffffff',
          category: undefined,
        });
      }
    },
    [editorForm, prefilledLines]
  );

  if (loading) {
    return (
      <Box className={classes.container}>
        <CircularProgress color={'secondary'} />
      </Box>
    );
  } else {
    if (error !== PrefilledLinesErrorType.NO_ERRORS) {
      return (
        <Box className={classes.container}>
          <span className={classes.centeredText}>
            Une erreur s&apos;est produite pendant le chargement des lignes
            pré-remplies enregistrées.
          </span>
          {error === PrefilledLinesErrorType.INVALID_EXISTING_FILE ? (
            <span className={classes.centeredText}>
              Les lignes pré-remplies enregistrées sont corrompues. Nous vous
              invitons à{' '}
              <Button
                className={classes.inlineButtons}
                variant={'outlined'}
                size={'small'}
                onClick={handleExport}
              >
                Exporter
              </Button>{' '}
              leur configuration puis les{' '}
              <Button
                className={classes.inlineButtons}
                variant={'outlined'}
                size={'small'}
                onClick={handleReset}
              >
                Réinitialiser
              </Button>{' '}
              pour tenter de corriger le problème.
            </span>
          ) : null}
          {errorMessage != null ? (
            <span className={classes.centeredText}>{errorMessage}</span>
          ) : null}
        </Box>
      );
    } else {
      return (
        <>
          <PrefilledLineEditorModal
            open={editPrefilledLineId != null}
            onClose={() => {
              setEditPrefilledLineId(null);
            }}
            form={editorForm}
          />
          <SimpleModal
            title={'Confirmation de suppression'}
            buttons={['Oui', 'Non']}
            onButtonClicked={(buttonIndex) => {
              if (buttonIndex === 0) {
                confirmRemoveSelected();
              } else {
                setLineToDeleteIndex(null);
              }
            }}
            open={lineToDeleteIndex != null}
          >
            Êtes-vous sûr(e) de vouloir supprimer cette ligne pré-remplie ?
          </SimpleModal>
          <DropFileModal
            title={'Import de lignes pré-remplies'}
            message={'Importe des lignes pré-remplies depuis un fichier JSON.'}
            submitButtonLabel={'Importer'}
            jsonSchema={PrefilledLineImportFileSchema}
            onSubmitClicked={handleSubmitImport}
            onCancelClicked={handleCloseImport}
            open={importModalOpen}
            onClose={handleCloseImport}
          />
          <Box className={classes.listContainer}>
            <PrefilledLinesToolbar
              containerClassName={classes.toolbarContainer}
              onAddClicked={() => {
                handleEditPrefilledLine(null);
              }}
              onEditClicked={() => {
                handleEditPrefilledLine(selection.selectedUuid);
              }}
              editDisabled={selection.selectedIndex == null}
              onDeleteClicked={handleRemoveSelected}
              deleteDisabled={selection.selectedIndex == null}
              onImportClicked={handleImport}
              onExportClicked={handleExport}
              saving={saving}
            />
            <Box style={{ flex: 1 }}>
              <PrefilledLinesList
                lines={prefilledLines}
                selectedIndex={selection.selectedIndex}
                setSelectedIndex={selection.setSelectedIndex}
              />
            </Box>
          </Box>
        </>
      );
    }
  }
};
