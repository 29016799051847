export class CollectionsTools {
  public static editAsSet<ValueType>(
    setArray: ValueType[],
    act: (set: Set<ValueType>) => void
  ): ValueType[] {
    const intermediateSet = new Set(setArray);
    act(intermediateSet);
    return Array.from(intermediateSet);
  }
}
