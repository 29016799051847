import { BankAccountOperationColumn } from '../../../types/bank-account/BankAccountOperationColumn';
import { BankAccountLineData } from '../../../types/data/BankAccountLineData';
import { PrefilledLineData } from '../../../types/prefilled-line/PrefilledLineData';

export enum OperationActionType {
  ADD_OPERATION = 'ADD_OPERATION',
  REMOVE_OPERATION = 'REMOVE_OPERATION',
  MOVE_OPERATION = 'MOVE_OPERATION',
  SET_VALUE = 'SET_VALUE',
  CHANGE_COLOR = 'CHANGE_COLOR',
  RESET_CHEQUE_BOOK = 'RESET_CHEQUE_BOOK',
  DELETE_CHEQUE_BOOK = 'DELETE_CHEQUE_BOOK',
  INCREMENT_CHEQUE_BOOK = 'INCREMENT_CHEQUE_BOOK',
  ADD_FROM_PREFILLED_LINE = 'ADD_FROM_PREFILLED_LINE',
  SET_CATEGORY = 'SET_CATEGORY',
}

export interface AddOperationPayload {
  type: OperationActionType.ADD_OPERATION;
  index: number;
  data?: BankAccountLineData;
  lineType: 'operation' | 'month';
}

export interface RemoveOperationPayload {
  type: OperationActionType.REMOVE_OPERATION;
  index: number;
}

export interface MoveOperationPayload {
  type: OperationActionType.MOVE_OPERATION;
  from: number;
  to: number;
}

export interface SetValuePayload {
  type: OperationActionType.SET_VALUE;
  index: number;
  column: BankAccountOperationColumn;
  value: string;
}

export interface ChangeColorPayload {
  type: OperationActionType.CHANGE_COLOR;
  index: number;
  color?: string;
}

export interface ResetChequeBookPayload {
  type: OperationActionType.RESET_CHEQUE_BOOK;
  chequeBookId: string;
  currentChequeNumber: number;
  maxChequeNumber: number;
}

export interface IncrementChequeBookPayload {
  type: OperationActionType.INCREMENT_CHEQUE_BOOK;
  toAdd: number;
}

export interface DeleteChequeBookPayload {
  type: OperationActionType.DELETE_CHEQUE_BOOK;
}

export interface AddFromPrefilledLinePayload {
  type: OperationActionType.ADD_FROM_PREFILLED_LINE;
  index: number;
  prefilledLine: PrefilledLineData;
}

export interface SetCategoryPayload {
  type: OperationActionType.SET_CATEGORY;
  index: number;
  category?: string;
}

export type OperationActionPayload =
  | AddOperationPayload
  | RemoveOperationPayload
  | MoveOperationPayload
  | SetValuePayload
  | ChangeColorPayload
  | ResetChequeBookPayload
  | IncrementChequeBookPayload
  | DeleteChequeBookPayload
  | AddFromPrefilledLinePayload
  | SetCategoryPayload;

export type FilteredOperationActionPayload<T extends OperationActionType> =
  T extends OperationActionType.ADD_OPERATION
    ? AddOperationPayload
    : T extends OperationActionType.REMOVE_OPERATION
    ? RemoveOperationPayload
    : T extends OperationActionType.MOVE_OPERATION
    ? MoveOperationPayload
    : T extends OperationActionType.SET_VALUE
    ? SetValuePayload
    : T extends OperationActionType.CHANGE_COLOR
    ? ChangeColorPayload
    : T extends OperationActionType.RESET_CHEQUE_BOOK
    ? ResetChequeBookPayload
    : T extends OperationActionType.INCREMENT_CHEQUE_BOOK
    ? IncrementChequeBookPayload
    : T extends OperationActionType.DELETE_CHEQUE_BOOK
    ? DeleteChequeBookPayload
    : T extends OperationActionType.ADD_FROM_PREFILLED_LINE
    ? AddFromPrefilledLinePayload
    : T extends OperationActionType.SET_CATEGORY
    ? SetCategoryPayload
    : never;
