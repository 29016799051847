import { BankAccount } from '../../types/bank-account/BankAccount';
import { DineroObject } from 'dinero.js';
import { CONFIG } from '../../config';
import { PrintableAccount } from '../../print/printable-account';
import { PrintableMoney } from '../../print/printable-money';

export class PrinterTools {
  public static downloadPdf(account: BankAccount): Promise<void> {
    return fetch(CONFIG.printer.api + '/pdf', {
      method: 'POST',
      body: JSON.stringify(this.generatePrintableAccount(account)),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain',
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          throw new Error(
            'Unable to fetch the PDF for the account. ' +
              res.status +
              ': ' +
              res.statusText
          );
        }
      })
      .then((base64) => {
        window.open('data:application/pdf;base64, ' + encodeURI(base64));
      });
  }

  public static generatePrintableAccount(
    account: BankAccount
  ): PrintableAccount {
    return {
      name: account.name,
      operations: account.operations
        .map((line) => {
          if (line._type === 'operation') {
            return {
              type: 'operation' as any,
              checked: line.checked,
              date: line.date,
              opType: line.type,
              description: line.description,
              amount: this.convertAmount(line.amount),
              total: this.convertAmount(line.total),
            };
          } else if (line._type === 'month') {
            return {
              type: 'month' as any,
              month: line.name,
              total: this.convertAmount(line.total),
            };
          } else {
            console.warn(
              'Line of type ' +
                (line as any)._type +
                ' is not supported when printing!'
            );
            return null;
          }
        })
        .filter((printableLine) => printableLine != null)
        .map((line) => {
          if (line) {
            return line;
          } else {
            throw Error('Should not happen!');
          }
        }),
    };
  }

  private static convertAmount(amount: DineroObject): PrintableMoney {
    return {
      currency: amount.currency,
      value: '' + amount.amount / Math.pow(10, amount.precision),
    };
  }
}
