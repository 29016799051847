import { BankAccountChequeBook } from '../../../types/bank-account/BankAccountChequeBook';
import React from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

export interface AccountChequeBookControllerProps {
  chequeBook: BankAccountChequeBook;
  onIncrementClicked?: (negative: boolean) => void;
  onNewChequeBookClicked?: () => void;
}

const useStyle = makeStyles((theme) => ({
  newChequeBookButton: {
    marginRight: theme.spacing(1),
  },
}));

export const AccountChequeBookController = (
  props: AccountChequeBookControllerProps
) => {
  const { chequeBook, onIncrementClicked, onNewChequeBookClicked } = props;
  const classes = useStyle();

  const isOutOfBounds =
    chequeBook.currentChequeNumber > chequeBook.maxChequeNumber;

  return (
    <Box>
      <Button
        className={classes.newChequeBookButton}
        variant={'outlined'}
        color={'primary'}
        onClick={onNewChequeBookClicked}
      >
        Nouveau chéquier...
      </Button>
      <IconButton
        disabled={chequeBook.currentChequeNumber <= 1}
        onClick={() => onIncrementClicked?.(true)}
      >
        <Remove />
      </IconButton>
      <IconButton onClick={() => onIncrementClicked?.(false)}>
        <Add />
      </IconButton>
      {isOutOfBounds ? <div>Le chéquier est terminé.</div> : null}
    </Box>
  );
};
