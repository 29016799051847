import { Box, makeStyles } from '@material-ui/core';
import React, { useCallback, useRef } from 'react';
import { LeftDrawer } from './drawer/LeftDrawer';
import { useAutoSave } from '../../hooks/AutoSaveHooks';
import {
  useCurrentAccountDispatch,
  useCurrentBankAccountId,
} from '../../redux/bank-accounts/CurrentAccountHook';
import {
  AccountEditor,
  AccountEditorInterface,
} from './account-editor/AccountEditor';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { BankAccountsSlice } from '../../redux/bank-accounts/BankAccountsSlice';
import { OperationActionType } from '../../redux/bank-accounts/operations/OperationActionPayload';
import { useEpochDispatch } from '../../redux/hooks/ReduxHooks';
import { useStore } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { PrefilledLinesSlice } from '../../redux/prefilled-lines/PrefilledLinesSlice';
import { usePreventCloseWhenNotSaved } from '../../hooks/PreventCloseWhenNotSavedHook';
import { WhatsNewController } from './WhatsNewController';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    flex: 1,
    margin: theme.spacing(2),
    display: 'flex',
  },
}));

export const ConnectedPage = () => {
  const classes = useStyle();

  useAutoSave(true);
  usePreventCloseWhenNotSaved(true);

  const currentAccount = useCurrentBankAccountId();

  const rootState = useStore<RootState>();

  const epochDispatch = useEpochDispatch();
  const currentAccountDispatch = useCurrentAccountDispatch();

  const accountEditorRef = useRef<AccountEditorInterface>(null);

  const handleDrop = useCallback(
    (result: DropResult) => {
      if (result.destination == null) {
        return;
      }
      const srcDroppableId = result.source.droppableId;
      const destDroppableId = result.destination.droppableId;

      // A D&D inside the account table itself.
      if (
        srcDroppableId === 'account-table' &&
        destDroppableId === 'account-table'
      ) {
        currentAccountDispatch(BankAccountsSlice.actions.baAct as any, {
          type: OperationActionType.MOVE_OPERATION,
          from: result.source.index,
          to: result.destination.index || 0,
        });
      } else if (
        srcDroppableId === 'prefilled-lines' &&
        destDroppableId === 'prefilled-lines'
      ) {
        epochDispatch(PrefilledLinesSlice.actions.moveLine, {
          from: result.source.index,
          to: result.destination.index,
        });
      } else if (
        srcDroppableId === 'prefilled-lines' &&
        destDroppableId === 'account-table'
      ) {
        // We want to insert the prefilled line in the current account.
        const storeState = rootState.getState();
        const prefilledLineUuid =
          storeState.prefilledLines.linesList[result.source.index];
        const prefilledLine =
          storeState.prefilledLines.linesByUuid[prefilledLineUuid];
        currentAccountDispatch(BankAccountsSlice.actions.baAct as any, {
          type: OperationActionType.ADD_FROM_PREFILLED_LINE,
          index: result.destination.index ?? 0,
          prefilledLine,
        });
      }
    },
    [currentAccountDispatch, epochDispatch, rootState]
  );

  return (
    <Box className={classes.root}>
      <DragDropContext onDragEnd={handleDrop}>
        <LeftDrawer
          onWantToNavigateToLineUuid={(uuid) => {
            if (accountEditorRef.current) {
              accountEditorRef.current.scrollToLine(uuid);
            }
          }}
        />
        <WhatsNewController />
        <main className={classes.content}>
          {currentAccount != null ? (
            <AccountEditor ref={accountEditorRef} />
          ) : null}
        </main>
      </DragDropContext>
    </Box>
  );
};
