export default {
  envName: 'production',
  gapi: {
    appId: '443178242718',
    clientId:
      '443178242718-d93fl278jabtnhckr65jn568ri8ku426.apps.googleusercontent.com',
    apiKey: 'AIzaSyDjvNR3xHcGpm9vWEmK4DeDXzTmr8NuOy8',
  },
  printer: {
    api: 'https://jp95abeb8l.execute-api.eu-central-1.amazonaws.com',
  },
};
