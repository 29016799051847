import * as yup from 'yup';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Box,
  Button,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { ColorPickerButton } from '../../buttons/ColorPickerButton';
import {
  CATEGORIES_PER_KEYS,
  CATEGORIES_SORTED,
} from '../../../types/Categories';
import { SelectCategoryModal } from '../../modals/SelectCategoryModal';

interface PrefilledLineEditorFormValues {
  name: string;
  checked: boolean;
  type: string;
  description: string;
  amount: string;
  color: string;
  category?: string;
}

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  checked: yup.boolean().required(),
  type: yup.string(),
  description: yup.string(),
  amount: yup.lazy((value) =>
    value === '' ? yup.string().equals(['']) : yup.number().strict(false)
  ), // Allow cast from string.
  color: yup.string().matches(/^#[0-9ABCDEFabcdef]{6}$/),
  category: yup
    .string()
    .notRequired()
    .oneOf(CATEGORIES_SORTED.map((category) => category.key)),
});

const usePrefilledLineEditorForm = (
  defaultValues: PrefilledLineEditorFormValues,
  onSubmit: (values: PrefilledLineEditorFormValues) => void
) => {
  const [initialValues, setInitialValues] = useState(defaultValues);

  const form = useFormik<PrefilledLineEditorFormValues>({
    validationSchema,
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });
  const resetValues = useCallback(
    (values: PrefilledLineEditorFormValues) => {
      setInitialValues(values);
      form.resetForm();
    },
    [form]
  );
  return useMemo(
    () => ({
      ...form,
      resetValues,
    }),
    [form, resetValues]
  );
};

interface Props {
  form: ReturnType<typeof usePrefilledLineEditorForm>;
}

const useStyle = makeStyles((theme) => ({
  form: {
    '& > *': {
      margin: theme.spacing(1, 0),
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(0.5),
    },
    '& > :not(:last-child)': {
      marginRight: theme.spacing(0.5),
    },
  },
  colorFieldContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  colorFieldLabel: {
    color: theme.palette.grey['700'],
    fontSize: '1rem',
    marginBottom: theme.spacing(1),
  },
}));

const PrefilledLineEditComponent = (props: Props) => {
  const classes = useStyle();
  const theme = useTheme();

  const category = props.form.values.category
    ? CATEGORIES_PER_KEYS[props.form.values.category]
    : undefined;
  const categoryDisplay = category ? category.name : 'Aucune...';

  const categoryColor = category?.color;
  const categoryTextColor = categoryColor
    ? theme.palette.getContrastText(categoryColor)
    : undefined;

  const [selectCategoryModalOpen, setSelectCategoryModalOpen] = useState(false);

  return (
    <>
      <SelectCategoryModal
        open={selectCategoryModalOpen}
        onCategorySelected={(category) => {
          setSelectCategoryModalOpen(false);
          props.form.setFieldTouched('category', true);
          props.form.setFieldValue('category', category?.key);
        }}
        onCancel={() => {
          setSelectCategoryModalOpen(false);
          props.form.setFieldTouched('category', true);
        }}
      />
      <form className={classes.form} onSubmit={props.form.handleSubmit}>
        <TextField
          fullWidth
          name={'name'}
          label={'Nom'}
          variant="filled"
          value={props.form.values.name}
          onChange={props.form.handleChange}
          onBlur={props.form.handleBlur}
          error={props.form.touched.name && props.form.errors.name != null}
          helperText={
            props.form.touched.name && props.form.errors.name != null
              ? 'Un nom est requis.'
              : ' '
          }
        />
        <FormControlLabel
          label={'Encaissé'}
          control={
            <Checkbox
              name={'checked'}
              checked={props.form.values.checked}
              onChange={props.form.handleChange}
              onBlur={props.form.handleBlur}
            />
          }
        />
        <Box className={classes.rowContainer}>
          <TextField
            fullWidth
            name={'type'}
            label={'Type'}
            variant="filled"
            value={props.form.values.type}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={props.form.touched.type && props.form.errors.type != null}
            helperText={
              props.form.touched.type && props.form.errors.type != null
                ? 'Type invalide.'
                : ' '
            }
          />
          <TextField
            fullWidth
            name={'description'}
            label={'Description'}
            variant="filled"
            value={props.form.values.description}
            onChange={props.form.handleChange}
            onBlur={props.form.handleBlur}
            error={
              props.form.touched.description &&
              props.form.errors.description != null
            }
            helperText={
              props.form.touched.description &&
              props.form.errors.description != null
                ? 'Description invalide.'
                : ' '
            }
          />
        </Box>
        <TextField
          fullWidth
          name={'amount'}
          label={'Montant'}
          variant="filled"
          value={props.form.values.amount}
          onChange={props.form.handleChange}
          onBlur={props.form.handleBlur}
          error={props.form.touched.amount && props.form.errors.amount != null}
          helperText={
            props.form.touched.amount && props.form.errors.amount != null
              ? 'Une valeur numérique ou vide doit être saisie.'
              : ' '
          }
        />
        <Box className={classes.rowContainer}>
          <div className={classes.colorFieldContainer}>
            <label className={classes.colorFieldLabel}>
              Couleur de la ligne
            </label>
            <br />
            <ColorPickerButton
              variant={'contained'}
              buttonText={'Définir...'}
              colorValue={props.form.values.color}
              onColorValueSubmit={(color) =>
                props.form.setFieldValue('color', color)
              }
              onBlur={() => props.form.setFieldTouched('color', true)}
            />
          </div>
          <div className={classes.colorFieldContainer}>
            <label className={classes.colorFieldLabel}>
              Catégorie de la ligne
            </label>
            <br />
            <Button
              variant={'contained'}
              style={{
                backgroundColor: categoryColor,
                color: categoryTextColor,
              }}
              onClick={() => setSelectCategoryModalOpen(true)}
            >
              {category ? (
                <category.icon
                  htmlColor={categoryTextColor}
                  style={{ marginRight: 8 }}
                />
              ) : null}
              {categoryDisplay}
            </Button>
          </div>
        </Box>
      </form>
    </>
  );
};

export const PrefilledLineEditorForm = {
  Component: PrefilledLineEditComponent,
  useForm: usePrefilledLineEditorForm,
};

// eslint-disable-next-line no-redeclare,@typescript-eslint/no-namespace
export declare namespace PrefilledLineEditorForm {
  export type ComponentProps = Props;
  export type FormValues = PrefilledLineEditorFormValues;
  export type FormState = ReturnType<typeof usePrefilledLineEditorForm>;
}
