import { BankAccountsState } from './BankAccountsSlice';
import { BankAccountPayloadAction } from './BankAccountPayloadAction';
import { BankAccount } from '../../types/bank-account/BankAccount';
import { Draft } from '@reduxjs/toolkit';

/**
 * Wraps a reducer for the BankAccountsSlice for actions providing an epoch and a fileId.
 * It reduces a boilerplate for writing reducers acting on a bank account with an epoch.
 *
 * It runs the given reducer and then set the lastChangeEpoch with the epoch given in the
 * action's payload.
 *
 * @param reducer a reducer that can get a direct access to the bank account designated by
 * the fileId field in the action's payload.
 */
export const bankAccountReducer = <ExtraPayloadContent extends object>(
  reducer: (
    state: Draft<BankAccountsState>,
    action: BankAccountPayloadAction<ExtraPayloadContent>,
    bankAccount: Draft<BankAccount>
  ) => void
) => {
  return (
    state: Draft<BankAccountsState>,
    action: BankAccountPayloadAction<ExtraPayloadContent>
  ) => {
    const { epoch, fileId } = action.payload;
    const account = state.bankAccounts[fileId];
    reducer(state, action, account);
    account.lastChangeEpoch = epoch;
  };
};
