import React, { CSSProperties, MouseEventHandler, useMemo } from 'react';
import { TableCell, Tooltip } from '@material-ui/core';

export interface AccountOperationCellWithTooltipProps {
  /**
   * If the end of the value is between parenthesises, it will be displayed as a tooltip.
   */
  value: string;

  className?: string;

  style?: CSSProperties;

  onClick?: MouseEventHandler<HTMLDivElement>;
  onDoubleClick?: MouseEventHandler<HTMLDivElement>;
}

export const AccountOperationCellWithTooltip = (
  props: AccountOperationCellWithTooltipProps
) => {
  const { className, style, onClick, onDoubleClick, value } = props;

  const [cellValue, tooltipValue] = useMemo(() => {
    const match = value.trim().match(/^([^(]*)(?:\(([^)]*)\))?$/);
    if (match) {
      return [match[1], match[2]];
    } else {
      return [value, undefined];
    }
  }, [value]);

  return (
    <Tooltip
      arrow={true}
      open={tooltipValue != null ? undefined : false}
      title={tooltipValue ?? ''}
    >
      <TableCell
        component={'div'}
        className={className}
        style={style}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        {cellValue}
      </TableCell>
    </Tooltip>
  );
};
