import { configureStore } from '@reduxjs/toolkit';
import { rootReducer, RootState } from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
});

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  (module as any).hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;

export type ThunkResult<R> = (
  dispatch: AppDispatch,
  getState: () => RootState,
  extraArgument: undefined
) => R;

export const STORE = store;
