import { Box, useTheme } from '@material-ui/core';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccountBalance,
  Check,
  ExpandLess,
  ExpandMore,
  TrendingDown,
  TrendingUp,
} from '@material-ui/icons';
import Dinero, { DineroObject } from 'dinero.js';
import { BankAccount } from '../../../types/bank-account/BankAccount';
import { AccountTotalGraph } from './AccountTotalGraph';
import { AccountStatisticField } from './AccountStatisticField';
import { BankAccountLineTools } from '../../../tools/BankAccountLineTools';
import { PopperButton } from '../../buttons/PopperButton';

export interface AccountAmountsPopperProps {
  account: BankAccount;
  genuineBankAmount?: DineroObject;
  onGenuineBankAmountEdit?: () => void;
}

const useStyle = makeStyles((theme) => ({
  paperSubContainers: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2),
  },
}));

export const AccountAmountsPopper = (props: AccountAmountsPopperProps) => {
  const { account, genuineBankAmount, onGenuineBankAmountEdit } = props;

  const lastLineTotal: DineroObject = account.operations[
    account.operations.length - 1
  ].total || { currency: 'EUR', amount: 0 };
  const lastLineTotalFormat = Dinero(lastLineTotal).toFormat();
  const checkedAmount = useMemo(
    () =>
      account.operations
        .map((line) => Dinero(BankAccountLineTools.getLineCheckedAmount(line)))
        .reduce(
          (sum: Dinero.Dinero | null, amount) =>
            sum ? sum.add(amount) : amount,
          null
        ),
    [account.operations]
  );
  const checkedAmountFormat = checkedAmount?.toFormat() || '<vide>';

  const classes = useStyle();
  const theme = useTheme();

  let buttonContent: any;
  if (
    genuineBankAmount != null &&
    checkedAmount != null &&
    !Dinero(genuineBankAmount).equalsTo(checkedAmount)
  ) {
    const difference = checkedAmount.subtract(Dinero(genuineBankAmount));
    const positiveDifference = difference.isPositive();
    buttonContent = (
      <>
        {positiveDifference ? (
          <TrendingUp htmlColor={'red'} style={{ marginLeft: 8 }} />
        ) : (
          <TrendingDown htmlColor={'red'} style={{ marginLeft: 8 }} />
        )}

        <span style={{ marginLeft: 8, marginRight: 8 }}>
          {difference.multiply(positiveDifference ? 1 : -1).toFormat()}
          {positiveDifference ? ' en trop' : ' manquant'}
        </span>
      </>
    );
  } else {
    buttonContent = (
      <>
        {genuineBankAmount != null && checkedAmount != null ? (
          <Check htmlColor={'green'} style={{ marginLeft: 8 }} />
        ) : (
          <AccountBalance style={{ marginLeft: 8 }} />
        )}
        <span style={{ marginLeft: 8, marginRight: 8 }}>
          {checkedAmountFormat}
        </span>
      </>
    );
  }

  return (
    <PopperButton
      buttonContent={(expanded) => (
        <>
          {buttonContent}
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </>
      )}
    >
      <Box className={classes.paperSubContainers}>
        <AccountStatisticField
          graphLegendColor={theme.palette.warning.main}
          value={lastLineTotalFormat}
          label={'Solde'}
        />
        <AccountStatisticField
          graphLegendColor={theme.palette.success.main}
          value={checkedAmountFormat}
          label={'Solde encaissé'}
        />
        <AccountStatisticField
          value={
            genuineBankAmount
              ? Dinero(genuineBankAmount).toFormat()
              : '<non défini>'
          }
          label={'Solde encaissé réel'}
          editable={true}
          onClick={onGenuineBankAmountEdit}
        />
      </Box>
      <Box className={classes.paperSubContainers}>
        <AccountTotalGraph lines={account.operations} />
      </Box>
    </PopperButton>
  );
};
