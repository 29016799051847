import { BankAccount } from '../../../types/bank-account/BankAccount';
import { Draft } from 'immer';
import { BankAccountsState } from '../BankAccountsSlice';
import {
  OperationActionPayload,
  OperationActionType,
} from './OperationActionPayload';
import { BankAccountLineData } from '../../../types/data/BankAccountLineData';
import Dinero from 'dinero.js';
import { createLine } from '../BankAccountsReduxTools';
import { BankAccountLineTools } from '../../../tools/BankAccountLineTools';

/**
 * Encapsulate an operation action reducer that may need to increment and get
 * a cheque number from the cheque book.
 * @param account
 * @param state
 * @param reducer the reducer, it receives a fourth argument which is a function that can be
 * called to increment and get a new cheque number from the cheque book. The proper undo action
 * will be merged with the undo action(s) the reducer returns if getNewChequeNumber was called.
 * Note: getNewChequeNumber may be called only if hasChequeBook (third arg) is true.
 */
export const manageChequeBook = (
  account: Draft<BankAccount>,
  state: Draft<BankAccountsState>,
  reducer: (
    hasChequeBook: boolean,
    getNewChequeNumber: () => string
  ) => OperationActionPayload | OperationActionPayload[] | null
): OperationActionPayload | OperationActionPayload[] | null => {
  let chequeBookIncrementCount = 0;
  const innerReducerUndoActions = reducer(account.chequeBook != null, () => {
    if (account.chequeBook == null) {
      throw Error(
        'Cannot call getNewChequeNumber() if no cheque books on the account!'
      );
    }
    chequeBookIncrementCount++;
    const paddedNumber = `${account.chequeBook.currentChequeNumber}`.padStart(
      `${account.chequeBook.maxChequeNumber}`.length,
      '0'
    );
    const newChequeNumber = `Chèque ${paddedNumber} (${account.chequeBook.chequeBookId} ${paddedNumber})`;
    account.chequeBook.currentChequeNumber++;
    return newChequeNumber;
  });

  if (innerReducerUndoActions == null) {
    return null;
  } else {
    const array = Array.isArray(innerReducerUndoActions)
      ? innerReducerUndoActions
      : [innerReducerUndoActions];
    if (chequeBookIncrementCount > 0) {
      array.push({
        type: OperationActionType.INCREMENT_CHEQUE_BOOK,
        toAdd: -chequeBookIncrementCount,
      });
    }
    return array;
  }
};

export const addLineInBankAccount = (
  account: Draft<BankAccount>,
  index: number,
  lineType: 'operation' | 'month',
  data?: BankAccountLineData
): OperationActionPayload | OperationActionPayload[] | null => {
  // If there is a previous operation, get the total from it.
  let initialTotal = Dinero({ currency: 'EUR', amount: 0 });
  if (index > 0) {
    const previousOperationTotal = account.operations[index - 1].total;
    if (previousOperationTotal != null) {
      initialTotal = Dinero(previousOperationTotal);
    }
  }
  account.operations.splice(
    index,
    0,
    createLine(initialTotal, data ?? lineType)
  );

  // If both the line before and after the newly inserted line have the same date,
  // simply set it to the inserted line.
  const insertedLine = account.operations[index];
  if (
    insertedLine._type === 'operation' &&
    index > 0 &&
    index < account.operations.length - 1
  ) {
    const operationBefore = account.operations[index - 1];
    const operationAfter = account.operations[index + 1];
    if (
      operationBefore._type === 'operation' &&
      operationAfter._type === 'operation'
    ) {
      if (operationBefore.date === operationAfter.date) {
        insertedLine.date = operationBefore.date;
      }
    }
  }

  // Add the new operation amount if data is provided to the operations following the new operation.
  // (also add it to the new operation's total).
  if (data) {
    account.operations.forEach((o, oIndex) => {
      if (oIndex >= index) {
        o.total = Dinero(o.total)
          .add(Dinero(BankAccountLineTools.getLineAmount(data)))
          .toObject();
      }
    });
  }

  // A simple delete is needed.
  return {
    type: OperationActionType.REMOVE_OPERATION,
    index,
  };
};
