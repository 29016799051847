import Ajv from 'ajv';
import { BankAccount } from '../types/bank-account/BankAccount';
import {
  isSerializedAccountV1,
  SerializedAccountV1,
} from '../types/serialization/v1/SerializedAccountV1';
import { isSerializedAccount } from '../types/serialization/UnknownSerializedAccount';
import { SerializedAccount } from '../types/serialization/SerializedAccount';
import { BankAccountData } from '../types/data/BankAccountData';
import Dinero from 'dinero.js';
import { BankAccountLine } from '../types/bank-account/BankAccountLine';
import { SerializedLineV1 } from '../types/serialization/v1/SerializedLineV1';
import { BankAccountLineData } from '../types/data/BankAccountLineData';
import { isSerializedOperationV1 } from '../types/serialization/v1/SerializedOperationV1';
import { isSerializedMonthV1 } from '../types/serialization/v1/SerializedMonthV1';
import SerializedAccountSchema from '../generated/json-schema/SerializedAccount.json';

export class AccountSerializationTools {
  public static serialize(account: BankAccount): SerializedAccount {
    return {
      type: 'com.victorlevasseur.account-manager.file',
      version: 1,
      operations: account.operations.map((line) => this.serializeV1Line(line)),
      chequeBook: account.chequeBook,
    };
  }

  public static deserialize(account: unknown): BankAccountData {
    const validator = new Ajv();
    const isValid = validator.validate(SerializedAccountSchema, account);
    if (!isValid) {
      throw new Error('Compte invalide : ' + validator.errorsText());
    }

    if (!isSerializedAccount(account)) {
      throw new Error('This is not a serialized account!');
    }

    if (isSerializedAccountV1(account)) {
      return this.deserializeV1(account);
    } else {
      throw new Error('Unsupported serialized account version!');
    }
  }

  private static deserializeV1(account: SerializedAccountV1): BankAccountData {
    return {
      operations: account.operations.map((line) =>
        this.deserializeV1Line(line)
      ),
      chequeBook: account.chequeBook,
    };
  }

  private static deserializeV1Line(
    line: SerializedLineV1
  ): BankAccountLineData {
    if (isSerializedOperationV1(line)) {
      return {
        _type: 'operation',
        checked: line.checked,
        date: line.date,
        type: line.type,
        description: line.description,
        amount: Dinero({ currency: 'EUR', amount: line.amount }).toObject(),
        color: line.color,
        category: line.category,
      };
    } else if (isSerializedMonthV1(line)) {
      return {
        _type: 'month',
        name: line.name,
      };
    } else {
      throw Error('Unsupported SerializedLineV1 _type: ' + (line as any)._type);
    }
  }

  private static serializeV1Line(line: BankAccountLine): SerializedLineV1 {
    if (line._type === 'operation') {
      return {
        _type: 'operation',
        checked: line.checked,
        date: line.date,
        type: line.type,
        description: line.description,
        amount: Dinero(line.amount).getAmount(),
        color: line.color,
        category: line.category,
      };
    } else if (line._type === 'month') {
      return {
        _type: 'month',
        name: line.name,
      };
    } else {
      throw Error('Unsupported BankAccountLine _type: ' + (line as any)._type);
    }
  }
}
