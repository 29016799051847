import { makeStyles, Theme } from '@material-ui/core/styles';
import { TableCell, TableRow } from '@material-ui/core';
import React, {
  CSSProperties,
  memo,
  MouseEventHandler,
  useCallback,
} from 'react';
import clsx from 'clsx';
import { BankAccountOperationColumn } from '../../types/bank-account/BankAccountOperationColumn';
import { DraggableProvided } from 'react-beautiful-dnd';
import { BankAccountMonth } from '../../types/bank-account/BankAccountMonth';
import { GripIcon, SelectedGripIcon } from '../../assets/GripIcon';

export interface AccountMonthRowProps {
  item: BankAccountMonth;
  onColumnClick?: (column: BankAccountOperationColumn, rect: DOMRect) => void;
  onColumnDoubleClick?: (
    column: BankAccountOperationColumn,
    rect: DOMRect
  ) => void;
  selected?: boolean;
  isDragging?: boolean;

  style?: CSSProperties;

  draggableProvided?: DraggableProvided;

  onMouseEnter?: (el: HTMLElement) => void;
  onMouseLeave?: () => void;
  onContextMenu?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const useStyle = makeStyles<Theme, { isDragging: boolean }, string>((theme) => {
  const isDarkPalette = theme.palette.type === 'dark';
  const backgroundColor = theme.palette.grey[isDarkPalette ? 200 : 800];
  const hoverColor = theme.palette.grey[isDarkPalette ? 300 : 700];
  const textColor = theme.palette.getContrastText(backgroundColor);

  return {
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      backgroundColor,
      boxShadow: (props) => theme.shadows[props.isDragging ? 4 : 0],
    },
    tableRowSelected: {
      backgroundColor: hoverColor,
      transition: 'background-color 200ms ease-in-out',
    },
    tableCell: {
      color: textColor,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flexBasis: 0,
      transition: 'background-color 200ms ease-in-out',
      '&:hover': {
        backgroundColor: hoverColor,
        cursor: 'pointer',
      },
    },
    nameCell: {
      textAlign: 'center',
      fontSize: 24,
      padding: theme.spacing(1),
    },
    gripCell: {
      padding: theme.spacing(0),
      flex: 0,
      flexBasis: 40,
      textAlign: 'center',
    },
  };
});

export const AccountMonthRow = memo((props: AccountMonthRowProps) => {
  const {
    item,
    style,
    onColumnClick,
    onColumnDoubleClick,
    selected,
    draggableProvided,
    onContextMenu,
    isDragging,
  } = props;
  const classes = useStyle({ isDragging: isDragging || false });

  const columnClickHandler = useCallback(
    (column: BankAccountOperationColumn): MouseEventHandler<HTMLDivElement> =>
      (e) => {
        if (onColumnClick) {
          onColumnClick(column, e.currentTarget.getBoundingClientRect());
        }
      },
    [onColumnClick]
  );

  const columnDblClickHandler = useCallback(
    (column: BankAccountOperationColumn): MouseEventHandler<HTMLDivElement> =>
      (e) => {
        if (onColumnDoubleClick) {
          onColumnDoubleClick(column, e.currentTarget.getBoundingClientRect());
        }
      },
    [onColumnDoubleClick]
  );

  return (
    <TableRow
      ref={draggableProvided?.innerRef}
      component={'div'}
      className={clsx(
        classes.tableRow,
        selected ? classes.tableRowSelected : undefined
      )}
      {...draggableProvided?.draggableProps}
      style={style}
      onMouseEnter={(event) => {
        if (props.onMouseEnter) {
          props.onMouseEnter(event.currentTarget);
        }
      }}
      onMouseLeave={props.onMouseLeave}
      onContextMenu={onContextMenu}
    >
      <TableCell
        component={'div'}
        className={clsx(classes.tableCell, classes.gripCell)}
        {...draggableProvided?.dragHandleProps}
        onClick={columnClickHandler(BankAccountOperationColumn.DND)}
        onDoubleClick={columnDblClickHandler(BankAccountOperationColumn.DND)}
      >
        {selected ? <SelectedGripIcon /> : <GripIcon />}
      </TableCell>
      <TableCell
        component={'div'}
        className={clsx(classes.tableCell, classes.nameCell)}
        style={{ flex: 1 }}
        onClick={columnClickHandler(BankAccountOperationColumn.MONTH_NAME)}
        onDoubleClick={columnDblClickHandler(
          BankAccountOperationColumn.MONTH_NAME
        )}
      >
        {item.name}
      </TableCell>
    </TableRow>
  );
});
AccountMonthRow.displayName = 'AccountMonthRow';
