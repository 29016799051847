import { PopperButton } from '../../buttons/PopperButton';
import React, { ReactNode, useCallback, useState } from 'react';
import { ErrorOutline, ExpandLess, ExpandMore } from '@material-ui/icons';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BankAccountChequeBook } from '../../../types/bank-account/BankAccountChequeBook';
import { AccountChequeBookResetForm } from './AccountChequeBookResetForm';
import { useCurrentAccountDispatch } from '../../../redux/bank-accounts/CurrentAccountHook';
import { BankAccountsSlice } from '../../../redux/bank-accounts/BankAccountsSlice';
import {
  IncrementChequeBookPayload,
  OperationActionType,
  ResetChequeBookPayload,
} from '../../../redux/bank-accounts/operations/OperationActionPayload';
import { AccountChequeBookController } from './AccountChequeBookController';

export interface AccountChequeBookPopperProps {
  chequeBook?: BankAccountChequeBook;
}

const useStyle = makeStyles((theme) => ({
  currentChequeNumberChip: {
    margin: theme.spacing(0, 1),
  },
}));

// TODO: Extract redux actions.
export const AccountChequeBookPopper = (
  props: AccountChequeBookPopperProps
) => {
  const { chequeBook } = props;
  const classes = useStyle();

  // Force to display the reset form even if a cheque book is currently set.
  const [wantsToResetChequeBook, setWantsToResetChequeBook] = useState(false);
  const currentAccountDispatch = useCurrentAccountDispatch();

  const resetChequeBookFormSubmit = useCallback(
    (values: AccountChequeBookResetForm.FormValues) => {
      setWantsToResetChequeBook(false);
      currentAccountDispatch<ResetChequeBookPayload>(
        BankAccountsSlice.actions.baAct as any,
        {
          type: OperationActionType.RESET_CHEQUE_BOOK,
          chequeBookId: values.chequeBookId,
          maxChequeNumber: +values.maxChequeNumber,
          currentChequeNumber: 1,
        }
      );
    },
    [currentAccountDispatch]
  );
  const resetChequeBookForm = AccountChequeBookResetForm.useForm(
    {
      chequeBookId: '',
      maxChequeNumber: '' + (chequeBook?.maxChequeNumber ?? ''),
    },
    resetChequeBookFormSubmit
  );

  const handleChequeBookIncrement = useCallback(
    (negative: boolean) => {
      currentAccountDispatch<IncrementChequeBookPayload>(
        BankAccountsSlice.actions.baAct as any,
        {
          type: OperationActionType.INCREMENT_CHEQUE_BOOK,
          toAdd: negative ? -1 : 1,
        }
      );
    },
    [currentAccountDispatch]
  );

  let buttonContent: ReactNode;
  if (chequeBook) {
    const isOutOfBounds =
      chequeBook.currentChequeNumber > chequeBook.maxChequeNumber;
    buttonContent = (
      <>
        Chéquier {chequeBook.chequeBookId}
        <Chip
          className={classes.currentChequeNumberChip}
          icon={isOutOfBounds ? <ErrorOutline /> : undefined}
          color={'secondary'}
          size={'small'}
          label={
            `${chequeBook.currentChequeNumber}`.padStart(
              `${chequeBook.maxChequeNumber}`.length,
              '0'
            ) +
            '/' +
            chequeBook.maxChequeNumber
          }
        />
      </>
    );
  } else {
    buttonContent = 'Pas de chéquier configuré';
  }

  return (
    <PopperButton
      buttonContent={(expanded) => (
        <>
          {buttonContent}
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </>
      )}
    >
      {chequeBook && !wantsToResetChequeBook ? (
        <AccountChequeBookController
          chequeBook={chequeBook}
          onNewChequeBookClicked={() => {
            setWantsToResetChequeBook(true);
            resetChequeBookForm.resetValues({
              chequeBookId: chequeBook?.chequeBookId ?? '',
              maxChequeNumber: '' + chequeBook?.maxChequeNumber ?? '',
            });
          }}
          onIncrementClicked={handleChequeBookIncrement}
        />
      ) : (
        <AccountChequeBookResetForm.Component
          form={resetChequeBookForm}
          cancellable={wantsToResetChequeBook}
          onCancelClicked={() => setWantsToResetChequeBook(false)}
        />
      )}
    </PopperButton>
  );
};
