import { Spacer } from '../common/Spacer';
import React from 'react';
import { Button } from '@material-ui/core';

export interface PublicToolbarProps {
  logInDisabled?: boolean;
  onLogInClicked: () => void;
}

export const PublicToolbar = (props: PublicToolbarProps) => {
  const { logInDisabled, onLogInClicked } = props;
  return (
    <>
      <Spacer />
      <Button
        color={'inherit'}
        disabled={logInDisabled}
        onClick={onLogInClicked}
      >
        Se connecter
      </Button>
    </>
  );
};
