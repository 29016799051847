import React from 'react';
import { BankAccountCreationData } from '../../../types/data/BankAccountCreationData';
import {
  Dialog,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import {
  BankAccountNewModalFileStep,
  BankAccountNewModalFileStepData,
} from './BankAccountNewModalFileStep';
import {
  BankAccountNewModalSettingsStepData,
  BankAccountNewModalSettingStep,
} from './BankAccountNewModalSettingsStep';
import { BankAccountNewModalRecapStep } from './BankAccountNewModalRecapStep';

export interface BankAccountNewModalProps {
  creationData: BankAccountCreationData;
  setCreationData: (newData: BankAccountCreationData) => void;

  currentStep: StepId | null;

  onSubmit: () => void;

  onFileStepCancelClicked: () => void;
  onFileStepCompleted: (fileStepData: BankAccountNewModalFileStepData) => void;

  onSettingsStepCancelClicked: () => void;
  onSettingsStepCompleted: (
    settingsStepData: BankAccountNewModalSettingsStepData
  ) => void;

  onRecapStepCancelClicked: () => void;

  onCloseRequested: () => void;
}

export enum StepId {
  FILE,
  SETTINGS,
  RECAP,
}

const STEPS = [
  {
    id: StepId.FILE,
    label: 'Emplacement',
  },
  {
    id: StepId.SETTINGS,
    label: 'Paramétrage',
  },
  {
    id: StepId.RECAP,
    label: 'Récapitulatif',
  },
];

export const BankAccountNewModal = (props: BankAccountNewModalProps) => {
  const {
    onCloseRequested,
    currentStep,
    creationData,
    onRecapStepCancelClicked,
    onSubmit,
    onSettingsStepCancelClicked,
    onSettingsStepCompleted,
    onFileStepCancelClicked,
    onFileStepCompleted,
  } = props;

  return (
    <Dialog
      disableEnforceFocus={true}
      open={currentStep != null}
      onClose={onCloseRequested}
      maxWidth={'md'}
      fullWidth
    >
      <DialogTitle>
        Création d&apos;un nouveau compte
        <br />
        <Stepper>
          {STEPS.map((step) => (
            <Step
              key={step.id}
              active={currentStep === step.id}
              completed={currentStep != null && step.id < currentStep}
            >
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      {currentStep === StepId.FILE ? (
        <BankAccountNewModalFileStep
          initialValues={creationData}
          onSubmit={onFileStepCompleted}
          onCancelClicked={onFileStepCancelClicked}
        />
      ) : null}
      {currentStep === StepId.SETTINGS ? (
        <BankAccountNewModalSettingStep
          initialValues={creationData}
          onSubmit={onSettingsStepCompleted}
          onCancelClicked={onSettingsStepCancelClicked}
        />
      ) : null}
      {currentStep === StepId.RECAP ? (
        <BankAccountNewModalRecapStep
          creationData={creationData}
          onCancelClicked={onRecapStepCancelClicked}
          onSubmit={onSubmit}
        />
      ) : null}
    </Dialog>
  );
};
