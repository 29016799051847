import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BankAccountOperationColumn } from '../../types/bank-account/BankAccountOperationColumn';
import { BankAccount } from '../../types/bank-account/BankAccount';
import { Modal, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BankAccountLineTools } from '../../tools/BankAccountLineTools';
import { DineroCellInput } from './DineroCellInput';
import { TextCellInput } from './TextCellInput';
import { CellInput as CellInputType } from './CellInput';
import { TypeCellInput } from './TypeCellInput';

export interface CellEditorProps {
  data: CellEditorData | null;
  position: DOMRect | null;

  onSubmit: (result: CellEditorResult) => void;
  onCancel: () => void;
}

export interface CellEditorCurrentCell {
  fileId: string;
  index: number;
  column: BankAccountOperationColumn;
}

export interface CellEditorData extends CellEditorCurrentCell {
  bankAccount: BankAccount;
}

export interface CellEditorResult extends CellEditorCurrentCell {
  value: string;
}

const useStyle = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    backgroundColor: theme.palette.background.paper,
    zIndex: 100,

    display: 'flex',
    alignItems: 'stretch',
  },
  autoComplete: {
    display: 'flex',
    alignItems: 'stretch',
    flex: 1,
  },
  modalBackdrop: {
    backgroundColor: 'grey',
  },
}));

export const CellEditor = (props: CellEditorProps) => {
  const { data, onSubmit, onCancel, position } = props;

  const classes = useStyle();

  const [draft, setDraft] = useState<string | null>(null);

  const inputRef = useRef<any>(null);

  // Reset the draft if the cell editor changes cell.
  const previousInitialValue = useRef<string | null>(null);
  useEffect(() => {
    if (data) {
      const columnField = BankAccountLineTools.getFieldNameFromColumnName(
        data.column
      );
      if (columnField) {
        const newInitialValue = BankAccountLineTools.getStringValueFromColumn(
          data.bankAccount.operations[data.index],
          data.column
        );
        if (newInitialValue !== previousInitialValue.current) {
          previousInitialValue.current = newInitialValue;
          setDraft(newInitialValue);
          inputRef.current?.focus();
        }
        return;
      }
    }
    previousInitialValue.current = null;
    setDraft(null);
  }, [data]);

  const submit = useCallback(() => {
    if (data && draft != null) {
      onSubmit({
        ...data,
        value: draft,
      });
    } else {
      console.warn('CellEditor tried to submit without data or null draft!');
    }
  }, [data, draft, onSubmit]);

  if (data && position) {
    const { top, left, width, height } = position;

    const CellInput: CellInputType =
      data.column === BankAccountOperationColumn.AMOUNT_DEBIT ||
      data.column === BankAccountOperationColumn.AMOUNT_CREDIT
        ? DineroCellInput
        : data.column === BankAccountOperationColumn.TYPE
        ? TypeCellInput
        : TextCellInput;

    return (
      <Modal
        open={true}
        disableEscapeKeyDown={true}
        onClose={submit}
        disableScrollLock={true}
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
      >
        <Paper
          className={classes.container}
          style={{ left, top, width, height }}
          elevation={4}
        >
          <CellInput
            value={draft}
            setValue={setDraft}
            ref={inputRef}
            submit={submit}
            cancel={onCancel}
          />
        </Paper>
      </Modal>
    );
  }
  return null;
};
