import { BankAccountCreationData } from '../../../types/data/BankAccountCreationData';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

export interface BankAccountNewModalRecapStepProps {
  creationData: BankAccountCreationData;

  onCancelClicked: () => void;
  onSubmit: () => void;
}

const useStyle = makeStyles((theme) => ({
  list: {
    position: 'relative',
    maxHeight: 400,
  },
  subHeader: {
    backgroundColor: 'inherit',
  },
}));

export const BankAccountNewModalRecapStep = (
  props: BankAccountNewModalRecapStepProps
) => {
  const classes = useStyle();

  return (
    <>
      <DialogContent>
        <List className={classes.list}>
          <ListItem>
            <ListItemText
              primary={'Fichier'}
              secondary={props.creationData.fileName}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Dossier'}
              secondary={props.creationData.folderFileId}
            />
          </ListItem>
          <Divider />
          {[1, 2, 3, 4, 5].map((item) => (
            <ListItem key={item}>
              <ListItemText primary={'TODO'} secondary={'A faire'} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          color={'primary'}
          type={'button'}
          onClick={props.onCancelClicked}
        >
          Précédent
        </Button>
        <Button color={'primary'} type={'button'} onClick={props.onSubmit}>
          Créer le compte
        </Button>
      </DialogActions>
    </>
  );
};
