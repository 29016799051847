import { useAppSelector } from '../hooks/ReduxHooks';
import { useMemo } from 'react';
import { AppThemeType } from './EditorSlice';
import { appTheme, appThemeDark } from '../../theme';

export const useEditorThemeType = () =>
  useAppSelector((state) => state.editor.themeType);

export const useEditorTheme = () => {
  const themeType = useEditorThemeType();
  return useMemo(
    () => (themeType === AppThemeType.DARK ? appThemeDark : appTheme),
    [themeType]
  );
};
