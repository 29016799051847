import { ThunkResult } from '../Store';
import { GoogleApiTools } from '../../tools/GoogleApiTools';
import { AuthSlice } from '../auth/AuthSlice';
import { openFilesByIdsThunk } from './OpenFileThunk';
import { AccountsListPersistTools } from '../tools/AccountsListPersistTools';
import { loadPrefilledLinesThunk } from './prefilled-lines/LoadPrefilledLinesThunk';

export const loginThunk = (): ThunkResult<void> => async (dispatch) => {
  dispatch(AuthSlice.actions.authPending());
  const user = await GoogleApiTools.login();
  if (user) {
    dispatch(AuthSlice.actions.authSuccessful({ user }));
    dispatch(
      openFilesByIdsThunk(
        AccountsListPersistTools.getPersistedAccountsList(user.id)
      )
    );
    // Load the prefilled lines.
    dispatch(loadPrefilledLinesThunk());
  } else {
    dispatch(
      AuthSlice.actions.authError({
        error: 'Erreur lors de la connexion. Nous vous invitons à réessayer.',
      })
    );
  }
};
