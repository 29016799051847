export enum BankAccountOperationColumn {
  DND,
  CHECKED,
  DATE,
  TYPE,
  DESCRIPTION,
  AMOUNT_CREDIT,
  AMOUNT_DEBIT,
  TOTAL,
  MONTH_NAME,
}
