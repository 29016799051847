import React from 'react';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { useEditorTheme } from './redux/editor/EditorHooks';

export const AppWithTheme = () => {
  const themeInUse = useEditorTheme();
  return (
    <ThemeProvider theme={themeInUse}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  );
};
