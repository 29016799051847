import {
  WhatsNewModal,
  WhatsNewVersion,
} from '../../components/modals/whats-new-modal/WhatsNewModal';
import React, { useEffect, useMemo, useState } from 'react';
import * as semver from 'semver';

const ALL_NEWS: WhatsNewVersion[] = [
  {
    version: '1.0.1',
    messageMarkup: `<ul><li>Ajout d'une boîte de dialogue lors de l'ouverture pour présenter les nouveautés</li></ul>`,
  },
  {
    version: '1.1.0',
    messageMarkup: `
      <ul>
        <li>Les lignes colorées ont changé de présentation : 
        la description est colorée mais la couleur de l'arrière-plan de la ligne reste blanche</li>
      </ul>
    `,
  },
  {
    version: '1.2.0',
    messageMarkup: `
      <ul>
        <li>Il est possible de définir la couleur initiale d'une ligne pré-remplie</li>
      </ul>
    `,
  },
  {
    version: '1.3.0',
    messageMarkup: `
      <ul>
        <li>Lors de l'édition d'une cellule, tout le contenu du champ de texte est sélectionné</li>
        <li>Pour éditer une cellule, il faut désormais double-cliquer dessus. Un simple clic ne fait que sélectionner la ligne survolée (exception faite de la case encaissée)</li>
        <li>L'insertion d'une ligne entre deux lignes avec la même date pré-remplit la date de la ligne insérée</li>
        <li>Ajout d'un mode "sombre" qui peut être basculé à tout moment en utilisant le bouton dans la barre en haut à droite</li>
      </ul>
    `,
  },
  {
    version: '1.3.1',
    messageMarkup: `
      <ul>
        <li>En mode sombre, les mois sont affichés en blanc</li>
        <li>La recherche ignore désormais la casse et les accents</li>
        <li>Correction d'un bug lorsqu'on essaie de changer la couleur d'une ligne "mois" : le bouton est maintenant désactivé si un mois est sélectionné</li>
      </ul>
    `,
  },
  {
    version: '1.3.2',
    messageMarkup: `
      <ul>
        <li>Lors de l'insertion d'une ligne pré-remplie dans un compte, les chèques numérotés (C#) sont maintenant bien saisis et incrémentés.</li>
      </ul>
    `,
  },
  {
    version: '1.3.3',
    messageMarkup: `
      <ul>
        <li>Correction d'un bug empêchant la saisie de certains montants dans les lignes pré-remplies.</li>
      </ul>
    `,
  },
  {
    version: '1.4.0',
    messageMarkup: `
      <ul>
        <li><b>Ajout d'un système de catégories d'opérations</b> (cliquer sur la pastille tout à droite de la description permet de catégoriser l'opération</li>
        <li><b>Ajout d'une fenêtre de statistiques</b> en cliquant sur le bouton approprié dans la barre d'outil (les trois barres) qui affiche les dépenses et revenus mensuels répartis par catégorie</li>
        <li>Ajout d'une modale de confirmation de suppression pour les lignes pré-remplies</li>
      </ul>
    `,
  },
  {
    version: '1.5.0',
    messageMarkup: `
      <ul>
        <li>Ajout de la génération de PDF pour un compte (icône "Imprimante")</li>
      </ul>
    `,
  },
  {
    version: '1.6.0',
    messageMarkup: `
      <ul>
        <li>Ajout de la possibilité de définir la catégorie initiale d'une ligne pré-remplie</li>
      </ul>
    `,
  },
  {
    version: '1.7.0',
    messageMarkup: `
      <ul>
        <li>Ajustement des catégories disponibles</li>
        <li>Amélioration de l'affichage des camemberts de catégories de dépenses</li>
        <li>Correction d'un bug si le solde encaissé réel contenait une virgule (dans certains cas précis)</li>
      </ul>
    `,
  },
];

export const WhatsNewController = () => {
  const appVersion = process.env.REACT_APP_VERSION ?? '1.0.0';
  const whatsNewVersionsInfo: WhatsNewVersion[] = useMemo(() => {
    const lastExploredVersion =
      localStorage.getItem('last_version') ??
      (localStorage.length >= 1 ? '1.0.0' : appVersion);
    if (semver.compare(appVersion, lastExploredVersion) <= 0) {
      // The last version is already known to the user. No infos to display.
      return [];
    } else {
      return ALL_NEWS.filter(
        (v) => semver.compare(lastExploredVersion, v.version) < 0
      );
    }
  }, [appVersion]);
  const [whatsNewOpen, setWhatsNewOpen] = useState(
    whatsNewVersionsInfo.length > 0
  );

  useEffect(() => {
    // When the whats new modal is closed (or already closed), set the last explored version
    // to the current version.
    localStorage.setItem('last_version', appVersion);
  }, [appVersion, whatsNewOpen]);

  return (
    <WhatsNewModal
      open={whatsNewOpen}
      onClose={() => setWhatsNewOpen(false)}
      version={appVersion}
      versions={whatsNewVersionsInfo}
    />
  );
};
