import { BankAccount } from '../types/bank-account/BankAccount';

export interface BankAccountCheckerWorkerInterface {
  check(bankAccount: BankAccount): BankAccountCheckerResult;
}

export enum BankAccountCheckerLogLevel {
  INFO,
  WARNING,
  ERROR,
}

export interface BankAccountCheckerLog {
  level: BankAccountCheckerLogLevel;
  line: number;
  message: string;
}

export interface BankAccountCheckerResult {
  duration: number;
  accountId: string;
  accountName: string;
  logs: BankAccountCheckerLog[];
}
