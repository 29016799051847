import { Box, CircularProgress, Dialog } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLoadingBlockerMessage } from '../../redux/hooks/LoadingBlockerHook';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(6),
  },
  loadingLabel: {
    marginTop: theme.spacing(1),
  },
}));

export const LoadingBlockerModal = () => {
  const classes = useStyle();

  const loadingMessage = useLoadingBlockerMessage();

  const [lastVisibleLoadingMessage, setLastVisibleLoadingMessage] =
    useState(loadingMessage);
  const isDisplayed =
    loadingMessage !== null && lastVisibleLoadingMessage !== null;

  useEffect(() => {
    if (loadingMessage) setLastVisibleLoadingMessage(loadingMessage);
  }, [loadingMessage]);

  return (
    <Dialog
      open={isDisplayed}
      onClose={() => {
        /* Prevent close. */
      }}
      maxWidth={'xs'}
      fullWidth
    >
      <Box className={classes.container}>
        <CircularProgress size={64} />
        <p>{lastVisibleLoadingMessage ?? ''}</p>
      </Box>
    </Dialog>
  );
};
