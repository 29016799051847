import { PrefilledLineData } from '../../types/prefilled-line/PrefilledLineData';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Box, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';

export interface PrefilledLineInsertionDropCloneProps {
  item: PrefilledLineData;
  provided: DraggableProvided;
}

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.success.light,
  },
}));

export const PrefilledLineInsertionDropClone = (
  props: PrefilledLineInsertionDropCloneProps
) => {
  const { item, provided } = props;
  const classes = useStyle();
  const refProps = { ref: provided?.innerRef };
  return (
    <Box
      boxShadow={4}
      className={classes.container}
      {...refProps}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      style={{
        ...provided?.draggableProps.style,
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText primary={'Insérer "' + item.name + '" ici'} />
      </ListItem>
    </Box>
  );
};
