import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { STORE } from './redux/Store';
import { Provider } from 'react-redux';
import { enableMapSet } from 'immer';
import * as Sentry from '@sentry/browser';
import { AppWithTheme } from './AppWithTheme';
import { Settings } from 'luxon';

Settings.defaultLocale = 'fr';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://3783f14011194009af1d85b29ada29e7@o69815.ingest.sentry.io/5222208',
    environment: process.env.NODE_ENV,
    beforeSend: (event, hint) => {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({
          eventId: event.event_id,
          user: { name: event.user?.username || undefined },
        });
      }
      return event;
    },
  });
}

enableMapSet();

ReactDOM.render(
  <Provider store={STORE}>
    <AppWithTheme />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
