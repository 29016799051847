import { CATEGORIES_SORTED, Category } from '../../types/Categories';
import React from 'react';
import {
  Avatar,
  Dialog,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export interface SelectCategoryModalProps {
  open: boolean;
  category?: string;
  onCategorySelected: (category: Category | undefined) => void;
  onCancel: () => void;
}

const useStyle = makeStyles((theme) => ({
  list: {
    maxHeight: 350,
    overflow: 'auto',
  },
}));

export const SelectCategoryModal = (props: SelectCategoryModalProps) => {
  const { open, onCategorySelected, onCancel, category } = props;
  const classes = useStyle();
  return (
    <Dialog open={open} onClose={onCancel} maxWidth={'sm'} fullWidth>
      <DialogTitle>Catégories</DialogTitle>
      <Divider />
      <List className={classes.list}>
        <ListItem
          button
          selected={category === undefined}
          onClick={() => onCategorySelected(undefined)}
        >
          <ListItemText primary={'Aucune'} />
        </ListItem>
        {CATEGORIES_SORTED.map((c) => (
          <ListItem
            button
            key={c.key}
            selected={category === c.key}
            onClick={() => onCategorySelected(c)}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: c.color }}>
                <c.icon htmlColor={'white'} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={c.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
