import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { saveAccountsThunk } from '../redux/thunks/SaveAccountsThunk';

const AUTO_SAVE_INTERVAL = 5000;

export const useAutoSave = (enabled: boolean) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const intervalId = enabled
      ? setInterval(() => {
          dispatch(saveAccountsThunk());
        }, AUTO_SAVE_INTERVAL)
      : null;

    return () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
  }, [dispatch, enabled]);
};
