import { ThunkResult } from '../../Store';
import { PrefilledLinesSlice } from '../../prefilled-lines/PrefilledLinesSlice';
import { PrefilledLinesTools } from '../../../tools/PrefilledLinesTools';

/**
 * Load the prefilled lines into the store from their config file.
 */
export const loadPrefilledLinesThunk =
  (): ThunkResult<void> => async (dispatch) => {
    dispatch(PrefilledLinesSlice.actions.loadLines());
    try {
      const data = await PrefilledLinesTools.load();
      if (data.error != null) {
        dispatch(
          PrefilledLinesSlice.actions.loadLinesFailure({
            error: data.error,
            fileId: data.fileId,
          })
        );
      } else {
        dispatch(
          PrefilledLinesSlice.actions.loadLinesSuccessful({
            fileId: data.fileId,
            prefilledLinesConfig: data.result || null,
          })
        );
      }
    } catch (e: any) {
      dispatch(
        PrefilledLinesSlice.actions.loadLinesFailure({
          error: e.message,
          fileId: null,
        })
      );
    }
  };
