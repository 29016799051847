import {
  BankAccountCheckerLog,
  BankAccountCheckerLogLevel,
  BankAccountCheckerResult,
} from '../../../workers/BankAccountChecker';
import { PopperButton } from '../../buttons/PopperButton';
import React, { ReactNode } from 'react';
import {
  Check,
  Error,
  ExpandLess,
  ExpandMore,
  Info,
  Loop,
  Warning,
} from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

export interface AccountCheckerPopperProps {
  checkerResult: BankAccountCheckerResult | null;
  onLogClicked?: (log: BankAccountCheckerLog) => void;
}

const useStyle = makeStyles((theme) => ({
  logsList: {
    overflow: 'auto',
    maxHeight: 250,
  },
  buttonSection: {
    marginRight: theme.spacing(1),
  },
}));

const LogLevelIcon = ({
  logLevel,
}: {
  logLevel: BankAccountCheckerLogLevel;
}) => {
  const theme = useTheme();
  switch (logLevel) {
    case BankAccountCheckerLogLevel.ERROR:
      return <Error color={'error'} />;
    case BankAccountCheckerLogLevel.WARNING:
      return <Warning htmlColor={theme.palette.warning.main} />;
    case BankAccountCheckerLogLevel.INFO:
      return <Info htmlColor={theme.palette.info.main} />;
  }
};

export const AccountCheckerPopper = (props: AccountCheckerPopperProps) => {
  const { checkerResult, onLogClicked } = props;
  const classes = useStyle();
  const theme = useTheme();

  return (
    <PopperButton
      buttonContent={(expanded) => {
        const expandIcon = expanded ? <ExpandLess /> : <ExpandMore />;
        let content: ReactNode;
        if (checkerResult == null) {
          content = <Loop />;
        } else {
          const errorsCount = checkerResult.logs.filter(
            (log) => log.level === BankAccountCheckerLogLevel.ERROR
          ).length;
          const warningCount = checkerResult.logs.filter(
            (log) => log.level === BankAccountCheckerLogLevel.WARNING
          ).length;
          if (errorsCount === 0 && warningCount === 0) {
            content = (
              <Check
                htmlColor={theme.palette.success.main}
                className={classes.buttonSection}
              />
            );
          } else {
            content = (
              <>
                {errorsCount > 0 ? (
                  <>
                    <LogLevelIcon logLevel={BankAccountCheckerLogLevel.ERROR} />{' '}
                    <span className={classes.buttonSection}>{errorsCount}</span>
                  </>
                ) : null}
                {warningCount > 0 ? (
                  <>
                    <LogLevelIcon
                      logLevel={BankAccountCheckerLogLevel.WARNING}
                    />{' '}
                    <span className={classes.buttonSection}>
                      {warningCount}
                    </span>
                  </>
                ) : null}
              </>
            );
          }
        }
        return (
          <>
            {content} {expandIcon}
          </>
        );
      }}
    >
      {checkerResult != null ? (
        checkerResult.logs.length > 0 ? (
          <List className={classes.logsList} dense={true}>
            {checkerResult.logs.map((log, index) => (
              <ListItem key={index} button onClick={() => onLogClicked?.(log)}>
                <ListItemIcon>
                  <LogLevelIcon logLevel={log.level} />
                </ListItemIcon>
                <ListItemText>
                  Ligne n°{log.line + 1} : {log.message}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        ) : (
          'Pas de problèmes.'
        )
      ) : (
        'Chargement...'
      )}
    </PopperButton>
  );
};
