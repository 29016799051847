import { BankAccountData } from '../../types/data/BankAccountData';
import {
  BankAccount,
  BankAccountStatus,
} from '../../types/bank-account/BankAccount';
import { BankAccountOperation } from '../../types/bank-account/BankAccountOperation';
import Dinero from 'dinero.js';
import { v4 } from 'uuid';
import { BankAccountOperationData } from '../../types/data/BankAccountOperationData';
import { BankAccountLineTools } from '../../tools/BankAccountLineTools';
import { BankAccountLineData } from '../../types/data/BankAccountLineData';
import { BankAccountMonthData } from '../../types/data/BankAccountMonthData';
import { BankAccountMonth } from '../../types/bank-account/BankAccountMonth';
import { BankAccountLine } from '../../types/bank-account/BankAccountLine';

/**
 * Create a BankAccount from the data given by the baOpenSuccessful action creator.
 * @param fileId
 * @param name
 * @param data
 */
export const createBankAccountFromOpenedFile = (
  fileId: string,
  name: string,
  data: BankAccountData
): BankAccount => {
  return {
    id: fileId,
    name,
    lastChangeEpoch: -1,
    savingChangeEpoch: null,
    lastSavedChangeEpoch: -1,
    saveError: null,
    status: BankAccountStatus.READY,
    undo: [],
    redo: [],
    chequeBook: data.chequeBook,
    operations: data.operations.reduce<{
      temporaryTotal: Dinero.Dinero;
      operations: BankAccountLine[];
    }>(
      (prev, o) => {
        const updatedTotal = prev.temporaryTotal.add(
          Dinero(BankAccountLineTools.getLineAmount(o))
        );
        return {
          operations: [...prev.operations, createLine(updatedTotal, o)],
          temporaryTotal: updatedTotal,
        };
      },
      {
        temporaryTotal: Dinero({ currency: 'EUR', amount: 0 }),
        operations: [],
      }
    ).operations,
  };
};

/**
 * Create a BankAccount from the info given by the baNewSuccessful action creator.
 * @param fileId
 * @param name
 * @param epoch
 */
export const createBankAccountFromEmpty = (
  fileId: string,
  name: string,
  epoch: number
): BankAccount => {
  return {
    id: fileId,
    name,
    lastSavedChangeEpoch: -1,
    savingChangeEpoch: null,
    lastChangeEpoch: epoch,
    saveError: null,
    status: BankAccountStatus.READY,
    operations: [createOperation(Dinero({ amount: 0, currency: 'EUR' }))],
    undo: [],
    redo: [],
  };
};

export const createLine = (
  initialTotal: Dinero.Dinero,
  initialDataOrType: BankAccountLineData | BankAccountLineData['_type']
) => {
  const lineType =
    typeof initialDataOrType === 'string'
      ? initialDataOrType
      : initialDataOrType._type;
  const data =
    typeof initialDataOrType === 'string' ? undefined : initialDataOrType;
  if (lineType === 'operation') {
    return createOperation(
      initialTotal,
      data?._type === 'operation' ? data : undefined
    );
  } else if (lineType === 'month') {
    return createMonth(
      initialTotal,
      data?._type === 'month' ? data : undefined
    );
  } else {
    throw new Error('Unsupported line type: ' + lineType);
  }
};

export const createOperation = (
  initialTotal: Dinero.Dinero,
  initialData?: BankAccountOperationData
): BankAccountOperation => {
  const generatedUuid = v4();
  return {
    _type: 'operation',
    uuid: generatedUuid,
    checked: initialData?.checked || false,
    date: initialData?.date || '',
    type: initialData?.type || '',
    description: initialData?.description || '',
    amount:
      initialData?.amount || Dinero({ currency: 'EUR', amount: 0 }).toObject(),
    color: initialData?.color,
    category: initialData?.category,
    total: initialTotal.toObject(),
  };
};

export const createMonth = (
  initialTotal: Dinero.Dinero,
  initialData?: BankAccountMonthData
): BankAccountMonth => {
  const generatedUuid = v4();
  return {
    _type: 'month',
    uuid: generatedUuid,
    name: initialData?.name || '',
    total: initialTotal.toObject(),
  };
};
