export interface UnknownSerializedAccount {
  type: 'com.victorlevasseur.account-manager.file';
  version: number;
}

export const isSerializedAccount = (
  data: unknown
): data is UnknownSerializedAccount => {
  return (
    typeof data === 'object' &&
    data != null &&
    (data as any).type === 'com.victorlevasseur.account-manager.file' &&
    typeof (data as any).version === 'number'
  );
};
