import React from 'react';
import { ReactComponent as Icon } from './grip.svg';
import { ReactComponent as SelectedIcon } from './gripSelected.svg';

export const GripIcon = () => {
  return <Icon />;
};

export const SelectedGripIcon = () => {
  return <SelectedIcon />;
};
