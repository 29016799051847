import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  spacer: {
    flexGrow: 1,
  },
});

export const Spacer = () => {
  const classes = useStyle();
  return <Box className={classes.spacer} />;
};
