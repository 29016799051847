import React, {
  FC,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { Align, ListOnScrollProps, VariableSizeList } from 'react-window';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountOperationRow } from './AccountOperationRow';
import clsx from 'clsx';
import { BankAccountOperationColumn } from '../../types/bank-account/BankAccountOperationColumn';
import { AutoSizer as _AutoSizer, AutoSizerProps } from 'react-virtualized';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { BankAccountLine } from '../../types/bank-account/BankAccountLine';
import { AccountMonthRow } from './AccountMonthRow';

// FIXME: https://github.com/bvaughn/react-virtualized/issues/1739
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

export interface AccountTableProps {
  items: BankAccountLine[];
  selectedIndex: number | null;

  onItemColumnClicked?: (
    index: number,
    column: BankAccountOperationColumn,
    rect: DOMRect
  ) => void;

  onItemColumnDoubleClicked?: (
    index: number,
    column: BankAccountOperationColumn,
    rect: DOMRect
  ) => void;

  onScroll?: (props: ListOnScrollProps) => void;

  onMouseEnter?: () => void;
  onMouseLeave?: () => void;

  onMouseEnterItem?: (index: number, itemEl: HTMLElement) => void;
  onMouseLeaveItem?: (index: number) => void;
  onItemContextMenu?: (
    index: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;

  onItemCategoryButtonClick?: (index: number) => void;
}

const useStyle = makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  table: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  tableHeader: {
    flex: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginRight: 16,
  },
  tableBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  tableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexBasis: 0,
  },
  gripCell: {
    padding: theme.spacing(1),
    flex: 0,
    flexBasis: 24,
    textAlign: 'center',
  },
}));

interface ListData {
  operations: BankAccountLine[];
  selectedIndex: number | null;
  onItemColumnClicked: (
    index: number,
    column: BankAccountOperationColumn,
    rect: DOMRect
  ) => void;
  onItemDoubleColumnClicked: (
    index: number,
    column: BankAccountOperationColumn,
    rect: DOMRect
  ) => void;
  onMouseEnterItem: (index: number, itemEl: HTMLElement) => void;
  onMouseLeaveItem: (index: number) => void;
  onItemContextMenu: (
    index: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onItemCategoryButtonClick: (index: number) => void;
}

const AccountRowRenderer = memo(
  (props: { data: ListData; index: number; style: any }) => {
    const { style, index, data } = props;
    const item = data.operations[index];
    const columnClickHandler = useCallback(
      (column: BankAccountOperationColumn, rect: DOMRect) => {
        data.onItemColumnClicked(index, column, rect);
      },
      [data, index]
    );

    const columnDoubleClickHandler = useCallback(
      (column: BankAccountOperationColumn, rect: DOMRect) => {
        data.onItemDoubleColumnClicked(index, column, rect);
      },
      [data, index]
    );

    return (
      <Draggable
        draggableId={'account-table-item-' + item.uuid}
        index={index}
        key={item.uuid}
      >
        {(provided, snapshot) => {
          if (item._type === 'operation') {
            return (
              <AccountOperationRow
                item={item}
                style={{ ...style, ...provided.draggableProps.style }}
                onColumnClick={columnClickHandler}
                onColumnDoubleClick={columnDoubleClickHandler}
                selected={data.selectedIndex === index}
                draggableProvided={provided}
                onMouseEnter={(el) => data.onMouseEnterItem(index, el)}
                onMouseLeave={() => data.onMouseLeaveItem(index)}
                onContextMenu={(event) => data.onItemContextMenu(index, event)}
                onCategoryButtonClick={() =>
                  data.onItemCategoryButtonClick(index)
                }
              />
            );
          } else {
            return (
              <AccountMonthRow
                item={item}
                style={{ ...style, ...provided.draggableProps.style }}
                onColumnClick={columnClickHandler}
                onColumnDoubleClick={columnDoubleClickHandler}
                selected={data.selectedIndex === index}
                draggableProvided={provided}
                onMouseEnter={(el) => data.onMouseEnterItem(index, el)}
                onMouseLeave={() => data.onMouseLeaveItem(index)}
                onContextMenu={(event) => data.onItemContextMenu(index, event)}
              />
            );
          }
        }}
      </Draggable>
    );
  }
);
AccountRowRenderer.displayName = 'AccountRowRenderer';

/**
 * Interface of the ref passed to AccountTable.
 */
export interface AccountTableInterface {
  scrollTo(scrollOffset: number): void;
  scrollToItem(index: number, align?: Align): void;
}

/**
 * An account table component. Must be wrapped into a DragDropContext to enable D&D.
 */
export const AccountTable = forwardRef<
  AccountTableInterface,
  AccountTableProps
>((props: AccountTableProps, ref) => {
  const {
    items,
    selectedIndex,
    onItemColumnClicked,
    onItemColumnDoubleClicked,
    onMouseEnter,
    onMouseLeave,
    onMouseEnterItem,
    onMouseLeaveItem,
    onItemContextMenu,
    onItemCategoryButtonClick,
    onScroll,
  } = props;

  const classes = useStyle();

  const itemKey = useCallback((index: number) => items[index].uuid, [items]);

  const listData: ListData = useMemo(
    () => ({
      operations: items,
      selectedIndex,
      onItemColumnClicked:
        onItemColumnClicked ||
        (() => {
          /* do nothing */
        }),
      onItemDoubleColumnClicked:
        onItemColumnDoubleClicked ||
        (() => {
          /* do nothing */
        }),
      onMouseEnterItem: (index, itemEl) => {
        if (onMouseEnterItem) {
          onMouseEnterItem(index, itemEl);
        }
      },
      onMouseLeaveItem: (index) => {
        if (onMouseLeaveItem) {
          onMouseLeaveItem(index);
        }
      },
      onItemContextMenu: (index, event) => {
        if (onItemContextMenu) {
          onItemContextMenu(index, event);
        }
      },
      onItemCategoryButtonClick:
        onItemCategoryButtonClick ??
        (() => {
          /* do nothing */
        }),
    }),
    [
      items,
      onItemCategoryButtonClick,
      onItemColumnClicked,
      onItemColumnDoubleClicked,
      onItemContextMenu,
      onMouseEnterItem,
      onMouseLeaveItem,
      selectedIndex,
    ]
  );

  const virtualListRef = useRef<VariableSizeList>(null);
  useImperativeHandle(ref, () => ({
    scrollTo: (offset) => virtualListRef.current?.scrollTo(offset),
    scrollToItem: (offset, align) =>
      virtualListRef.current?.scrollToItem(offset, align),
  }));

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table
        aria-label="simple table"
        component={'div'}
        className={classes.table}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <TableHead component={'div'} className={classes.tableHeader}>
          <TableRow component={'div'} className={classes.tableRow}>
            <TableCell
              component={'div'}
              className={clsx(classes.tableCell, classes.gripCell)}
            />
            <TableCell
              component={'div'}
              className={classes.tableCell}
              style={{ flex: 0, flexBasis: 24 }}
            />
            <TableCell
              component={'div'}
              className={classes.tableCell}
              style={{ flex: 1 }}
            >
              Date
            </TableCell>
            <TableCell
              component={'div'}
              className={classes.tableCell}
              style={{ flex: 1 }}
            >
              Type
            </TableCell>
            <TableCell
              component={'div'}
              className={classes.tableCell}
              style={{ flex: 5 }}
            >
              Description
            </TableCell>
            <TableCell
              component={'div'}
              className={classes.tableCell}
              style={{ flex: 1 }}
            >
              Crédit
            </TableCell>
            <TableCell
              component={'div'}
              className={classes.tableCell}
              style={{ flex: 1 }}
            >
              Débit
            </TableCell>
            <TableCell
              component={'div'}
              className={classes.tableCell}
              style={{ flex: 1 }}
            >
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody} component={'div'}>
          <AutoSizer>
            {({ width, height }) => (
              <Droppable
                droppableId={'account-table'}
                mode={'virtual'}
                renderClone={(provided, snapshot, rubric) => {
                  const item = items[rubric.source.index];
                  if (item._type === 'operation') {
                    return (
                      <AccountOperationRow
                        item={item}
                        selected={false}
                        isDragging={true}
                        style={provided.draggableProps.style}
                        draggableProvided={provided}
                      />
                    );
                  } else {
                    return (
                      <AccountMonthRow
                        item={item}
                        selected={false}
                        isDragging={true}
                        style={provided.draggableProps.style}
                        draggableProvided={provided}
                      />
                    );
                  }
                }}
              >
                {(provided) => (
                  <VariableSizeList
                    ref={virtualListRef}
                    style={{ overflowY: 'scroll' }}
                    itemSize={() => 52}
                    height={height}
                    itemData={listData}
                    itemCount={items.length}
                    width={width}
                    itemKey={itemKey}
                    outerRef={provided.innerRef}
                    onScroll={onScroll}
                  >
                    {AccountRowRenderer}
                  </VariableSizeList>
                )}
              </Droppable>
            )}
          </AutoSizer>
        </TableBody>
      </Table>
    </TableContainer>
  );
});
AccountTable.displayName = 'AccountTable';
