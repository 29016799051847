import { BankAccountOperationColumn } from '../types/bank-account/BankAccountOperationColumn';
import { BankAccountOperation } from '../types/bank-account/BankAccountOperation';
import Dinero, { DineroObject } from 'dinero.js';
import { DateTime } from 'luxon';
import { BankAccountLine } from '../types/bank-account/BankAccountLine';
import { BankAccountMonth } from '../types/bank-account/BankAccountMonth';
import { BankAccountLineData } from '../types/data/BankAccountLineData';

export class BankAccountLineTools {
  private static formatsToTry = ['d/L/yy', 'd/L', 'd', 'd L', 'd L yy'];

  public static getFieldNameFromColumnName(
    column: BankAccountOperationColumn
  ): keyof BankAccountOperation | keyof BankAccountMonth | null {
    switch (column) {
      case BankAccountOperationColumn.CHECKED:
        return 'checked';
      case BankAccountOperationColumn.DATE:
        return 'date';
      case BankAccountOperationColumn.TYPE:
        return 'type';
      case BankAccountOperationColumn.DESCRIPTION:
        return 'description';
      case BankAccountOperationColumn.AMOUNT_CREDIT:
        return 'amount';
      case BankAccountOperationColumn.AMOUNT_DEBIT:
        return 'amount';
      case BankAccountOperationColumn.MONTH_NAME:
        return 'name';
    }
    return null;
  }

  public static getStringValueFromColumn(
    line: BankAccountLine,
    column: BankAccountOperationColumn
  ): string | null {
    if (line._type === 'operation') {
      switch (column) {
        case BankAccountOperationColumn.CHECKED:
          return line.checked ? 'true' : 'false';
        case BankAccountOperationColumn.DATE:
          return line.date;
        case BankAccountOperationColumn.TYPE:
          return line.type;
        case BankAccountOperationColumn.DESCRIPTION:
          return line.description;
        case BankAccountOperationColumn.AMOUNT_CREDIT:
          return JSON.stringify(line.amount);
        case BankAccountOperationColumn.AMOUNT_DEBIT:
          return JSON.stringify(Dinero(line.amount).multiply(-1).toObject());
      }
    } else if (line._type === 'month') {
      switch (column) {
        case BankAccountOperationColumn.MONTH_NAME:
          return line.name;
      }
    }
    return null;
  }

  public static setColumnFromStringValue(
    line: BankAccountLine,
    column: BankAccountOperationColumn,
    value: string
  ): void {
    if (line._type === 'operation') {
      switch (column) {
        case BankAccountOperationColumn.CHECKED:
          if (value === 'true') {
            line.checked = true;
          } else if (value === 'false') {
            line.checked = false;
          } else {
            throw Error(
              'Unable to set value from string "' +
                value +
                '" on column ' +
                column
            );
          }
          break;
        case BankAccountOperationColumn.DATE:
          if (value) {
            const parsedDate: DateTime | undefined = this.formatsToTry
              .map((format) => DateTime.fromFormat(value.trim(), format))
              .filter((result) => result.isValid)[0];
            if (parsedDate) {
              line.date = parsedDate.toFormat('dd/LL/yyyy');
            } else {
              line.date = value;
            }
          } else {
            line.date = value;
          }
          break;
        case BankAccountOperationColumn.TYPE:
          line.type = value;
          break;
        case BankAccountOperationColumn.DESCRIPTION:
          line.description = value;
          break;
        case BankAccountOperationColumn.AMOUNT_CREDIT:
          try {
            line.amount = Dinero(JSON.parse(value)).toObject();
          } catch (e) {
            if (value !== '') {
              throw Error(
                'Unable to set value from string "' +
                  value +
                  '" on column ' +
                  column
              );
            }
          }
          break;
        case BankAccountOperationColumn.AMOUNT_DEBIT:
          try {
            line.amount = Dinero(JSON.parse(value)).multiply(-1).toObject();
          } catch (e) {
            if (value !== '') {
              throw Error(
                'Unable to set value from string "' +
                  value +
                  '" on column ' +
                  column
              );
            }
          }
          break;
      }
    } else if (line._type === 'month') {
      switch (column) {
        case BankAccountOperationColumn.MONTH_NAME:
          line.name = value;
          break;
      }
    } else {
      throw new Error(
        'Unsupported BankAccountLine with _type: ' + (line as any)._type
      );
    }
  }

  public static getLineAmount(
    line: BankAccountLine | BankAccountLineData
  ): DineroObject {
    if (line._type === 'operation') {
      return line.amount;
    } else {
      return Dinero({ amount: 0, currency: 'EUR' }).toObject();
    }
  }

  public static getLineCheckedAmount(
    line: BankAccountLine | BankAccountLineData
  ): DineroObject {
    if (line._type === 'operation' && line.checked) {
      return line.amount;
    } else {
      return Dinero({ amount: 0, currency: 'EUR' }).toObject();
    }
  }
}
