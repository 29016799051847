import { ThunkResult } from '../Store';
import { GoogleApiTools } from '../../tools/GoogleApiTools';
import { BankAccountsSlice } from '../bank-accounts/BankAccountsSlice';
import { BankAccountCreationData } from '../../types/data/BankAccountCreationData';
import { AccountsListPersistTools } from '../tools/AccountsListPersistTools';

export const createFileThunk =
  (
    creationData: BankAccountCreationData
  ): ThunkResult<Promise<string | null>> =>
  async (dispatch, getState) => {
    dispatch(BankAccountsSlice.actions.baNew());
    try {
      const fileId = await GoogleApiTools.createFile(
        creationData.fileName,
        creationData.folderFileId,
        'application/com.victorlevasseur.account-manager.file'
      );
      if (fileId != null) {
        dispatch(
          BankAccountsSlice.actions.baNewSuccessful({
            fileId,
            name: creationData.fileName,
            epoch: Date.now(),
          })
        );
        AccountsListPersistTools.persistAccountsListFromRedux(getState());
        return fileId;
      } else {
        throw Error('Erreur inconnue !');
      }
    } catch (e: any) {
      console.error(e);
      dispatch(BankAccountsSlice.actions.baNewFailure({ error: e.message }));
      return null;
    }
  };
