import { User } from '../../types/User';
import { Spacer } from '../common/Spacer';
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import React, { useCallback, useState, MouseEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BugReport, Opacity } from '@material-ui/icons';

export interface ConnectedToolbarProps {
  user: User;
  onLogoutClicked: () => void;
  onNewClicked: () => void;
  onOpenClicked: () => void;
  onOpenImportedFileClicked: () => void;
  onCloseClicked: () => void;
  onSwitchThemeTypeClicked: () => void;
  onBugReportClicked: () => void;
}

const useStyle = makeStyles((theme) => ({
  toolBarButton: {
    height: '100%',
  },
}));

export const ConnectedToolbar = (props: ConnectedToolbarProps) => {
  const {
    user,
    onLogoutClicked,
    onNewClicked,
    onOpenClicked,
    onOpenImportedFileClicked,
    onCloseClicked,
    onSwitchThemeTypeClicked,
    onBugReportClicked,
  } = props;
  const classes = useStyle();

  const [fileMenuEl, setFileMenuEl] = useState<HTMLElement | null>(null);
  const fileButtonClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setFileMenuEl(event.currentTarget);
    },
    []
  );
  const closeFileMenu = useCallback(() => {
    setFileMenuEl(null);
  }, []);

  const handleNewClicked = useCallback(() => {
    onNewClicked();
    closeFileMenu();
  }, [closeFileMenu, onNewClicked]);
  const handleOpenClicked = useCallback(() => {
    onOpenClicked();
    closeFileMenu();
  }, [closeFileMenu, onOpenClicked]);
  const handleOpenImportedClicked = useCallback(() => {
    onOpenImportedFileClicked();
    closeFileMenu();
  }, [closeFileMenu, onOpenImportedFileClicked]);
  const handleCloseClicked = useCallback(() => {
    onCloseClicked();
    closeFileMenu();
  }, [closeFileMenu, onCloseClicked]);

  const [accountMenuEl, setAccountMenuEl] = useState<HTMLElement | null>(null);
  const accountButtonClicked = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAccountMenuEl(event.currentTarget);
    },
    []
  );
  const closeAccountMenu = useCallback(() => {
    setAccountMenuEl(null);
  }, []);

  const onLogoutItemClicked = useCallback(() => {
    onLogoutClicked();
    closeAccountMenu();
  }, [closeAccountMenu, onLogoutClicked]);

  return (
    <>
      <Button
        className={classes.toolBarButton}
        aria-controls="file-menu"
        aria-haspopup="true"
        color={'inherit'}
        onClick={fileButtonClicked}
      >
        Fichier
      </Button>
      <Menu
        id={'file-menu'}
        anchorEl={fileMenuEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        keepMounted
        open={fileMenuEl != null}
        onClose={closeFileMenu}
        variant={'selectedMenu'}
      >
        <MenuItem onClick={handleNewClicked}>Nouveau compte...</MenuItem>
        <MenuItem onClick={handleOpenClicked}>Ouvrir un compte...</MenuItem>
        <MenuItem onClick={handleOpenImportedClicked}>
          Ouvrir un compte importé...
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCloseClicked}>Fermer le compte</MenuItem>
      </Menu>
      <Spacer />
      <Tooltip title={'Reporter un problème...'}>
        <IconButton color={'inherit'} onClick={onBugReportClicked}>
          <BugReport />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Passer au thème sombre/clair'}>
        <IconButton color={'inherit'} onClick={onSwitchThemeTypeClicked}>
          <Opacity />
        </IconButton>
      </Tooltip>
      <Button
        className={classes.toolBarButton}
        aria-controls="account-menu"
        aria-haspopup="true"
        color={'inherit'}
        onClick={accountButtonClicked}
      >
        <Avatar
          alt={user.displayName}
          src={user.displayIconUrl}
          style={{ marginRight: 4 }}
        />
        {user.displayName}
      </Button>
      <Menu
        id={'account-menu'}
        anchorEl={accountMenuEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        keepMounted
        open={accountMenuEl != null}
        onClose={closeAccountMenu}
        variant={'selectedMenu'}
      >
        <MenuItem onClick={onLogoutItemClicked}>Déconnexion</MenuItem>
      </Menu>
    </>
  );
};
