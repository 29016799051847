import { User } from '../../types/User';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { illegalAction } from '../tools/CommonThrows';
import { authLogoutAction } from './AuthExtraActions';

export interface AuthState {
  pending: boolean;
  error: string | null;
  authenticatedUser: User | null;
}

const initialState: AuthState = {
  error: null,
  pending: false,
  authenticatedUser: null,
};

export const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    authPending: (state, action: PayloadAction) => {
      if (state.pending || state.authenticatedUser) {
        illegalAction('AUTH', action, state);
      }
      state.error = null;
      state.pending = true;
    },
    authSuccessful: (state, action: PayloadAction<{ user: User }>) => {
      if (!state.pending) {
        illegalAction('AUTH', action, state);
      }
      state.pending = false;
      state.error = null;
      state.authenticatedUser = action.payload.user;
    },
    authError: (state, action: PayloadAction<{ error: string }>) => {
      if (!state.pending) {
        illegalAction('AUTH', action, state);
      }
      state.pending = false;
      state.error = action.payload.error;
      state.authenticatedUser = null;
    },
    authReloadLoggedInUser: (state, action: PayloadAction<{ user: User }>) => {
      if (state.authenticatedUser) {
        illegalAction('AUTH', action, state);
      }
      state.authenticatedUser = action.payload.user;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogoutAction, (state, action) => {
      if (state.pending || !state.authenticatedUser) {
        illegalAction('AUTH', action, state);
      }
      state.pending = false;
      state.error = null;
      state.authenticatedUser = null;
    });
  },
});
