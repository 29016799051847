import React, { ComponentType, ReactNode } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Paper,
  SvgIconProps,
  Typography,
} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(8, 0),
    padding: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
  },
  titleIcon: {
    textAlign: 'center',
    fontSize: 64,
  },
}));

export interface CenteredPaperProps {
  icon?: ComponentType<SvgIconProps>;
  title?: string;
  children: ReactNode;
}

export const CenteredPaper = (props: CenteredPaperProps) => {
  const { icon, title, children } = props;
  const classes = useStyle();

  const IconComponent = icon;

  return (
    <Container maxWidth={'sm'}>
      <Paper className={classes.paper} elevation={3}>
        {IconComponent ? (
          <Box className={classes.titleIcon}>
            <IconComponent fontSize={'inherit'} />
          </Box>
        ) : null}
        {title ? (
          <Typography className={classes.title} variant={'h5'}>
            {title}
          </Typography>
        ) : null}
        {children}
      </Paper>
    </Container>
  );
};
